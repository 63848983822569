import { Box, Flex, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import TemplatesContext from 'src/contexts/templates/TemplatesContext';
import {
	ICase,
	ILayer,
	ITemplateFormValues,
} from 'src/lib/schemas/account/template/TemplateSchema';
import httpAppsai from 'src/services/http-appsai';
import TestTable from './TestTable';

export const TableHook = () => {
	const [hasFetchedLayers, setHasFetchedLayers] = useState(false);

	const { selectedTemplate } = useContext(TemplatesContext);
	const { setValue, watch, getValues } = useFormContext<ITemplateFormValues>();
	const cases = watch('cases');

	const [loadingCases, setLoadingCases] = useState(false);

	const fetchLayers = async () => {
		if (
			!selectedTemplate?.variations ||
			selectedTemplate.variations.length === 0
		) {
			return;
		}
		setHasFetchedLayers(true);

		setLoadingCases(true);
		try {
			const { data: responseData } = await httpAppsai.post(
				'/images/get-layers',
				{
					inputs: {
						layeredFile: selectedTemplate.variations[0].layeredFile,
						copyImages: false,
					},
				},
			);

			const mappedLayers: ILayer[] = responseData.layers
				.filter((layer: ILayer) => layer.name.startsWith('$'))
				.map((layer: ILayer) => {
					let mappedLayer: ILayer = {
						name: layer.name,
						type: layer.type || 'image',
						visible: layer.visible,
					};
					if (layer.type === 'image' && layer.imageUrl) {
						mappedLayer = { ...mappedLayer, imageUrl: layer.imageUrl };
					}
					if (layer.type === 'text' && layer.content) {
						mappedLayer = { ...mappedLayer, content: layer.content };
					}
					return mappedLayer;
				});

			setValue('cases', [
				{
					id: 1,
					layers: mappedLayers,
				},
			]);
		} catch (error) {
			console.error('Error fetching layers from API:', error);
		} finally {
			setLoadingCases(false);
		}
	};

	useEffect(() => {
		if (!hasFetchedLayers) {
			fetchLayers();
		}
	}, [selectedTemplate]);

	const handleDuplicateCase = (caseItem: ICase) => {
		const currentCases = getValues('cases');
		const newCase = {
			...caseItem,
			id: currentCases.length + 1,
			isDuplicate: true,
		};
		setValue('cases', [...currentCases, newCase]);
	};

	if (selectedTemplate && selectedTemplate.variations!.length >= 1) {
		return (
			<>
				{loadingCases ? (
					<Flex mt="10px" justifyContent="center">
						<Spinner />
					</Flex>
				) : (
					<>
						{cases.map((caseItem) => (
							<Box key={caseItem.id} mt="50px">
								<TestTable
									onDuplicate={handleDuplicateCase}
									caseItem={caseItem}
								/>
							</Box>
						))}
					</>
				)}
			</>
		);
	}
};
