import AccountIcon from 'src/assets/icons/AccountIcon';
import CampaignIcon from 'src/assets/icons/CampaignIcon';
import CommerceIcon from 'src/assets/icons/CommerceIcon';
import HomeCustomIcon from 'src/assets/icons/HomeCustomIcon';
import IntegrationsIcon from 'src/assets/icons/IntegrationsIcon';
import TasksIcon from 'src/assets/icons/TasksIcon';

import { getAppsCategories } from 'src/services/app';

export const SIDEBAR_NAV_LIST = [
	{
		title: 'Home',
		link: '/home',
		icon: HomeCustomIcon,
		access: ['verifiedAccountAdmin', 'accountAdmin', 'verifiedUser', 'user'],
	},
	{
		title: 'Campaigns',
		icon: CampaignIcon,
		link: '/projects/campaigns',
		access: ['verifiedAccountAdmin', 'accountAdmin', 'verifiedUser', 'user'],
		list: [
			{
				title: 'Campaigns',
				link: '/projects/campaigns',
				access: [
					'verifiedAccountAdmin',
					'accountAdmin',
					'verifiedUser',
					'user',
				],
			},
			{
				title: 'Campaign groups',
				link: '/projects/groups',
				access: [
					'verifiedAccountAdmin',
					'accountAdmin',
					'verifiedUser',
					'user',
				],
			},
		],
	},
	{
		icon: TasksIcon,
		title: 'Tasks',
		link: '/tasks',
		access: ['verifiedAccountAdmin', 'accountAdmin', 'verifiedUser', 'user'],
		list: [],
	},
	{
		title: 'Account',
		icon: AccountIcon,
		link: '/account/company',
		access: ['verifiedAccountAdmin', 'accountAdmin', 'verifiedUser', 'user'],
		list: [
			{
				title: 'Company',
				link: '/account/company',
				access: [
					'verifiedAccountAdmin',
					'accountAdmin',
					'verifiedUser',
					'user',
				],
			},
			{
				title: 'Users',
				link: '/account/users',
				access: [
					'verifiedAccountAdmin',
					'accountAdmin',
					'verifiedUser',
					'user',
				],
			},
		],
	},
	{
		title: 'Commerce',
		icon: CommerceIcon,
		link: '/commerce/catalog',
		access: ['verifiedAccountAdmin', 'accountAdmin', 'verifiedUser', 'user'],
		list: [
			{
				title: 'Catalog',
				link: '/commerce/catalog',
				access: [
					'verifiedAccountAdmin',
					'accountAdmin',
					'verifiedUser',
					'user',
				],
			},
			{
				title: 'Tags',
				link: '/commerce/tags',
				access: [
					'verifiedAccountAdmin',
					'accountAdmin',
					'verifiedUser',
					'user',
				],
			},
			// {
			// 	title: 'Brands',
			// 	link: '/commerce/brands',
			// 	access: [
			// 		'verifiedAccountAdmin',
			// 		'accountAdmin',
			// 		'verifiedUser',
			// 		'user',
			// 	],
			// },
		],
	},

	{
		title: 'Integrations',
		icon: IntegrationsIcon,
		link: '/integrations',
		access: ['verifiedAccountAdmin', 'accountAdmin', 'verifiedUser', 'user'],
	},
];

export async function updateSidebarCategoryList() {
	try {
		const categories = await getAppsCategories();
		const categoryList = categories.map((category) => ({
			title: category.description,
			link: `/tasks?category=${category.id}`,
			access: ['verifiedAccountAdmin', 'accountAdmin', 'verifiedUser', 'user'],
		}));

		categoryList.unshift({
			title: 'All Tasks',
			link: '/tasks',
			access: ['verifiedAccountAdmin', 'accountAdmin', 'verifiedUser', 'user'],
		});

		const appsIndex = SIDEBAR_NAV_LIST.findIndex(
			(item) => item.title === 'Tasks',
		);
		if (appsIndex !== -1) {
			SIDEBAR_NAV_LIST[appsIndex].list = categoryList;
		}
	} catch (error) {
		console.error('Error:', error);
	}
}

updateSidebarCategoryList();
