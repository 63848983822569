import React, { useEffect, useState, useContext } from 'react';
import { Box, Text, VStack, Grid } from '@chakra-ui/react';
import { CHANNELS_NAME_MAPPING } from 'src/lib/constants';
import { AppInputConfigContext } from 'src/contexts';

interface CampaignDetailsProps {
	enabledChannels?: string[];
	targetAudience?: string[];
	campaignTone?: string[];
}

const CampaignDetails: React.FC<CampaignDetailsProps> = ({
	enabledChannels = [],
	targetAudience = [],
	campaignTone = [],
}) => {
	const { inputConfig } = useContext(AppInputConfigContext); 
	const [audienceNames, setAudienceNames] = useState<string[]>([]);
	const [toneNames, setToneNames] = useState<string[]>([]);

	useEffect(() => {
		if (inputConfig['Audience']) {
			const matchedAudienceNames = targetAudience.map(
				(id) => inputConfig['Audience'].find((audience) => audience.value === id)?.label || id
			);
			setAudienceNames(matchedAudienceNames);
		}
	}, [targetAudience, inputConfig]);

	useEffect(() => {
		if (inputConfig['Tone']) {
			const matchedToneNames = campaignTone.map(
				(id) => inputConfig['Tone'].find((tone) => tone.value === id)?.label || id
			);
			setToneNames(matchedToneNames);
		}
	}, [campaignTone, inputConfig]);

	return (
		<Box
			p={4}
			bg="white"
			borderWidth="1px"
			borderRadius="md"
			boxShadow="sm"
			mt={5}
		>
			<Text fontWeight="bold" mb={4}>
				Campaign Details
			</Text>
			<VStack spacing={2} align="start">
				<Grid templateColumns="1fr 2fr" gap={2} w="full">
					<Text fontWeight="bold">Enabled Channels:</Text>
					<Text>
						{enabledChannels.map((c) => CHANNELS_NAME_MAPPING[c]).join(', ')}
					</Text>
					<Text fontWeight="bold">Target Audience:</Text>
					<Text>{audienceNames.join(', ')}</Text>
					<Text fontWeight="bold">Campaign Tone:</Text>
					<Text>{toneNames.join(', ')}</Text>
				</Grid>
			</VStack>
		</Box>
	);
};

export default CampaignDetails;
