import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Flex, VStack } from '@chakra-ui/react';
import {
	IChannelMediaAttributes,
	IDesignDirection,
	IChannelTextContent,
	ImageLayer,
	TextLayer,
} from 'src/lib/schemas';
import DDFields from './DDFields';
import {
	IDesignDirectionPayload,
	IPayload,
	IPerview,
} from 'src/components/campaign/DesignOutput/parts/DesignDirections/parts/utils';
import {
	editCampaignDesignDirections,
	updateCampaignCreative,
} from 'src/services/campaign';
import http from 'src/services/http';
import { toastError, toastSuccess } from 'src/services/toast';
import { CampaignContext } from 'src/contexts';
import DDPreview from './DDPreview';

interface EditDDModalProps {
	initialValues: IDesignDirection;
	designDirections: IDesignDirection[];
	isSaving: boolean;
	setIsSaving: (val: boolean) => void;
	designerMode: boolean;
	setDesignerMode: (val: boolean) => void;
	setIsUploading: (val: boolean) => void;
	onSave: () => void;
	onRefetchDD: () => void;
	closeEditModal: () => void;
}

const EditDDForm: React.FC<EditDDModalProps> = ({
	initialValues,
	isSaving,
	designerMode,
	setIsSaving,
	setDesignerMode,
	setIsUploading,
	onSave,
	designDirections,
	onRefetchDD,
	closeEditModal,
}) => {
	const { id: campaignId, campaign } = useContext(CampaignContext);
	const [isPreviewLoading, setIsPreviewLoading] = useState(false);
	const [isChangingImageLayer, setIsChangingImageLayer] = useState(false);
	const [isGeneratingDD, setIsGeneratingDD] = useState(false);
	const [editMode, setEditMode] = useState(true);
	const { flatFile, layeredFile, layers } = (initialValues as any).attributes
		.image;
	const [previewPSDMeta, setPreviewPSDMeta] = useState<IPerview>({
		flatFile,
		layeredFile,
		layers,
	});
	const [currentDesignDirection, setCurrentDesignDirection] =
		useState<IDesignDirection>(initialValues);
	const mediaContent =
		currentDesignDirection?.attributes as unknown as IChannelMediaAttributes;

	const getLayersWithParents = (type: string) => {
		const layersCopy = [...(mediaContent.image?.layers ?? [])];

		return layersCopy
			.filter((layer: any) => layer.type === type && layer.primary)
			.map((layer: any) => {
				const parents = [];
				let currentLayer = layer;

				while (currentLayer?.parentName) {
					const parentLayer = mediaContent.image?.layers.find(
						(l) => l.name === currentLayer.parentName,
					);

					if (parentLayer) {
						parents.unshift(currentLayer.parentName);
					}

					currentLayer = parentLayer;
				}

				return {
					...layer,
					parents,
				};
			});
	};

	const [textLayersContent, setTextLayersContent] = useState<any>(
		getLayersWithParents('text'),
	);
	const [mediaLayersContent, setMediaLayersContent] = useState<any>(
		getLayersWithParents('image'),
	);

	useEffect(() => {
		if (isSaving) {
			try {
				handleEditDD(currentDesignDirection);
			} catch (error) {
				toastError(error);
				setIsSaving(false);
			}
		}
	}, [isSaving]);

	useEffect(() => {
		setIsUploading(isChangingImageLayer);
	}, [isChangingImageLayer]);

	useEffect(() => {
		const image = mediaContent?.image || {};
		if (currentDesignDirection && image && image.layeredFile) {
			setPreviewPSDMeta((prevState) => ({
				...prevState,
				flatFile: image.flatFile,
				layeredFile: image.layeredFile,
				layers: image.layers,
			}));
		}
	}, [mediaContent?.image?.layeredFile]);

	useEffect(() => {
		if (currentDesignDirection !== null) {
			const updatedDD = designDirections.find(
				(dd) => dd.id === currentDesignDirection.id,
			);
			updatedDD && setCurrentDesignDirection(updatedDD);
		}
	}, [designDirections, currentDesignDirection]);

	const handleChangeTextLayerContent = useCallback(() => {
		document.getElementById('change-text-layer-button-preview')?.click();
	}, []);

	const updateDD = async (
		payload: IPerview,
		designDirection: IDesignDirection,
	) => {
		const attributes =
			designDirection?.attributes as unknown as IChannelMediaAttributes;
		if (!attributes || !campaignId) return;

		const updatePayload = {
			attributes: {
				...attributes,
				image: {
					...attributes.image,
					layeredFile: payload.layeredFile,
					flatFile: payload.flatFile,
					layers: payload.layers,
					changesApplied: payload.changesApplied
				},
				headline: textLayersContent.find(
					(layer: any) => layer.name === '$Headline',
				)?.content,
			},
		};
		await updateCampaignCreative(campaignId, designDirection.id, updatePayload);
		onRefetchDD();
	};

	const resetLoadingStates = (payload: IPayload) => {
		setIsPreviewLoading(false);
		payload.actions.action === 'newDD' && setIsGeneratingDD(false);
		payload.actions.action === 'changeImage' && setIsChangingImageLayer(false);
	};

	const changeTextLayer = async (
		payload: IDesignDirectionPayload,
		dd?: IDesignDirection,
	) => {
		const attributes =  dd?.attributes as IChannelMediaAttributes

		const { layeredFile } = payload.psdPerview;
		const textValues = payload.text.map((layer: any) => ({
			value: layer.content,
			layer: layer.name,
			visible: layer.visible,
			property: 'text',
		}));
		const imageValues = payload.image?.map((layer) => ({
			value: layer.imageUrl,
			layer: layer.name,
			visible: layer.visible,
			property: 'image',
		}));

		const values = [...textValues, ...(imageValues ?? [])];

		const changeLayerPayload = {
			inputs: {
				layeredFile,
				currentLayeredFile: attributes.image.layeredFile,
				changesToBeApplied: attributes.image.changesApplied,
				values,
			},
		};

		try {
			const { data } = await http.post(
				'/v2/apps/fusion_ai.edit_psd/execute/edit_psd',
				changeLayerPayload,
			);
			if (data) await processCallback(data.callback, payload, dd);
		} catch (error) {
			toastError(error);
			resetLoadingStates(payload);
			setIsSaving(false);
		}
	};

	const processCallback = async (
		callbackUrl: string,
		payload: IDesignDirectionPayload,
		dd?: IDesignDirection,
	) => {
		try {
			const response = await http.get(callbackUrl);
			const { status, body } = response.data;
			if (status === 'processing' || status === 'pending') {
				setTimeout(processCallback.bind(null, callbackUrl, payload, dd), 500);
				// setIsSaving(true);
			} else if (status === 'error' || status === 'failed') {
				toastError('Error processing image');
				resetLoadingStates(payload);
				setIsSaving(false);
			} else if (status === 'successful') {
				const updatedTextLayers = textLayersContent.map((layer: any) => {
					return {
						...layer,
						content:
							payload.text.find((l: any) => l.name === layer.name)?.content ??
							layer.content,
					};
				});

				const updatedMediaLayers = mediaLayersContent.map(
					(layer: ImageLayer) => {
						return {
							...layer,
							content:
								payload.image?.find((l) => l.name === layer.name)?.imageUrl ??
								layer.imageUrl,
						};
					},
				);

				const currentLayers = (currentDesignDirection as any).attributes.image
					.layers;

				const mergeLayers = (originalLayers: any[], updatedLayers: any[]) => {
					return originalLayers.map((originalLayer) => {
						const updatedLayer = updatedLayers.find(
							(layer) => layer.name === originalLayer.name,
						);
						return updatedLayer ? updatedLayer : originalLayer;
					});
				};

				const mergedTextLayers = mergeLayers(currentLayers, updatedTextLayers);
				const mergedMediaLayers = mergeLayers(
					mergedTextLayers,
					updatedMediaLayers,
				);

				const layers = [...mergedMediaLayers];

				setPreviewPSDMeta((prevState) => ({
					...prevState,
					flatFile: body.flatFile,
					layeredFile: body.layeredFile,
					layers,
				}));

				if (isSaving) {
					await updateDD(
						{
							flatFile: body.flatFile,
							layeredFile: body.layeredFile,
							layers,
							changesApplied:body.changesApplied
						},
						payload.designDirection,
					);
					onSave();
					toastSuccess('Your changes have been saved');
					setIsSaving(false);
				}

				payload.actions.changeDD &&
					dd &&
					setCurrentDesignDirection((prev) => {
						if (prev?.id === dd.id) return prev;
						setEditMode(true);
						return dd;
					});

				resetLoadingStates(payload);
			}
		} catch (e) {
			console.log({ e });
			toastError(e);
			resetLoadingStates(payload);
			setIsSaving(false);
		}
	};

	const handleEditDD = (dd: IDesignDirection) => {
		setIsPreviewLoading(true);
		try {
			if (currentDesignDirection && editMode) {
				const payload: IDesignDirectionPayload = {
					designDirection: currentDesignDirection,
					psdPerview: {
						flatFile: previewPSDMeta.flatFile,
						layeredFile: previewPSDMeta.layeredFile,
						layers: [...textLayersContent, ...mediaLayersContent],
					},
					text: textLayersContent,
					image: mediaLayersContent,
					actions: {
						action: 'changeDD',
						generateCreatives: false,
						editMode: false,
						changeDD: true,
					},
				};
				changeTextLayer(payload, currentDesignDirection);
			} else {
				setCurrentDesignDirection((prev) => {
					if (prev?.id === dd.id) return prev;
					setEditMode(true);
					return dd;
				});
				setIsPreviewLoading(false);
				setIsSaving(false);
			}
		} catch (error) {
			console.error(error);
			setIsPreviewLoading(false);
			setIsSaving(false);
		}
	};

	const handleChangingPreviewLoading = (status: Record<string, boolean>) => {
		const { preview, newDD, changeImage } = status;
		preview !== undefined && setIsPreviewLoading(preview);
		newDD !== undefined && setIsGeneratingDD(newDD);
		changeImage !== undefined && setIsChangingImageLayer(changeImage);
	};

	const handleChangeMediaImage = (
		flatFile: string,
		layeredFile: string,
		layers: (ImageLayer | TextLayer)[],
	) => {
		if (isChangingImageLayer) {
			return;
		}

		setPreviewPSDMeta((prevState) => ({
			...prevState,
			flatFile: flatFile,
			layeredFile: layeredFile,
			layers: layers,
		}));

		setIsChangingImageLayer(true);

		const payload: IDesignDirectionPayload = {
			designDirection: currentDesignDirection,
			psdPerview: {
				flatFile: flatFile,
				layeredFile: layeredFile,
				layers: layers,
			},
			text: textLayersContent,
			image: mediaLayersContent,
			actions: {
				action: 'changeImage',
				generateCreatives: false,
				editMode: false,
			},
		};

		changeTextLayer(payload, currentDesignDirection);
	};

	return (
		<Flex w="full" direction="column" gap={5}>
			<Flex w="full" gap={5} overflowY="hidden" py={2}>
				<VStack
					spacing={4}
					align="stretch"
					flex={1}
					overflowY="hidden"
					maxH="60vh"
				>
					<Box overflowY="auto" maxH="70vh" w="full" pr={4} pt={2}>
						<DDFields
							designDirection={initialValues}
							onLayersTextChange={handleChangeTextLayerContent}
							previewPSD={(initialValues?.attributes as any).image}
							isChangingLayer={isChangingImageLayer}
							onChangingLayer={setIsChangingImageLayer}
							onChangePreviewPSD={handleChangeMediaImage}
							setTextLayersContent={setTextLayersContent}
							setMediaLayersContent={setMediaLayersContent}
							textLayersContent={textLayersContent}
							mediaLayersContent={mediaLayersContent}
						/>
					</Box>
				</VStack>

				<VStack
					spacing={4}
					align="center"
					flex={1}
					overflow="hidden"
					py={2}
					alignItems="flex-end"
				>
					<DDPreview
						designDirection={initialValues}
						textLayerContent={textLayersContent}
						mediaLayersContent={mediaLayersContent}
						onRefetchDesignDirections={onRefetchDD}
						previewPSD={previewPSDMeta.layeredFile}
						isChangingLayer={isChangingImageLayer}
						setDesignerMode={setDesignerMode}
						designerMode={designerMode}
						closeEditModal={closeEditModal}
					/>
				</VStack>
			</Flex>
		</Flex>
	);
};

export default EditDDForm;
