const urlRegex =
	/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;

export const validateWebsiteUrl = (
	url: string,
): { isValid: boolean; updatedUrl: string } => {
	if (!url) return { isValid: false, updatedUrl: '' };

	// Trim and normalize the URL
	let updatedUrl = url.trim();

	// Remove trailing slash if present
	if (updatedUrl.endsWith('/')) {
		updatedUrl = updatedUrl.slice(0, -1);
	}

	// Add http if not present
	if (!updatedUrl.startsWith('http')) {
		updatedUrl = `https://${updatedUrl}`;
	}

	// Add www if not present
	if (
		(updatedUrl.startsWith('https://') || updatedUrl.startsWith('http://')) &&
		!updatedUrl.includes('www.')
	) {
		const urlParts = updatedUrl.split('//');
		updatedUrl = `${urlParts[0]}//www.${urlParts[1]}`;
	}

	// Validate the URL format
	const isValid = urlRegex.test(updatedUrl);
	return { isValid, updatedUrl };
};
