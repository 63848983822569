import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import Sidebar from './Sidebar';
import AssistantChat from 'src/components/assistantChat';
import { AssistantChatContext } from 'src/contexts';
import TopBar from './TopBar';
import { usePopup } from 'src/contexts/PopupContext';
import useWindowSize from 'src/hooks/useWindowSize';
import UserContext from 'src/contexts/UserContext';
import DesktopForcer from 'src/components/common/DesktopForcer';
import DeviceNotSupported from 'src/components/common/DeviceNotSupported';

const PAGES_WITH_OWN_WRAPPER = ['/home', '/tasks', '/campaigns'];

const MainLayout = () => {
	const { isChatOpen } = useContext(AssistantChatContext);
	const { pathname } = useLocation();
	const { isPopupVisible, setPopupVisible } = usePopup();
	const { width } = useWindowSize();
	const isMobile = width < 769;
	const { user } = useContext(UserContext);
	const shouldForceDesktop = user && isMobile && !isPopupVisible;

	const isTaksPath = pathname.includes('tasks');

	useEffect(() => {
		if (isMobile) {
			setPopupVisible(true);
			return;
		}
		setPopupVisible(false);
	}, [width, setPopupVisible]);

	const handleClosePopup = () => {
		setPopupVisible(false);
		sessionStorage.setItem('popupClosed', 'true');
	};

	return (
		<Box h="100vh" w="100vw" m={0}>
			{shouldForceDesktop && <DesktopForcer isAuthenticated={!!user} />}
			{isPopupVisible && width < 769 && (
				<DeviceNotSupported onClose={handleClosePopup} />
			)}
			{!isPopupVisible && (
				<>
					<Sidebar />
					<TopBar />
					<Box
						minH="calc(100vh - 72px)"
						w={{ base: '100vw', md: 'calc(100vw - 250px)' }}
						ml={{ base: 0, md: '250px' }}
						p="28px"
						bg="white"
						display="flex"
						flexDirection="column"
						overflowX="hidden"
						overflowY="auto"
					>
						{isChatOpen && <AssistantChat />}
						<Box w="100%" h="100%" mt="72px">
							{PAGES_WITH_OWN_WRAPPER.includes(pathname) || isTaksPath ? (
								<Outlet />
							) : (
								<Outlet />
							)}
						</Box>
					</Box>
				</>
			)}
		</Box>
	);
};

export default MainLayout;
