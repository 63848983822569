import React, { useContext, useMemo, useState, useEffect } from 'react';
import {
	Box,
	Button,
	Text,
	useColorModeValue,
	Grid,
	HStack,
	IconButton,
	Flex,
	Tooltip,
} from '@chakra-ui/react';
import { FaThLarge, FaTh } from 'react-icons/fa';
import { MdOutlineViewModule } from 'react-icons/md';
import TemplateContext from 'src/contexts/templates/TemplatesContext';
import CustomModal from 'src/components/common/CustomModal';
import IndustryLabelSelector from 'src/components/common/IndustryLabelSelector';
import { generateDesignDirectionWithTemplate } from 'src/services/campaign';
import { toastError } from 'src/services/toast';
interface SelectTemplateModalProps {
	isOpen: boolean;
	onClose: () => void;
	header?: string;
	campaignId: string;
	handleRefreshDesignDirections: () => Promise<void>;
	size?: 'full' | any;
	modalStyle?: React.CSSProperties;
	isTemplateModal?: boolean;
	setIsGeneratingDD: React.Dispatch<React.SetStateAction<boolean>>;
}
const SelectTemplateModal: React.FC<SelectTemplateModalProps> = ({
	isOpen,
	onClose,
	header = 'Select a Template',
	size = 'full',
	campaignId,
	handleRefreshDesignDirections,
	modalStyle,
	isTemplateModal,
	setIsGeneratingDD,
}) => {
	const { fetchTemplatesByScope, templatesByScope, templates } =
		useContext(TemplateContext);
	const [scope, setScope] = useState<string>('all');
	const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
		null,
	);
	const [columns, setColumns] = useState<number>(7);
	const [tooltipsEnabled, setTooltipsEnabled] = useState<boolean>(false);
	useEffect(() => {
		const loadTemplates = async () => {
			try {
				await fetchTemplatesByScope('');
			} catch (error: any) {
				console.error('Error loading templates by scope:', error.message);
			}
		};
		loadTemplates();
	}, [fetchTemplatesByScope]);
	useEffect(() => {
		if (isOpen) {
			setTooltipsEnabled(false);
			const timer = setTimeout(() => setTooltipsEnabled(true), 500);
			return () => clearTimeout(timer);
		}
	}, [isOpen]);
	const handleScopeChange = (newScope: string) => {
		setScope(newScope);
		setSelectedTemplateId(null);
	};
	const filteredTemplates = useMemo(() => {
		if (scope === 'all') {
			const myTemplates = templates.filter(
				(template) => template.scope === 'account',
			);
			const platformTemplates = templatesByScope
				.filter((template) => template.scope === 'platform')
				.sort(
					(a, b) =>
						(b.attributes?.priority || 0) - (a.attributes?.priority || 0),
				);

			if (selectedTemplateId) {
				const selectedTemplate = templates.find(
					(template) => template.id === selectedTemplateId,
				);
				if (selectedTemplate) {
					if (
						selectedTemplate.scope === 'account' &&
						!myTemplates.includes(selectedTemplate)
					) {
						myTemplates.unshift(selectedTemplate);
					} else if (
						selectedTemplate.scope === 'platform' &&
						!platformTemplates.includes(selectedTemplate)
					) {
						platformTemplates.unshift(selectedTemplate);
					}
				}
			}
			return { myTemplates, platformTemplates };
		} else if (scope === 'my_templates') {
			const myTemplates = templates.filter(
				(template) => template.scope === 'account',
			);
			if (selectedTemplateId) {
				const selectedTemplate = templates.find(
					(template) => template.id === selectedTemplateId,
				);
				if (
					selectedTemplate &&
					selectedTemplate.scope === 'account' &&
					!myTemplates.includes(selectedTemplate)
				) {
					myTemplates.unshift(selectedTemplate);
				}
			}
			return { myTemplates };
		} else {
			const platformTemplates = templatesByScope
				.filter(
					(template) =>
						template.scope === 'platform' &&
						template.attributes?.industries?.includes(scope),
				)
				.sort((a, b) => {
					const positionComparison =
						templatesByScope.indexOf(a) - templatesByScope.indexOf(b);
					if (positionComparison !== 0) {
						return positionComparison;
					}
					return (b.attributes?.priority || 0) - (a.attributes?.priority || 0);
				});

			if (selectedTemplateId) {
				const selectedTemplate = templates.find(
					(template) => template.id === selectedTemplateId,
				);
				if (
					selectedTemplate &&
					selectedTemplate.scope === 'platform' &&
					!platformTemplates.includes(selectedTemplate)
				) {
					platformTemplates.unshift(selectedTemplate);
				}
			}
			return { platformTemplates };
		}
	}, [scope, templates, templatesByScope, selectedTemplateId]);
	const handleTemplateSelect = (templateId: string) => {
		setSelectedTemplateId(templateId);
	};
	const handleGenerateDesign = async () => {
		if (!selectedTemplateId || !campaignId) {
			return;
		}
		setIsGeneratingDD(true);
		onClose();
		try {
			await generateDesignDirectionWithTemplate(campaignId, selectedTemplateId);
			await handleRefreshDesignDirections();
		} catch (error: any) {
			console.error('Error generating design direction:', error.message);
			toastError('Error generating design direction');
			setIsGeneratingDD(false);
		}
	};
	const renderTemplatesGrid = (templatesList: any[]) => (
		<Grid templateColumns={`repeat(${columns}, 1fr)`} gap={6} mt={4}>
			{templatesList.map((template) => (
				<Box
					key={template.id}
					borderWidth="3px"
					borderRadius="md"
					overflow="hidden"
					boxShadow={
						selectedTemplateId === template.id ? '0 0 10px #F7480B' : 'md'
					}
					cursor="pointer"
					bg={
						selectedTemplateId === template.id
							? 'white'
							: useColorModeValue('white', 'gray.700')
					}
					padding="3px"
					border={
						selectedTemplateId === template.id
							? '3px solid #F7480B'
							: '3px solid transparent'
					}
					_hover={
						selectedTemplateId === template.id
							? {}
							: {
									transform: 'scale(1.05)',
									transition:
										'transform 0.2s ease-in-out, border 0.2s ease-in-out',
									border: '3px solid #D9D9D9',
							  }
					}
					transition="transform 0.3s ease-in-out, border 0.3s ease-in-out"
					width="100%"
					height="auto"
					onClick={() => handleTemplateSelect(template.id)}
				>
					{template.attributes?.thumbnail ? (
						<img
							src={template.attributes.thumbnail}
							alt={template.name}
							style={{ width: '100%', height: '100%', objectFit: 'cover' }}
						/>
					) : (
						<img
							src="https://via.placeholder.com/167x167"
							alt="Default thumbnail"
							style={{ width: '100%', height: '100%', objectFit: 'cover' }}
						/>
					)}
				</Box>
			))}
		</Grid>
	);
	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			header={
				<Box>
					<HStack justifyContent="space-between" w="full" mb={2}>
						<Text fontWeight="bold" fontSize="16px" color="black">
							{header}
						</Text>
						<HStack spacing={3} pr={6}>
							{tooltipsEnabled && (
								<>
									<Tooltip label="Large view" borderRadius={100} px={3}>
										<IconButton
											aria-label="Large icons"
											icon={<FaThLarge />}
											onClick={() => setColumns(3)}
											variant={columns === 3 ? 'solid' : 'ghost'}
										/>
									</Tooltip>
									<Tooltip label="Medium view" borderRadius={100} px={3}>
										<IconButton
											aria-label="Medium icons"
											icon={<FaTh />}
											onClick={() => setColumns(5)}
											variant={columns === 5 ? 'solid' : 'ghost'}
										/>
									</Tooltip>
									<Tooltip label="Small view" borderRadius={100} px={3}>
										<IconButton
											aria-label="Small icons"
											icon={<MdOutlineViewModule />}
											onClick={() => setColumns(7)}
											variant={columns === 7 ? 'solid' : 'ghost'}
										/>
									</Tooltip>
								</>
							)}
						</HStack>
					</HStack>
					<Box mt={2}>
						<IndustryLabelSelector
							selectedScope={scope}
							onScopeChange={handleScopeChange}
							templatesByScope={templatesByScope}
							templates={templates}
						/>
					</Box>
				</Box>
			}
			footer={
				<Flex justify="flex-end" align="center" w="full">
					<Button
						isDisabled={!selectedTemplateId}
						variant="orangeSolid"
						_hover={{
							opacity: 0.9,
						}}
						_disabled={{
							opacity: 0.7,
							cursor: 'not-allowed',
						}}
						onClick={handleGenerateDesign}
					>
						Generate design
					</Button>
				</Flex>
			}
			size={size}
			isTemplateModal={true}
		>
			{scope === 'all' ? (
				<>
					{}
					{filteredTemplates.myTemplates &&
						filteredTemplates.myTemplates.length > 0 && (
							<Box mb={6}>
								<Text fontSize="14px" fontWeight="regular" mb={4}>
									My Templates
								</Text>
								{renderTemplatesGrid(filteredTemplates.myTemplates)}
							</Box>
						)}
					{}
					<Box>
						<Text fontSize="14px" fontWeight="regular" mb={4}>
							All Templates
						</Text>
						{filteredTemplates.platformTemplates &&
						filteredTemplates.platformTemplates.length > 0 ? (
							renderTemplatesGrid(filteredTemplates.platformTemplates)
						) : (
							<Text>No platform templates found.</Text>
						)}
					</Box>
				</>
			) : scope === 'my_templates' ? (
				<Box>
					{filteredTemplates.myTemplates &&
					filteredTemplates.myTemplates.length > 0 ? (
						renderTemplatesGrid(filteredTemplates.myTemplates)
					) : (
						<Text>No personal templates available.</Text>
					)}
				</Box>
			) : (
				<>
					{filteredTemplates.platformTemplates &&
					filteredTemplates.platformTemplates.length > 0 ? (
						renderTemplatesGrid(filteredTemplates.platformTemplates)
					) : (
						<Text>No templates found for the selected industry.</Text>
					)}
				</>
			)}
		</CustomModal>
	);
};
export default SelectTemplateModal;
