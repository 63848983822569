import React, { useContext, useEffect, useRef, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	FormControl,
	Heading,
	Switch,
	FormLabel,
	Image,
} from '@chakra-ui/react';
import { uploadFile } from 'src/services/fileUpload';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { ArrowBackIcon } from '@chakra-ui/icons';
import TemplatesContext, {
	ITemplate,
} from 'src/contexts/templates/TemplatesContext';
import {
	updateTemplate,
	createTemplate,
	getTemplateById,
	getFonts,
} from 'src/services/templates';
import { getIndustries } from 'src/services/industries';
import { toastError, toastSuccess } from 'src/services/toast';
import {
	MultiSelectValuesHook,
	StringInputHook,
	MultiSelectCreateableInputHook,
} from 'src/components/common/form';
import { FileInput } from 'src/components/common/form';
import WrappedVariationsList from './VariationsList';
import UserContext from 'src/contexts/UserContext';
import TagInputHook from 'src/components/common/form/TagsCreateableInput/TagInputHook';
import TestTemplates from '../testTemplates/TestTemplates';

const TemplateSchema = z.object({
	id: z.string().optional(),
	enabled: z.boolean(),
	attributes: z
		.object({
			thumbnail: z.string().url().optional(),
			fonts: z.array(z.string()).optional(),
			industries: z.array(z.string()).optional(),
			tags: z.array(z.string()).optional(),
		})
		.optional(),
	variations: z
		.array(
			z.object({
				id: z.string().optional(),
				layeredFile: z.string().optional(),
				layerSpecs: z
					.array(
						z.object({
							name: z.string().optional(),
							actions: z
								.array(
									z.object({
										id: z.string().optional(),
										arguments: z.any().optional(),
									}),
								)
								.optional(),
						}),
					)
					.optional(),
			}),
		)
		.optional(),
	driveFolderId: z.string().optional(),
});

export type TemplateFormValues = z.infer<typeof TemplateSchema>;

const TemplatesForm: React.FC = () => {
	const location = useLocation();
	const templateData = location.state?.template;

	const navigate = useNavigate();
	const { id: templateId } = useParams<{ id: string }>();
	const { templates, selectedTemplate, setSelectedTemplate } =
		useContext(TemplatesContext);
	const { user } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState(false);
	const [fontOptions, setFontOptions] = useState<
		{ label: string; value: string }[]
	>([]);
	const [industryOptions, setIndustryOptions] = useState<
		{ label: string; value: string }[]
	>([]);

	const formMethods = useForm<TemplateFormValues>({
		resolver: zodResolver(TemplateSchema),
		defaultValues: {
			id: '',
			enabled: true,
			attributes: {},
			variations: [],
		},
	});
	const { handleSubmit, reset, watch, setValue } = formMethods;

	const attributes = watch('attributes');
	const selectedFonts = watch('attributes.fonts') || [];
	const selectedIndustries = watch('attributes.industries') || [];
	const folderName = watch('id');
	const [isUploading, setIsUploading] = useState(false);

	const handleFontSelect = (newValue: string[]) => {
		setValue('attributes.fonts', newValue);
	};

	const handleIndustrySelect = (
		newValue: (string | { label: string; value: string })[],
	) => {
		if (
			newValue.length > 0 &&
			typeof newValue[0] === 'object' &&
			'value' in newValue[0]
		) {
			setValue(
				'attributes.industries',
				(newValue as { label: string; value: string }[]).map(
					(option) => option.value,
				),
			);
		} else {
			setValue('attributes.industries', newValue as string[]);
		}
	};

	const handleCreateTag = (inputValue: string) => {
		const newTag = inputValue.trim();
		if (newTag) {
			const currentTags = watch('attributes.tags') || [];
			if (!currentTags.includes(newTag)) {
				setValue('attributes.tags', [...currentTags, newTag]);
			}
		}
		return newTag;
	};

	const handleVariationsGenerated = (variationsData: any) => {
		setValue('variations', variationsData);
		setValue('driveFolderId', variationsData.templateFolderId);
	};

	useEffect(() => {
		const values = formMethods.getValues();
		const newTemplate: ITemplate = {
			...values,
			id: values.id || 'No title',
			variations: values.variations || [],
		};
		setSelectedTemplate(newTemplate);
	}, [watch('id'), watch('variations')]);

	useEffect(() => {
		if (templateData) {
			reset({ ...templateData });
		}
	}, [templateData, reset]);

	useEffect(() => {
		const loadTemplateData = async () => {
			if (templateId && user?.account) {
				setIsLoading(true);
				try {
					const updatedTemplate = await getTemplateById(
						user.account,
						templateId,
					);
					setSelectedTemplate(updatedTemplate);
					const formattedFonts = updatedTemplate.attributes?.fonts?.map(
						(font) => font.replace(/-/g, ' '),
					);
					const formattedIndustries =
						updatedTemplate.attributes?.industries || [];
					reset({
						id: updatedTemplate.id,
						enabled: updatedTemplate.enabled,
						attributes: {
							...updatedTemplate.attributes,
							fonts: formattedFonts,
							industries: formattedIndustries,
							tags: updatedTemplate.attributes?.tags || [],
						},
						variations: updatedTemplate.variations || [],
					});
				} catch (error) {
					console.error('Failed to load template data:', error);
					toastError('Failed to load template data');
				} finally {
					setIsLoading(false);
				}
			}
		};

		loadTemplateData();
	}, [templateId, user, setSelectedTemplate, reset, setValue]);

	useEffect(() => {
		const loadTemplateData = async () => {
			if (templateId && user?.account) {
				setIsLoading(true);
				try {
					const updatedTemplate = await getTemplateById(
						user.account,
						templateId,
					);
					setSelectedTemplate(updatedTemplate);
					const formattedFonts = updatedTemplate.attributes?.fonts?.map(
						(font) => font.replace(/-/g, ' '),
					);
					const formattedIndustries =
						updatedTemplate.attributes?.industries || [];
					reset({
						id: updatedTemplate.id,
						enabled: updatedTemplate.enabled,
						attributes: {
							...updatedTemplate.attributes,
							fonts: formattedFonts,
							industries: formattedIndustries,
							tags: updatedTemplate.attributes?.tags || [],
						},
						variations: updatedTemplate.variations || [],
					});
					if (updatedTemplate.attributes) {
						if (updatedTemplate.attributes.fonts) {
							setValue('attributes.fonts', formattedFonts);
						}
						if (updatedTemplate.attributes.industries) {
							setValue('attributes.industries', formattedIndustries);
						}
						if (updatedTemplate.attributes.tags) {
							setValue('attributes.tags', updatedTemplate.attributes.tags);
						}
					}
				} catch (error) {
					console.error('Failed to load template data:', error);
					toastError('Failed to load template data');
				} finally {
					setIsLoading(false);
				}
			}
		};

		loadTemplateData();
	}, [templateId, user, setSelectedTemplate, reset, setValue]);

	useEffect(() => {
		if (templateId && templates.length > 0) {
			const template = templates.find((t) => t.id === templateId) || null;
			setSelectedTemplate(template);
			if (template) {
				const formattedFonts = template.attributes?.fonts?.map((font) =>
					font.replace(/-/g, ' '),
				);
				const formattedIndustries = template.attributes?.industries || [];
				reset({
					id: template.id,
					enabled: template.enabled,
					attributes: {
						...template.attributes,
						fonts: formattedFonts,
						industries: formattedIndustries,
					},
					variations: template.variations || [],
				});
				if (template.attributes) {
					if (template.attributes.fonts) {
						setValue('attributes.fonts', formattedFonts);
						setFontOptions(
							template.attributes.fonts.map((font) => ({
								label: font.replace(/-/g, ' '),
								value: font,
							})),
						);
					}
					if (template.attributes.industries) {
						setValue('attributes.industries', formattedIndustries);
						setIndustryOptions(
							formattedIndustries.map((ind) => ({ label: ind, value: ind })),
						);
					}
				}
			}
		} else {
			setSelectedTemplate(null);
			reset({
				id: '',
				enabled: true,
				attributes: {},
				variations: [],
			});
		}
	}, [templateId, templates, setSelectedTemplate, reset, setValue]);

	const handleSave = () => {
		handleSubmit(onSubmit);
	};

	useEffect(() => {
		const fetchFonts = async () => {
			try {
				const fontsData = await getFonts();
				setFontOptions(
					fontsData.map((font) => ({
						label: font.replace(/-/g, ' '),
						value: font,
					})),
				);
			} catch (error) {
				console.error('Failed to fetch fonts', error);
			}
		};

		const fetchIndustries = async () => {
			try {
				const industries = await getIndustries();
				const formattedIndustries = industries.map((industry) => ({
					label: industry.name,
					value: industry.industryIds[0],
				}));
				setIndustryOptions(formattedIndustries);
			} catch (error) {
				console.error('Failed to fetch industries', error);
			}
		};

		fetchFonts();
		fetchIndustries();
	}, []);

	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const file = event.target.files?.[0];
		if (!file) return;

		setIsUploading(true);
		try {
			const { imageUrl } = await uploadFile(
				file,
				false,
				100,
				100,
				undefined,
				undefined,
			);
			setValue('attributes.thumbnail', imageUrl);
		} catch (error) {
			console.error('Error uploading file', error);
			toastError('Failed to upload image');
		}
		setIsUploading(false);
	};

	const onSubmit = async (data: TemplateFormValues) => {
		try {
			if (templateId) {
				const updatedTemplate: ITemplate = {
					...selectedTemplate!,
					...data,
					id: data.id || templateId,
				};
				const response = await updateTemplate(updatedTemplate, templateId);
				toastSuccess('Template updated successfully');
			} else {
				const newTemplate: ITemplate = {
					...data,
					id: data.id || 'No title',
					variations: data.variations || [],
				};
				const response = await createTemplate(newTemplate);
				toastSuccess('Template created successfully');
			}

			navigate('/account/templates');
		} catch (error) {
			console.error('Failed to submit template', error);
			toastError('Failed to submit template');
		}
	};

	const handleBack = () => {
		navigate('/account/templates');
	};

	if (isLoading) {
		return <Box>Loading...</Box>;
	}

	const textButton = templateId ? 'Save Template' : 'Create new template';

	return (
		<>
			<Box justifyContent="center" mx="auto" maxW="850px">
				<FormProvider {...formMethods}>
					<form onSubmit={handleSubmit(onSubmit)} id="hook-form">
						<Flex
							direction="column"
							gap={4}
							width="850px"
							marginBottom="80px"
							justifyContent="center"
						>
							<Button
								onClick={handleBack}
								leftIcon={<ArrowBackIcon />}
								justifyContent="left"
								variant="link"
								colorScheme="blue"
								mb={4}
							>
								Back
							</Button>
							<Flex
								direction="row"
								alignItems="center"
								justifyContent="space-between"
								width="100%"
							>
								<Heading as="h2" size="lg" width="100%">
									{templateId ? 'Edit Template' : 'New Template'}
								</Heading>
								<FormControl
									display="flex"
									alignItems="center"
									justifyContent="right"
									mr="20px"
								>
									<FormLabel htmlFor="enabled-switch" mb="0">
										Enabled
									</FormLabel>
									<Switch
										id="enabled-switch"
										isChecked={watch('enabled')}
										onChange={() => setValue('enabled', !watch('enabled'))}
									/>
								</FormControl>
							</Flex>
							<Flex direction="row" gap={4}>
								<Flex direction="column" flex="1" gap={4}>
									<FormControl maxW="400px">
										<StringInputHook name={'id'} label={'Name'} required />
									</FormControl>
									<FormControl maxW="400px">
										<FormLabel>Industry</FormLabel>
										<MultiSelectCreateableInputHook
											name="attributes.industries"
											placeholder="Select/search industries"
											options={industryOptions}
											isLoading={false}
											disableCreateOption={true}
											isMulti
											required
											onChange={handleIndustrySelect}
										/>
										<MultiSelectValuesHook
											name="attributes.industries"
											title="Selected Industries"
											options={industryOptions}
										/>
									</FormControl>
									<FormControl maxW="400px">
										<FormLabel>Fonts</FormLabel>
										<MultiSelectCreateableInputHook
											name="attributes.fonts"
											placeholder="Select/search fonts"
											options={fontOptions}
											isLoading={false}
											disableCreateOption={true}
											isMulti
											required
										/>
										<MultiSelectValuesHook
											name="attributes.fonts"
											title="Selected Fonts"
											options={fontOptions}
										/>
										<FormControl maxW="400px" mt="20px">
											<FormLabel>Tags</FormLabel>
											<TagInputHook
												name="attributes.tags"
												placeholder="Add tags"
											/>
										</FormControl>
										<MultiSelectValuesHook
											name="attributes.tags"
											title="Selected Tags"
											options={(watch('attributes.tags') || []).map((tag) => ({
												label: tag,
												value: tag,
											}))}
										/>
									</FormControl>
								</Flex>
								<Flex
									direction="column"
									alignItems="center"
									justifyContent="right"
									flex="1"
									gap={4}
								>
									<FormControl width="300px" ml="80px">
										<FormLabel>Thumbnail</FormLabel>
										{attributes?.thumbnail ? (
											<Box mt={2} width="300px" height="300px" mb="10px">
												<Image
													src={attributes.thumbnail}
													alt="Thumbnail Preview"
													width="100%"
													height="100%"
													objectFit="cover"
												/>
											</Box>
										) : (
											<Box
												mt={2}
												mb="10px"
												width="300px"
												height="300px"
												bg="gray.200"
												display="flex"
												alignItems="center"
												justifyContent="center"
											>
												<Box width="300px" height="300px" bg="gray.400"></Box>
											</Box>
										)}
										<Flex justifyContent="flex-end">
											<input
												type="file"
												accept=".png, .jpg, .jpeg, .gif, .webp, .svg"
												onChange={handleFileChange}
												style={{ display: 'none' }}
												id="file-upload"
											/>
											<Button
												as="label"
												htmlFor="file-upload"
												isLoading={isUploading}
												loadingText="Uploading..."
											>
												{attributes?.thumbnail
													? 'Change image'
													: 'Upload image'}
											</Button>
										</Flex>
									</FormControl>
								</Flex>
							</Flex>
						</Flex>
						<WrappedVariationsList
							onVariationsGenerated={handleVariationsGenerated}
							folderName={folderName || 'New Folder'}
						/>
						<Flex justifyContent="flex-end"></Flex>
					</form>
					<Box>
						<TestTemplates textButton={textButton} />
					</Box>
				</FormProvider>
			</Box>
		</>
	);
};

export default TemplatesForm;
