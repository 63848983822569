import React, { useEffect } from 'react';

interface DesktopForcerProps {
	isAuthenticated: boolean;
}

const DesktopForcer: React.FC<DesktopForcerProps> = ({ isAuthenticated }) => {
	useEffect(() => {
		if (isAuthenticated) {
			const meta = document.createElement('meta');
			meta.name = 'viewport';
			meta.content = 'width=1024, initial-scale=0.3, shrink-to-fit=no';
			document.getElementsByTagName('head')[0].appendChild(meta);

			return () => {
				document.getElementsByTagName('head')[0].removeChild(meta);
			};
		}
	}, [isAuthenticated]);

	return null;
};

export default DesktopForcer;
