import React, { useCallback, useMemo } from 'react';
import { Box, Button, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { CellContext } from '@tanstack/react-table';

import {
	DataTable,
	DataTablePagination,
	TActions,
} from 'src/components/common/DataTable';
import DataTableActions from 'src/components/common/DataTable/DataTableActions';
import { SearchInput } from 'src/components/common/form';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import useClientPagination from 'src/hooks/useClientPagination';
import useFetch from 'src/hooks/useFetch';
import {
	createTag,
	deleteTag,
	getTags,
	ITag,
	updateTag,
} from 'src/services/tags';
import CustomContainer from 'src/components/common/CustomContainer';
import TagDeleteModal from 'src/components/commerce/tags/TagsDeleteModal';
import TagFormModal from 'src/components/commerce/tags/TagsFormModal';

const getTagListingColumns = (
	handleAction: (action: TActions, data: ITag) => void,
) => [
	{
		header: 'Name',
		accessorKey: 'name',
		cell: ({ getValue }: CellContext<ITag, number>) => (
			<Flex pl="10px" width="100%">
				<Text>{getValue()}</Text>
			</Flex>
		),
	},
	{
		header: () => (
			<Text textAlign="right" width="100%" fontWeight="bold">
				Items in the catalog
			</Text>
		),
		accessorKey: 'catalogCount',
		cell: ({ getValue }: CellContext<ITag, number>) => (
			<Flex justifyContent="flex-end" pr="80px" width="100%">
				<Text>{getValue()}</Text>
			</Flex>
		),
	},
	{
		header: () => (
			<Text textAlign="right" pr="20px" width="100%" fontWeight="bold">
				Action
			</Text>
		),
		id: 'action',
		cell: ({ row }: { row: { original: ITag } }) => (
			<Flex justifyContent="flex-end" pr="35px" width="100%">
				<DataTableActions
					data={row.original}
					actionsAllowed={['Edit', 'Delete']}
					onAction={handleAction}
				/>
			</Flex>
		),
	},
];

const TagListing: React.FC = () => {
	const formToggle = useToggleWithPayload<Partial<ITag>>();
	const deleteToggle = useToggleWithPayload<ITag>();
	const toast = useToast();

	const { isLoading, data, refetch } = useFetch<ITag[]>(getTags);
	const filteredData = useMemo(() => data?.filter((tag) => tag.scope !== 'platform') || [], [data]);
	const handleSearch = useCallback((item: ITag, query: string) => {
		return item.name.toLowerCase().includes(query.toLowerCase());
	}, []);

	const {
		data: tags,
		setSearchQuery,
		...pagination
	} = useClientPagination<ITag>({
		data: filteredData,
		searchFunction: handleSearch,
	});

	const handleCreateUpdateTag = async (payload: Partial<ITag>) => {
		try {
			if (payload._id) {
				const updates = {
					name: payload.name,
					value: payload.name,
				};
				await updateTag(payload._id, updates);
				toast({
					title: 'Tag updated',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
			} else {
				await createTag({ name: payload.name! });
				toast({
					title: 'Tag created',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
			}
			refetch();
			formToggle.onClose();
		} catch (error) {
			toast({
				title: 'Error',
				description: 'Failed to save tag',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	const handleDeleteTag = async (tag: ITag) => {
		try {
			await deleteTag(tag._id);
			toast({
				title: 'Tag deleted',
				status: 'success',
				duration: 3000,
				isClosable: true,
			});
			refetch();
			deleteToggle.onClose();
		} catch (error) {
			toast({
				title: 'Error',
				description: 'Failed to delete tag',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	const handleAction = (action: TActions, tag: ITag) => {
		switch (action) {
			case 'Edit':
				formToggle.onOpen(tags.find((t) => t._id === tag._id));
				break;
			case 'Delete':
				deleteToggle.onOpen(tags.find((t) => t._id === tag._id));
				break;
			default:
				console.warn(`Unknown action: ${action}`);
				break;
		}
	};

	const columns = getTagListingColumns(handleAction);

	return (
		<CustomContainer>
			<Flex direction="column" gap={6}>
				<Flex alignItems="center" justifyContent="space-between">
					<Heading>Tags</Heading>
					<Flex gap={6} flex={1} justify="flex-end" alignItems="center">
						<Box w={80} pl="60px">
							<SearchInput
								placeholder="Search tags..."
								onChange={setSearchQuery}
							/>
						</Box>
						<Button
							leftIcon={<AddIcon />}
							onClick={() => formToggle.onOpen()}
							colorScheme="secondary"
							size="sm"
							h="40px"
						>
							Add Tag
						</Button>
					</Flex>
				</Flex>
				<Box>
					<DataTable
						columns={columns}
						data={tags}
						isLoading={isLoading}
						tableProps={{
							border: '1px solid #F5F5F5',
							minH: '400px',
						}}
						trProps={{
							borderBottomWidth: '1px',
							borderColor: '#F5F5F5',
						}}
					/>
					<DataTablePagination {...pagination} />
				</Box>
				<TagFormModal
					isOpen={formToggle.isOpen}
					onClose={formToggle.onClose}
					onSubmit={handleCreateUpdateTag}
					title={formToggle.payload?._id ? 'Edit Tag' : 'Add Tag'}
					initialValues={formToggle.payload}
				/>
				<TagDeleteModal
					isOpen={deleteToggle.isOpen}
					onClose={deleteToggle.onClose}
					onConfirm={handleDeleteTag}
					tag={deleteToggle.payload}
				/>
			</Flex>
		</CustomContainer>
	);
};

export default TagListing;
