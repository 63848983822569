import { Flex, FormLabel, Wrap, WrapItem } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { ICatalog } from 'src/lib/schemas';
import ProductCard from './Product';
import LoadingCard from './LoadingCard';

interface ICatalogList {
	isLoading: boolean;
	isProductByAILoading: boolean;
	products: ICatalog[];
	selected?: ICatalog;
	onAddProduct: () => void;
	onSelect: (product: ICatalog) => void;
}

const ProductList: FC<ICatalogList> = ({
	products,
	selected,
	isLoading,
	isProductByAILoading,
	onAddProduct,
	onSelect,
}) => {
	const renderedProducts = products.map((product) => (
		<WrapItem key={product.catalogId}>
			<ProductCard
				product={product}
				onClick={() => onSelect(product)}
				selected={selected}
			/>
		</WrapItem>
	));

	return (
		<Flex direction="column">
			<Flex direction="column">
				<Flex justify="space-between" alignItems="center">
					<FormLabel>
						<Flex gap={1} alignItems="center" fontSize="16px">
							What do you want to promote?
						</Flex>
					</FormLabel>
				</Flex>
			</Flex>
			<Wrap justify="start">
				{isLoading
					? Array(3)
							.fill(null)
							.map((_, index) => (
								<WrapItem key={index} mx="5px" mb="7px">
									<LoadingCard />
								</WrapItem>
							))
					: renderedProducts}
				{isProductByAILoading && (
					<WrapItem mx="5px">
						<LoadingCard />
					</WrapItem>
				)}
				<WrapItem>
					<ProductCard onClick={onAddProduct} />
				</WrapItem>
			</Wrap>
		</Flex>
	);
};

export default ProductList;
