import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';

import {
	DataTable,
	DataTablePagination,
	TActions,
} from 'src/components/common/DataTable';
import {
	getProductsListingColumns,
	ProductDeleteModal,
	ProductFormModal,
} from 'src/components/commerce/products';
import { SearchInput } from 'src/components/common/form';
import useFetch from 'src/hooks/useFetch';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import useClientPagination from 'src/hooks/useClientPagination';
import {
	createAccountConfigOptions,
	deleteAccountConfigOptions,
	getAccountConfigOptions,
	updateAccountConfigOptions,
} from 'src/services/account';
import { toastError } from 'src/services/toast';
import { ICatalog, ICatalogForm } from 'src/lib/schemas';
import CustomContainer from 'src/components/common/CustomContainer';

import useCatalogKinds from 'src/hooks/useCatalogKinds';
import { withCampaignProvider, withAppConfigProvider } from 'src/contexts';

interface ICompanyData {
	url?: string;
}

const ProductListing = () => {
	const formToggle = useToggleWithPayload<Partial<ICatalog>>();
	const deleteToggle = useToggleWithPayload<ICatalog>();
	const navigate = useNavigate();
	const [companyUrl, setCompanyUrl] = useState('');

	const { isLoading, data, refetch } = useFetch<ICatalog[]>(() =>
		getAccountConfigOptions('catalogs'),
	);

	const {
		catalogKinds,
		isLoading: isCatalogKindsLoading,
		error: catalogKindsError,
	} = useCatalogKinds();

	useEffect(() => {
		const fetchCompanyUrl = async () => {
			try {
				const companyData = (await getAccountConfigOptions(
					'company',
				)) as ICompanyData;
				setCompanyUrl(companyData.url || '');
			} catch (error) {
				console.error('Error fetching company URL:', error);
				toastError('Failed to fetch company URL');
			}
		};
		fetchCompanyUrl();
	}, []);

	const handleSearch = useCallback((item: ICatalog, query: string) => {
		return item.name.toLowerCase().includes(query.toLowerCase());
	}, []);

	const {
		data: products,
		setSearchQuery,
		...pagination
	} = useClientPagination<ICatalog>({
		data: data || [],
		searchFunction: handleSearch,
	});

	const handleCreateUpdateProduct = async (payload: ICatalogForm) => {
		try {
			if (!payload.id) {
				await createAccountConfigOptions('catalogs', payload);
			} else {
				await updateAccountConfigOptions('catalogs', payload);
			}
			refetch();
		} catch (error) {
			toastError(error);
		}
	};

	const handleDeleteProduct = async (product: ICatalog) => {
		try {
			await deleteAccountConfigOptions('catalogs', product.id);
			refetch();
		} catch (error) {
			toastError(error);
		}
	};

	const handleAction = (action: TActions, data: ICatalog) => {
		switch (action) {
			case 'Edit':
				formToggle.onOpen(products.find((u) => u.id === data.id));
				break;
			case 'Delete':
				deleteToggle.onOpen(products.find((u) => u.id === data.id));
				break;
			case 'Create campaign':
				navigate('/projects/campaigns/new', {
					state: {
						type: 'catalogs',
						option: { value: data.id, label: data.name, url: data.url },
					},
				});
				break;
			default:
				break;
		}
	};

	const handleOpenAddModal = () => {
		formToggle.onOpen({ kind: 'service', url: companyUrl });
	};

	const columns = getProductsListingColumns(handleAction);

	const mappedProducts = useMemo(() => {
		return products.map((product) => ({
			...product,
		}));
	}, [products]);

	useEffect(() => {
		if (catalogKindsError) {
			console.error('Error loading catalog kinds:', catalogKindsError);
			toastError('Failed to load catalog kinds');
		}
	}, [catalogKindsError]);

	return (
		<CustomContainer>
			<Flex direction="column" gap={6}>
				<Flex alignItems="center" justifyContent="space-between">
					<Heading>Catalog</Heading>
					<Flex gap={6}>
						<Box>
							<SearchInput
								placeholder="Search product or service"
								onChange={setSearchQuery}
							/>
						</Box>
						<Button
							leftIcon={<AddIcon />}
							onClick={handleOpenAddModal}
							colorScheme="secondary"
							size="sm"
							h="40px"
						>
							Add catalog
						</Button>
					</Flex>
				</Flex>
				<Box>
					<DataTable
						columns={columns}
						data={mappedProducts}
						isLoading={isLoading}
						tableProps={{
							border: '1px solid #F5F5F5',
							minH: '400px',
						}}
						trProps={{
							borderBottomWidth: '1px',
							borderColor: '#F5F5F5',
						}}
					/>
					<DataTablePagination {...pagination} />
				</Box>

				<ProductFormModal
					isOpen={formToggle.isOpen}
					catalogKinds={catalogKinds}
					onClose={formToggle.onClose}
					onSubmit={handleCreateUpdateProduct}
					title={`${formToggle.payload?.id ? 'Edit' : 'Add'} Catalog`}
					initialValues={formToggle.payload}
				/>
				<ProductDeleteModal
					isOpen={deleteToggle.isOpen}
					onClose={deleteToggle.onClose}
					onConfirm={handleDeleteProduct}
					data={deleteToggle.payload}
				/>
			</Flex>
		</CustomContainer>
	);
};

const ProductListingWithProvider = withCampaignProvider(ProductListing);
const ProductListingWithAppConfigProvider = withAppConfigProvider(
	ProductListingWithProvider,
);
export default ProductListingWithAppConfigProvider;
