import { IIndustry } from 'src/services/industries';
import { z } from 'zod';
import { IOption } from '../misc';

const IndustrySchema: z.ZodType<IIndustry> = z.lazy(() =>
	z.object({
		industryIds: z.array(z.string()),
		name: z.string(),
		parent: z.union([z.null(), z.lazy(() => IndustrySchema)]).optional(),
	}),
);

const OptionSchema: z.ZodType<IOption> = z.lazy(() =>
	z.object({
		label: z.string(),
		value: z.string(),
	}),
);

export interface ISignupForm {
	name: string;
	description?: string;
	industry?: IIndustry;
	logo?: string;
	fontType?: IOption;
	audiences?: Array<string>;
	tones?: Array<string>;
	color?: string;
	entityType?: string;
}

export const SignupSchema: Zod.ZodType<ISignupForm> = z.object({
	name: z.string().nonempty('Name is required'),
	description: z.string().optional(),
	industry: IndustrySchema,
	fontType: OptionSchema.optional(),
	logo: z.string().optional(),
	color: z.string().optional(),
	entityType: z.string().optional(),
	audiences: z
		.array(z.string(), {
			errorMap: () => ({
				message: 'One of the options must be selected',
			}),
		})
		.nonempty('Audience must be selected'),
	tones: z
		.array(z.string(), {
			errorMap: () => ({
				message: 'One of the options must be selected',
			}),
		})
		.nonempty('Tone must be selected'),
});
