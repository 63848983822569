import { FC, useContext, useEffect, useState } from 'react';
import { Button, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StringInputHook } from 'src/components/common/form';
import UserContext from 'src/contexts/UserContext';
import { SingUpSchema, ISingUpFOrm } from 'src/lib/schemas';
import AlertMessage from 'src/components/common/AlertMessage';
import authService from 'src/services/auth';
import { completeRegistration } from 'src/services/scraping';

interface CustomWindow extends Window {
	dataLayer: Array<Record<string, any>>;
}
declare const window: CustomWindow;

interface ISignUpForm {
	withHeader?: boolean;
}

const SignUpForm: FC<ISignUpForm> = ({ withHeader = true }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const formMethods = useForm<ISingUpFOrm>({
		resolver: zodResolver(SingUpSchema),
	});
	const { user, setUser } = useContext(UserContext);
	const navigate = useNavigate();
	const [search] = useSearchParams();
	const redirectUrl = search.get('redirect_url');

	const watchedEmail = formMethods.watch('email');

	useEffect(() => {
		if (watchedEmail && watchedEmail !== watchedEmail.trim()) {
			formMethods.setValue('email', watchedEmail.trim());
		}
	}, [watchedEmail]);

	const onSubmit = formMethods.handleSubmit(async (formData) => {
		setError(null);
		setIsLoading(true);
		const id = authService.getCurrentUser()?.id;
		if (!id) {
			setIsLoading(false);
			return;
		}
		try {
			const user = await completeRegistration(formData, id);

			if (user && !user.isEmailVerified) {
				window.dataLayer.push({
					event: 'successful_sign_up',
					user_id: user.id,
					email: user.email,
					name: user.name,
					account_id: user.account,
				});
				authService.cleanupSession();
				setUser(null);
				navigate('/pending-verification');
			}
		} catch (error: any) {
			const { message } = error.response?.data || 'An error occurred';
			setError(message);
		}
		formMethods.reset({ password: '' });
		setIsLoading(false);
	});

	return (
		<Flex
			flex={1}
			direction="column"
			gap={3}
			maxW={{ base: '100%', sm: '80%', lg: '100%' }}
			margin="auto"
		>
			{withHeader && (
				<Heading size="md" textAlign="center">
					Complete the sign up to adjust and publish your ad for FREE
				</Heading>
			)}
			<FormProvider {...formMethods}>
				{error && <AlertMessage status="error">{error}</AlertMessage>}
				<form onSubmit={onSubmit}>
					<Flex flexDirection="column" justifyContent="center" gap="20px">
						<StringInputHook
							label="Name"
							name="name"
							placeholder="Your full name"
							required
							inputProps={{ size: 'md', fontSize: '14px' }}
						/>
						<StringInputHook
							label="Email"
							name="email"
							placeholder={`you@${user?.name
								.toLocaleLowerCase()
								.replace(/\s+/g, '')}.com`}
							required
							inputProps={{ size: 'md', fontSize: '14px' }}
						/>
						<Flex direction="column" gap="3">
							<StringInputHook
								label="Password"
								name="password"
								type={isPasswordVisible ? 'text' : 'password'}
								placeholder="Strong password"
								required
								inputProps={{ size: 'md', fontSize: '14px' }}
							/>
							<Flex alignItems="center">
								<input
									type="checkbox"
									name="showPassword"
									id="showPassword"
									onChange={() => setIsPasswordVisible(!isPasswordVisible)}
								/>
								<label htmlFor="showPassword">
									<Text fontSize="13px">&nbsp; Show password</Text>
								</label>
							</Flex>
						</Flex>
						<Flex
							flexDirection={{ base: 'column', md: 'row' }}
							justifyContent="space-between"
							alignItems="center"
							w="full"
							gap="10px"
						>
							<Text
								fontSize={{ base: '12px', md: '14px' }}
								textAlign={{ base: 'center', md: 'left' }}
							>
								By clicking “Sign Up” you agree to our{' '}
								<Link textDecor="underline">Terms of Service</Link> and{' '}
								<Link textDecor="underline">Privacy Notice</Link>
							</Text>
							<Button
								variant="orangeSolid"
								minW="120px"
								type="submit"
								isLoading={isLoading}
								loadingText="Loading..."
							>
								Sign Up
							</Button>
						</Flex>
					</Flex>
				</form>
			</FormProvider>
		</Flex>
	);
};

export default SignUpForm;
