import { Helmet } from 'react-helmet';

import { isProdEnvironment } from 'src/lib/utils';

const Scripts = () => {
	return (
		<Helmet>
			{isProdEnvironment() && (
				<script src="/js/newrelic.js" type="text/javascript" />
			)}
			{isProdEnvironment() && (
				<script src="/js/clarity.js" type="text/javascript" />
			)}
		</Helmet>
	);
};

export default Scripts;
