import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import UserContext from 'src/contexts/UserContext';

import { PublicLayout } from 'src/layout';
import {
	AcceptInvitation,
	ForgotPassword,
	Login,
	PendingVerification,
	ResetPassword,
	SignUp,
	VerifyEmail,
} from 'src/pages';
import ScrappingPage from 'src/pages/scraping';
import authService from 'src/services/auth';

const whiteListedRoutes = ['/verify-email'];

const getRoutesProtected = () => {
	const location = useLocation();
	const isAuth = authService.isAuthenticated();
	const { user } = useContext(UserContext);
	const isUserIncomplete = !user || user.permissions?.includes('demoByDomain');

	if (
		!isAuth ||
		whiteListedRoutes.includes(location.pathname) ||
		isUserIncomplete
	) {
		return <Outlet />;
	} else {
		return <Navigate to="/home" />;
	}
};

const getPublicRoutes = () => ({
	element: <PublicLayout />,
	children: [
		{
			element: getRoutesProtected(),
			children: [
				{
					path: '/login',
					element: <Login />,
				},
				{
					path: '/signup',
					element: <SignUp />,
				},
				{
					path: '/forgot-password',
					element: <ForgotPassword />,
				},
				{
					path: '/reset-password',
					element: <ResetPassword />,
				},
				{
					path: '/invitation',
					element: <AcceptInvitation />,
				},
				{
					path: '/pending-verification',
					element: <PendingVerification />,
				},
				{
					path: '/',
					element: authService.isAuthenticated() ? (
						<Navigate to="/home" />
					) : (
						<Navigate to="/login" />
					),
				},
			],
		},
		{
			path: '/verify-email',
			element: <VerifyEmail />,
		},
		{
			path: '/welcome/:id',
			element: <ScrappingPage />,
		},
	],
});

export default getPublicRoutes;
