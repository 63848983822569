import React, { useState, useEffect, useContext } from 'react';
import {
	Box,
	Flex,
	Text,
	Button,
	Link,
	Collapse,
	Icon,
	Spinner,
	useToast,
} from '@chakra-ui/react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import GoogleDriveIcon from 'src/assets/icons/GoogleDriveIcon';
import {
	main as generateDriveVariations,
	initGapiClient,
} from 'src/services/drive';
import UserContext from 'src/contexts/UserContext';
import TemplatesContext from 'src/contexts/templates/TemplatesContext';

const CLIENT_ID =
	'1003422174381-evhamlkokjku3kk0rg8cv5quqr8n36h1.apps.googleusercontent.com';
const SCOPES =
	'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';

interface Variation {
	id: string;
	title: string;
	layeredFile: string;
	isLoading: boolean;
	layerSpecs?: {
		name: string;
		actions: {
			id: string;
			arguments: any;
		}[];
	}[];
}

interface VariationItemProps {
	variation: Variation;
}

const VariationItem: React.FC<VariationItemProps> = ({ variation }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () => setIsOpen(!isOpen);

	const getViewUrl = (layeredFile: string) => {
		if (!layeredFile) return '';
		const fileId = layeredFile.split('id=')[1]?.split('&')[0];
		return fileId ? `https://drive.google.com/file/d/${fileId}/view` : '';
	};

	return (
		<Box
			mb={2}
			p={4}
			onClick={handleToggle}
			cursor="pointer"
			borderBottom="1px"
			borderColor="gray.200"
		>
			<Flex alignItems="center" justifyContent="space-between">
				<Flex alignItems="center" flex="1">
					<Text fontSize="16px" mr={4} minWidth="130px">
						{variation.title || variation.id}
					</Text>
					<Flex alignItems="center">
						<Icon as={GoogleDriveIcon} color="gray.500" w={5} h={5} mr={1} />
						{variation.layeredFile && variation.layeredFile.trim() !== '' ? (
							<Link
								href={getViewUrl(variation.layeredFile)}
								color="blue.500"
								fontSize="14px"
								display="flex"
								alignItems="center"
								isExternal
							>
								Open in GDrive{' '}
								<Icon
									as={FaExternalLinkAlt}
									color="blue.500"
									w={3}
									h={3}
									ml={1}
								/>
							</Link>
						) : (
							<Text color="gray.500" fontSize="14px">
								Not generated yet
							</Text>
						)}
					</Flex>
				</Flex>
				<Text cursor="pointer" color="black" display="flex" alignItems="center">
					Configure layers
					<Icon as={isOpen ? ChevronUpIcon : ChevronDownIcon} ml={1} />
				</Text>
			</Flex>
			<Collapse in={isOpen}>
				<Box mt={4}>{/* Layer configuration content goes here */}</Box>
			</Collapse>
		</Box>
	);
};

const VariationsList: React.FC<{
	onVariationsGenerated: (variations: Variation[]) => void;
	folderName: string;
}> = ({ onVariationsGenerated, folderName }) => {
	const { user, isLoading: isUserLoading, account } = useContext(UserContext);
	const { selectedTemplate } = useContext(TemplatesContext);

	const [variations, setVariations] = useState<Variation[]>([]);
	const [isGenerating, setIsGenerating] = useState(false);
	const [isGenerated, setIsGenerated] = useState(false);
	const toast = useToast();

	useEffect(() => {
		const defaultTitles: Record<string, string> = {
			design: 'Design Direction',
			facebook_story: 'FB/IG Story',
			facebook_feed: 'Facebook Feed',
			instagram_feed: 'Instagram Feed',
			instagram_story: 'FB/IG Story',
		};

		if (
			selectedTemplate?.variations &&
			selectedTemplate.variations.length > 0
		) {
			const formattedVariations = selectedTemplate.variations.map(
				(variation) => ({
					...variation,
					title: variation.title || defaultTitles[variation.id!] || '',
					isLoading: false,
				}),
			) as Variation[];

			setVariations(formattedVariations);
		} else {
			const initialVariations = [
				{
					id: 'design',
					title: 'Design Direction',
					layeredFile: '',
					isLoading: false,
				},
				{
					id: 'facebook_story',
					title: 'FB/IG Story',
					layeredFile: '',
					isLoading: false,
				},
				{
					id: 'facebook_feed',
					title: 'Facebook Feed',
					layeredFile: '',
					isLoading: false,
				},
				{
					id: 'instagram_feed',
					title: 'Instagram Feed',
					layeredFile: '',
					isLoading: false,
				},
				{
					id: 'instagram_story',
					title: 'FB/IG Story',
					layeredFile: '',
					isLoading: false,
				},
			];
			setVariations(initialVariations);
		}
	}, [selectedTemplate]);

	useEffect(() => {
		const allGenerated = variations.every((v) => v.layeredFile !== '');
		setIsGenerated(allGenerated);
	}, [variations]);

	const initializeGoogleApi = async (
		accessToken: string,
		maxRetries = 3,
	): Promise<boolean> => {
		for (let attempt = 0; attempt < maxRetries; attempt++) {
			try {
				await initGapiClient(accessToken, CLIENT_ID);

				if (window.gapi && window.gapi.client && window.gapi.client.drive) {
					return true;
				}

				await new Promise((resolve) => setTimeout(resolve, 1000));
			} catch (error) {
				console.error(`Error in initialization attempt ${attempt + 1}:`, error);
				if (attempt === maxRetries - 1) {
					throw error;
				}
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}
		}
		return false;
	};

	const login = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			if (isGenerated) return;
			setIsGenerating(true);
			setVariations(variations.map((v) => ({ ...v, isLoading: true })));
			try {
				const isInitialized = await initializeGoogleApi(
					tokenResponse.access_token,
				);
				if (!isInitialized) {
					throw new Error(
						'Failed to initialize Google API after multiple attempts',
					);
				}

				if (account && user) {
					const generatedVariations = await generateDriveVariations(
						tokenResponse.access_token,
						CLIENT_ID,
						folderName,
						account.id,
						account.name,
					);

					const uniqueVariations = generatedVariations.reduce(
						(acc: Variation[], current) => {
							if (current) {
								return acc.concat([{ ...current, isLoading: false }]);
							}
							return acc;
						},
						[],
					);

					setVariations(uniqueVariations);
					onVariationsGenerated(uniqueVariations);
					setIsGenerated(true);
				} else {
					throw new Error('User or account information is missing');
				}
			} catch (error) {
				console.error('Error generating variations:', error);
				setVariations(variations.map((v) => ({ ...v, isLoading: false })));
				toast({
					title: 'Error',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			} finally {
				setIsGenerating(false);
			}
		},
		onError: (error) => {
			console.error('Login Failed:', error);
			toast({
				title: 'Error',
				description: 'Google login failed. Please try again.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		},
		scope: SCOPES,
		flow: 'implicit',
	});

	return (
		<Box>
			<Flex alignItems="center" mb={4}>
				<Text fontWeight="bold" fontSize="24px" mr={2}>
					Variations
				</Text>
				<Button
					variant="link"
					colorScheme="blue"
					leftIcon={isGenerating ? <Spinner size="sm" /> : <GoogleDriveIcon />}
					onClick={() => login()}
					isDisabled={isGenerating || isGenerated}
				>
					{isGenerating
						? 'Generating...'
						: isGenerated
						? 'Generated'
						: 'Generate variations ↓'}
				</Button>
			</Flex>
			{variations
				.filter(
					(v, index, self) =>
						v.id === 'design' ||
						v.id === 'facebook_feed' ||
						v.id === 'instagram_feed' ||
						(v.id === 'facebook_story' &&
							index ===
								self.findIndex(
									(t) =>
										t.id === 'facebook_story' || t.id === 'instagram_story',
								)),
				)
				.map((variation, index) => {
					return <VariationItem key={index} variation={variation} />;
				})}
		</Box>
	);
};

const WrappedVariationsList: React.FC<{
	onVariationsGenerated: (variations: Variation[]) => void;
	folderName: string;
}> = (props) => (
	<GoogleOAuthProvider clientId={CLIENT_ID}>
		<VariationsList {...props} />
	</GoogleOAuthProvider>
);

export default WrappedVariationsList;
