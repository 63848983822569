import { ITokens, IUser } from 'src/lib/schemas';
import http from './http';
import {
	IScrappingDataResponse,
	IScrappingRegisterResponse,
} from 'src/lib/schemas/scrapping';

const apiVersion = 'v1';
const authUrl = `/${apiVersion}/auth`;
const scrapeUrl = `/${apiVersion}/scrape`;
const usersUrl = `/${apiVersion}/users`;
const tokensKey = 'tokens';
const userKey = 'user';

const setTokens = (tokens: ITokens) => {
	localStorage.setItem(tokensKey, JSON.stringify(tokens));
};

const setCurrentUser = (user: IUser) => {
	localStorage.setItem(userKey, JSON.stringify(user));
};

export const registerByDomain = async (domainUrl: string) => {
	const { data } = await http.post<IScrappingRegisterResponse>(
		`${authUrl}/register-by-domain`,
		{ domainUrl },
	);
	setTokens(data.tokens);
	setCurrentUser(data.user);
	return data;
};

export const getData = async (url: string) => {
	const { data } = await http.get<IScrappingDataResponse>(
		`${scrapeUrl}/domain?url=${url}`,
	);
	return data;
};

export const completeRegistration = async (payload: any, id: string) => {
	const { data } = await http.post<any>(
		`${usersUrl}/${id}/register-demo`,
		payload,
	);
	setTokens(data.tokens);
	setCurrentUser(data.user);
	return data;
};
