import React, { useContext, useEffect, useState } from 'react';
import { Button, Text, Image, Box, Flex, VStack } from '@chakra-ui/react';
import { RiLockUnlockLine, RiLockLine } from 'react-icons/ri';
import { IDesignDirection } from 'src/lib/schemas/campaign/newFlowCampaign';
import { CampaignContext } from 'src/contexts';
import {
	editCampaignDesignDirections,
	getCampaign,
} from 'src/services/campaign';
import CustomModal from 'src/components/common/CustomModal';
import CardActions from '../creatives/CardActions';
import { cloneDeepWith } from 'lodash';

interface DesignLockModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onCancel: () => void;
	designDirections?: IDesignDirection[];
	setDesignDirections: React.Dispatch<React.SetStateAction<IDesignDirection[]>>;
}

const DesignLockModal: React.FC<DesignLockModalProps> = ({
	designDirections,
	setDesignDirections,
	isOpen,
	onSubmit,
	onClose,
	onCancel,
}) => {
	const {
		id: campaignId,
		campaign,
		setCampaign,
		setOldCampaignCopy,
	} = useContext(CampaignContext);
	const [lockedOptions, setLockedOptions] = useState<string[]>([]);
	const [isHovering, setIsHovering] = useState<string | null>(null);
	const [amountToRegenerate, setAmountToRegenerate] = useState(3);
	const [initialDesignDirections, setInitialDesignDirections] = useState<
		IDesignDirection[]
	>([]);

	useEffect(() => {
		if (designDirections && isOpen) {
			setInitialDesignDirections(cloneDeepWith(designDirections));
		}
	}, [designDirections, isOpen]);

	useEffect(() => {
		if (designDirections) {
			const lockedIds = designDirections
				.filter((dd) => dd.locked)
				.map((dd) => dd.id);

			setLockedOptions(lockedIds);
		}
	}, [designDirections]);

	useEffect(() => {
		if (!designDirections?.length) return;

		const updatedDesignDirections = designDirections.map((dd) => ({
			...dd,
			locked: lockedOptions.includes(dd.id),
		}));

		const hasChanged = designDirections.some(
			(dd, index) => dd.locked !== updatedDesignDirections[index].locked,
		);

		if (hasChanged) {
			setDesignDirections(updatedDesignDirections);
			campaign &&
				setCampaign({
					...campaign,
					designDirections: updatedDesignDirections,
				});
		}
	}, [lockedOptions]);

	const totalDesigns = designDirections?.length ?? 0;
	const unlockedDesigns = totalDesigns - lockedOptions.length;

	useEffect(() => {
		const designsToRegenerate = unlockedDesigns > 3 ? 3 : unlockedDesigns;
		setAmountToRegenerate(designsToRegenerate);
	}, [unlockedDesigns]);

	const isLocked = (id: string) => lockedOptions.includes(id);

	const toggleLock = async (id: string) => {
		if (!campaignId) return;
		setLockedOptions((prev) => {
			if (prev.includes(id)) {
				return prev.filter((lockedId) => lockedId !== id);
			} else {
				return [...prev, id];
			}
		});
		try {
			await editCampaignDesignDirections(campaignId, id, {
				locked: !isLocked(id),
			});
		} catch (error) {}
	};

	const handleSubmit = async () => {
		if (!campaign || !campaignId) return;
		onClose();
		setOldCampaignCopy(cloneDeepWith(campaign));
		const data = await getCampaign(campaignId);
		setCampaign(data);
		onSubmit();
	};

	const handleCancel = () => {
		setDesignDirections(initialDesignDirections);
		onClose();
		onCancel();
	};

	const modalHeader =
		'Do you want your changes to be reflected in the designs?';

	const modalFooter = (
		<>
			<Button variant="orangeOutline" onClick={handleCancel}>
				No
			</Button>
			<Button variant="orangeSolid" ml={3} onClick={handleSubmit}>
				Yes
			</Button>
		</>
	);

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			size="2xl"
			header={modalHeader}
			footer={modalFooter}
		>
			<Text mb={4} color="black">
				Click lock{' '}
				<Box as="span" display="inline-flex" alignItems="center">
					<RiLockLine style={{ marginRight: '4px' }} />
				</Box>
				on each design you want to keep without any change.
			</Text>

			{unlockedDesigns > 3 && (
				<Text color="black" mb={4} mt="-14px">
					We will regenerate up to {amountToRegenerate} designs.
				</Text>
			)}
			<Flex align="center" gap={2} wrap="wrap">
				{designDirections?.map((dd: any) => (
					<Box
						key={dd.id}
						textAlign="center"
						w="200px"
						position="relative"
						onMouseEnter={() => setIsHovering(dd.id)}
						onMouseLeave={() => setIsHovering(null)}
						cursor="pointer"
					>
						<Box position="relative">
							<Image
								src={dd.attributes.image.flatFile}
								alt={dd.name}
								mb={2}
								borderRadius="2xl"
								fallbackSrc="/fusion-loader.gif"
							/>

							{(isHovering === dd.id || isLocked(dd.id)) && (
								<Flex
									position="absolute"
									top={0}
									left={0}
									w="full"
									h="full"
									bg="blackAlpha.700"
									align="center"
									justify="center"
									borderRadius="2xl"
									zIndex={1}
									onClick={() => toggleLock(dd.id)}
								>
									<VStack>
										{isLocked(dd.id) ? (
											<>
												<RiLockLine color="white" size={35} />
												<Text color="white">Locked design</Text>
											</>
										) : (
											<>
												<RiLockLine color="white" size={35} />
												<Text color="white">Lock design</Text>
											</>
										)}
									</VStack>
								</Flex>
							)}
						</Box>

						<CardActions
							title={`Option ${dd.variant}`}
							onLock={() => toggleLock(dd.id)}
							isLocked={isLocked(dd.id)}
						/>
					</Box>
				))}
			</Flex>
		</CustomModal>
	);
};

export default DesignLockModal;
