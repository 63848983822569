import { FC, ReactNode, useEffect, useState } from 'react';
import {
	Box,
	Checkbox,
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
	FormLabelProps,
	IconButton,
	Input,
	Text,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { FaRegCalendarAlt } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';
import './reactDatePicker.css';
import { ECampaignScheduleType } from 'src/lib/schemas/campaign/newFlowCampaign';

export interface DatePickerInputProps {
	label?: string;
	id?: string;
	value?: Date;
	placeholder?: string;
	error?: ReactNode;
	onChange?: (date: Date | null) => void;
	minDate?: Date;
	name: string;
	isEndDate?: boolean;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	onChangeScheduleType?: (type: ECampaignScheduleType) => void;
}

const DatePickerInput: FC<DatePickerInputProps> = ({
	value,
	onChange,
	error,
	label,
	name,
	minDate,
	placeholder,
	formControlProps,
	formLabelProps,
	isEndDate,
	onChangeScheduleType,
}) => {
	const [defineEndDate, setDefineEndDate] = useState(false);

	const hasError = !!error;

	useEffect(() => {
		onChangeScheduleType &&
			onChangeScheduleType(
				defineEndDate
					? ECampaignScheduleType['One-off']
					: ECampaignScheduleType.Evergreen,
			);
	}, [defineEndDate]);

	const CustomInput = ({
		value,
		onClick,
	}: {
		value?: string;
		onClick?: () => void;
	}) => (
		<Box position="relative" display="flex" alignItems="center" w="full">
			<IconButton
				aria-label="Calendar"
				icon={<FaRegCalendarAlt />}
				size="sm"
				bg="transparent"
				position="absolute"
				left="8px"
				onClick={onClick}
				_hover={{ bg: 'transparent' }}
			/>
			<Input
				fontSize="14px"
				value={value}
				onClick={onClick}
				placeholder={placeholder}
				pl="40px"
				disabled={isEndDate && !defineEndDate}
				autoComplete="off"
			/>
		</Box>
	);

	return (
		<FormControl isInvalid={hasError} {...formControlProps} overflow="visible">
			<FormLabel fontWeight={400} color={'black'} mb={2}>
				{isEndDate ? (
					<Checkbox
						isChecked={defineEndDate}
						onChange={(e) => setDefineEndDate(e.target.checked)}
					>
						<Text fontWeight={400} color={'black'}>
							Define an end date
						</Text>
					</Checkbox>
				) : (
					label
				)}
			</FormLabel>
			<DatePicker
				selected={value}
				onChange={(date) => {
					onChange && onChange(date);
				}}
				minDate={minDate || new Date()}
				customInput={<CustomInput />}
				dateFormat="dd/MM/yyyy h:mm aa"
				placeholderText={placeholder}
				showTimeSelect
				shouldCloseOnSelect
				disabled={isEndDate && !defineEndDate}
				popperPlacement="bottom-start"
				popperModifiers={[
					{
						name: 'preventOverflow',
						options: {
							rootBoundary: 'viewport',
							altAxis: true,
						},
					},
				]}
			/>
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

export default DatePickerInput;
