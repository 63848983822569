import { createContext } from 'react';
import { ICompanyAccount, IUser } from 'src/lib/schemas';

interface IUserContext {
	user: IUser | null;
	account: ICompanyAccount | null;
	isLoading: boolean;
	setUser: (user: IUser | null) => void;
	setAccount: (account: ICompanyAccount | null) => void;
}

const UserContext = createContext<IUserContext>({
	user: null,
	account: null,
	isLoading: false,
	setUser: () => {},
	setAccount: () => {},
});

export default UserContext;
