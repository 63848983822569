import { SIDEBAR_NAV_LIST } from '../constants';

export const getSideBarNavItems = (role: string) => {
	const fileredList = SIDEBAR_NAV_LIST.filter((item) => {
		if (item.access.includes(role)) {
			return item;
		}
	});

	return fileredList.map((item) => {
		if (item.list) {
			return {
				...item,
				list: item.list.filter((listItem) => listItem.access.includes(role)),
			};
		}
		return item;
	});
};
