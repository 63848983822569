import { FC } from 'react';
import { Button, Center, Container, Divider, Flex } from '@chakra-ui/react';
import { IDesignDirection, ImageLayer, TextLayer } from 'src/lib/schemas';
import DDFields from './DDFields';
import DDPreview from './DDPreview';
import { SparklesIcon } from 'src/assets/icons';
import { IPerview } from './utils';

interface EditDesignDirectionProps {
	designDirection?: IDesignDirection | null;
	editMode: boolean;
	isGeneratingDD: boolean;
	onLockUnlock: (
		designDirectionId: string,
		locked: boolean,
		isDD?: boolean,
	) => void;
	onRefetchDesignDirections: () => void;
	previewPSDMeta: IPerview;
	isPreviewLoading: boolean;
	isGeneratingCreatives: boolean;
	textLayerContent: any;
	onLayersTextChange: (text: any, layers: any) => void;
	isChangingLayer: boolean;
	handleChangingLayer: (status: boolean) => void;
	allDDGenerated: boolean;
	onChangeMediaImage: (
		flatFile: string,
		layeredFile: string,
		layers: (ImageLayer | TextLayer)[],
	) => void;
	onGenerateNewDD: () => void;
	onGenerateCreatives: () => void;
}

const EditDesignDirection: FC<EditDesignDirectionProps> = ({
	designDirection,
	editMode,
	isGeneratingDD,
	onRefetchDesignDirections,
	onLockUnlock,
	previewPSDMeta,
	isPreviewLoading,
	textLayerContent,
	onLayersTextChange,
	isGeneratingCreatives,
	isChangingLayer,
	handleChangingLayer,
	allDDGenerated,
	onChangeMediaImage,
	onGenerateNewDD,
	onGenerateCreatives,
}) => {
	return (
		<Container maxW="7xl" py={5} p={0} m={0} mt={6}>
			{designDirection && editMode && (
				<Flex gap={6}>
					<DDFields
						designDirection={designDirection}
						onLayersTextChange={onLayersTextChange}
						previewPSD={previewPSDMeta}
						onChangePreviewPSD={onChangeMediaImage}
						isChangingLayer={isChangingLayer}
						onChangingLayer={(status: boolean) => handleChangingLayer(status)}
					/>

					<Center>
						<Divider orientation="vertical" position="relative" />
					</Center>
					<Flex position="sticky" top={20} right={0} h="fit-content">
						<DDPreview
							designDirection={designDirection}
							onLockUnlock={onLockUnlock}
							textLayerContent={textLayerContent}
							onRefetchDesignDirections={onRefetchDesignDirections}
							previewPSD={previewPSDMeta.layeredFile}
							isChangingLayer={isChangingLayer}
						/>
					</Flex>
				</Flex>
			)}
			<Flex justify="center" mt={10} gap={4}>
				<Button
					colorScheme="secondary"
					height={8}
					isLoading={isGeneratingDD}
					onClick={onGenerateNewDD}
					isDisabled={isChangingLayer || isPreviewLoading}
					rightIcon={<SparklesIcon color="white" />}
					size="sm"
				>
					Add new design direction
				</Button>
				<Button
					colorScheme="secondary"
					height={8}
					rightIcon={<SparklesIcon color="white" />}
					onClick={onGenerateCreatives}
					isLoading={isGeneratingCreatives}
					isDisabled={
						isChangingLayer ||
						isPreviewLoading ||
						isGeneratingDD ||
						!allDDGenerated
					}
					size="sm"
				>
					Generate channels content
				</Button>
			</Flex>
		</Container>
	);
};

export default EditDesignDirection;
