import React, {
	useState,
	useEffect,
	FC,
	ReactNode,
	useContext,
	useCallback,
} from 'react';
import TemplateContext, {
	IPayloadsByCase,
	ITemplate,
	IUpdatePayload,
} from './TemplatesContext';
import { getTemplatesByIndustry } from 'src/services/templates';
import UserContext from 'src/contexts/UserContext';
import { toastError } from 'src/services/toast';

interface TemplateProviderProps {
	children: ReactNode;
}

export const TemplateProvider: FC<TemplateProviderProps> = ({ children }) => {
	const { user } = useContext(UserContext);
	const [accountTemplates, setAccountTemplates] = useState<ITemplate[]>([]);
	const [platformTemplates, setPlatformTemplates] = useState<ITemplate[]>([]);
	const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(
		null,
	);
	const [payloads, setPayloads] = useState<IUpdatePayload[]>([]);
	const [payloadsByCase, setPayloadsByCaseState] = useState<IPayloadsByCase[]>(
		[],
	);

	const handleSetPayloadsByCase = (
		caseId: number,
		newPayload: IUpdatePayload,
		isRegenerate: boolean,
	) => {
		setPayloadsByCaseState((prevPayloadsByCase) => {
			const existingCase = prevPayloadsByCase.find(
				(payloadCase) => payloadCase.idCase === caseId,
			);

			if (existingCase) {
				return prevPayloadsByCase.map((payloadCase) => {
					if (payloadCase.idCase === caseId) {
						if (isRegenerate) {
							return {
								...payloadCase,
								payloadList: payloadCase.payloadList.map((payload) =>
									payload.variationId === newPayload.variationId
										? newPayload
										: payload,
								),
							};
						}
						return {
							...payloadCase,
							payloadList: [...payloadCase.payloadList, newPayload],
						};
					}
					return payloadCase;
				});
			} else {
				return [
					...prevPayloadsByCase,
					{ idCase: caseId, payloadList: [newPayload] },
				];
			}
		});
	};

	const fetchTemplatesByScope = useCallback(async (industry: string) => {
		try {
			const allTemplates = await getTemplatesByIndustry(industry);
			const filteredAccountTemplates = allTemplates.filter(
				(template) => template.scope === 'account',
			);
			const filteredPlatformTemplates = allTemplates.filter(
				(template) => template.scope === 'platform',
			);

			setAccountTemplates(filteredAccountTemplates);
			setPlatformTemplates(filteredPlatformTemplates);
		} catch (error: any) {
			toastError(
				error.message || `Failed to fetch templates for industry ${industry}`,
			);
		}
	}, []);

	return (
		<TemplateContext.Provider
			value={{
				templates: accountTemplates,
				templatesByScope: platformTemplates,
				selectedTemplate,
				setSelectedTemplate,
				fetchTemplatesByScope,
				payloads,
				setPayloads,
				payloadsByCase,
				handleSetPayloadsByCase,
				setPayloadsByCaseState,
			}}
		>
			{children}
		</TemplateContext.Provider>
	);
};

export default TemplateProvider;
