import { FC, useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import {
	Box,
	Collapse,
	Flex,
	HStack,
	IconProps,
	List,
	ListIcon,
	ListItem,
	Text,
} from '@chakra-ui/react';
import 'src/styles/hover.css';

import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

interface NestedNavListProps {
	title: string;
	icon: FC<IconProps>;
	link?: string;
	list: Array<{
		title: string;
		link?: string;
	}>;
}

const NestedNavList: FC<NestedNavListProps> = ({
	title,
	icon: Icon,
	list,
	link,
}) => {
	const [showNestedList, setShowNestedList] = useState(false);
	const { pathname } = useLocation();
	const [search] = useSearchParams();

	const isListActive = list.some((item) => pathname.includes(item.link ?? ''));

	useEffect(() => {
		if (isListActive) setShowNestedList(true);
	}, []);

	const activeListProps = isListActive
		? {
				color: 'orange.base',
				bg: '#EDEEEF',
				borderLeftRadius: 0,
		  }
		: {};

	const activeLinkProps = {
		color: 'orange.base',
		bg: 'orange.lighter',
	};

	const category = search.get('category');

	const isCategoryActive = (link: string) => {
		const isCatActive =
			(pathname.includes(link) && !(pathname.includes('tasks') && category)) ||
			link.split('=').pop() === category;

		if (!showNestedList && isCatActive) setShowNestedList(true);

		return isCatActive;
	};

	return (
		<Flex>
			<Box
				borderRight={
					isListActive ? '3px solid #F7480B' : '3px solid transparent'
				}
				borderRadius="0px 4px 4px 0px"
				height="48px"
			/>
			<Box w="full" id={title}>
				<ListItem cursor="pointer">
					<Link key={link} to={link ?? ''}>
						<HStack
							className="container"
							_hover={{
								color: 'orange.base',
							}}
							p={3}
							borderRadius="base"
							pl="20px"
							onClick={() => {
								setShowNestedList((prev) => !prev);
							}}
							{...activeListProps}
						>
							<ListIcon
								as={() => <Icon color="#414141" {...activeListProps} />}
							/>
							<Text
								className="text"
								flex={1}
								fontWeight="500"
								lineHeight="175%"
								letterSpacing="0.2px"
								{...activeListProps}
							>
								{title}
							</Text>

							<ListIcon
								color="#414141"
								as={showNestedList ? IoChevronUpOutline : IoChevronDownOutline}
							/>
						</HStack>
					</Link>
					<Collapse in={showNestedList}>
						<List>
							{list.map((item) => (
								<Link key={item.title} to={item.link ?? ''}>
									<ListItem
										className="container"
										p={2}
										pl="50px"
										cursor="pointer"
										borderRadius="6px"
										{...(isCategoryActive(item.link ?? '')
											? activeLinkProps
											: {})}
									>
										<Text
											className="text"
											fontWeight={500}
											color={
												isCategoryActive(item.link ?? '') ? 'oarnge.base' : ''
											}
										>
											{item.title}
										</Text>
									</ListItem>
								</Link>
							))}
						</List>
					</Collapse>
				</ListItem>
			</Box>
		</Flex>
	);
};

export default NestedNavList;
