import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Divider, Flex, Text, Image, Box, Button } from '@chakra-ui/react';
import { IAppActionInput } from 'src/lib/schemas';
import UserContext from 'src/contexts/UserContext';
import { FileInput, StringInputHook } from 'src/components/common/form';
import { useProductFormModalContext } from 'src/contexts/ProductFormModalContext';
import { FileRejection } from 'react-dropzone';
import { uploadFile } from 'src/services/fileUpload';
import FusionLoading from 'src/components/common/FusionLoading';
import { FaTrash } from 'react-icons/fa';

interface ImageInputWidgetProps {
	input: IAppActionInput;
	validationRules: Record<string, any>;
	handleImageUploading?: (isUploading: boolean) => void;
	propertyPrefix?: string;
	defaultImageValue?: string;
}

const IMAGE_NOT_VALID_MESSAGE = 'Image URL is not valid';
const IMAGE_PLACEHOLDER = 'https://img.clevup.in/static/placeholder.jpg';

const ImageInputWidget: FC<ImageInputWidgetProps> = ({
	input,
	validationRules,
	handleImageUploading,
	propertyPrefix = '',
	defaultImageValue = IMAGE_PLACEHOLDER,
}) => {
	const [isImageLoading, setIsImageLoading] = useState(false);

	const { control, setValue, formState, clearErrors, setError, setFocus } =
		useFormContext();
	const { user } = useContext(UserContext);
	const context = useProductFormModalContext();
	const isProductFormModal = context ? context.isProductFormModal : false;
	const property = propertyPrefix + input.property;
	const image = useWatch({ control, name: property });
	const [displayImage, setDisplayImage] = useState(defaultImageValue);

	const logoHasError = formState.errors[property];
	const memoizedLogoError = useMemo(
		() => logoHasError?.message,
		[logoHasError],
	);

	useEffect(() => {
		if (memoizedLogoError !== undefined) {
			setError(property, { message: memoizedLogoError.toString() });
		}
	}, [memoizedLogoError, setError]);

	useEffect(() => {
		if (defaultImageValue !== IMAGE_PLACEHOLDER) {
			setValue(property, defaultImageValue);
		}
		setDisplayImage(defaultImageValue);
	}, [defaultImageValue, property, setValue]);

	const onImageError = (error?: string) => {
		const message = error ?? IMAGE_NOT_VALID_MESSAGE;
		setError(property, { message });
		setDisplayImage(IMAGE_PLACEHOLDER);
		setFocus(property);
	};

	const handleImageChange = async (
		input: File | string,
		fileRejections?: FileRejection[],
	) => {
		if (fileRejections && fileRejections.length > 0) {
			console.error('File rejected', fileRejections);
			return;
		}
		clearErrors(property);
		setIsImageLoading(true);
		try {
			const isRequired = validationRules.required ? true : false;
			const { imageUrl } = await uploadFile(
				input,
				isRequired,
				validationRules.minWidth,
				validationRules.minHeight,
				validationRules.minShortestSide,
				validationRules.minLongestSide,
			);
			setIsImageLoading(false);
			setValue(property, imageUrl);
			setDisplayImage(imageUrl);
		} catch (error: any) {
			console.error('Error uploading file', error);
			onImageError(error.response?.data?.message);
			setIsImageLoading(false);
		}
	};

	const handleRemoveImage = () => {
		setValue(property, '');
		setDisplayImage(IMAGE_PLACEHOLDER);
		clearErrors(property);
	};

	let placeholder = `Enter ${input.name.toLowerCase()}`;
	if (input.placeholder) {
		placeholder = input.placeholder;
	}

	return (
		<Controller
			name={property}
			control={control}
			rules={validationRules}
			render={() => (
				<Flex direction="column">
					{isProductFormModal ? (
						<Flex direction="row" alignItems="flex-start" gap={2}>
							<Flex direction="column" flex={1} gap={2} mt="10px">
								<StringInputHook
									name={property}
									label={input.name}
									placeholder={placeholder}
									inputProps={{
										onChange: (event) =>
											handleImageChange(event.target.value, []),
									}}
									required={validationRules?.required}
									errorMessage={formState.errors[property]?.message as string}
									requirementsLabel={input.explanation}
								/>
								<Flex align="center" my={2}>
									<Divider flex={1} />
									<Text mx={2}>OR</Text>
									<Divider flex={1} />
								</Flex>
								<Flex justifyContent="center">
									<FileInput
										name="image"
										acceptImages
										uploadButtonText={image ? 'Change image' : 'Upload image'}
										onUrlChange={handleImageChange}
										uploadPath={`${user?.account}/app-store`}
										onUploadingStatusChange={handleImageUploading}
										onDrop={(acceptedFiles, fileRejections) =>
											handleImageChange(acceptedFiles[0], fileRejections)
										}
										onLoading={setIsImageLoading}
									/>
								</Flex>
							</Flex>

							<Box
								width="160px"
								height="200px"
								flexShrink={0}
								mt="10px"
								border="1px solid #E2E8F0"
								borderRadius="8px"
								p={2}
								display="flex"
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
								overflow="hidden"
								position="relative"
							>
								<FusionLoading
									isLoading={isImageLoading}
									imageProps={{ w: '160px', h: 'auto' }}
								/>
								{!isImageLoading && (
									<>
										<Image
											src={displayImage}
											alt="Product image"
											objectFit="contain"
											maxW="100%"
											maxH="80%"
											mb="20px"
											onError={() => setDisplayImage(IMAGE_PLACEHOLDER)}
										/>
										{displayImage !== IMAGE_PLACEHOLDER && (
											<Button
												leftIcon={<FaTrash />}
												mt={2}
												onClick={handleRemoveImage}
												variant="none"
												fontWeight={400}
												position="absolute"
												bottom="0px"
											>
												Remove image
											</Button>
										)}
									</>
								)}
							</Box>
						</Flex>
					) : (
						<>
							<StringInputHook
								name={propertyPrefix + input.property}
								label={input.name}
								inputProps={{
									onChange: (event) => handleImageChange(event.target.value),
								}}
								placeholder={placeholder}
								required={validationRules?.required}
								requirementsLabel={input.explanation}
							/>
							<Flex align="center">
								<Divider borderColor="gray.300" />
								<Text padding="2">OR</Text>
								<Divider borderColor="gray.300" />
							</Flex>
							<Flex direction="column" gap={5}>
								<FileInput
									name="image"
									acceptImages
									uploadButtonText={image ? 'Change image' : 'Upload image'}
									onUrlChange={handleImageChange}
									uploadPath={`${user?.account}/app-store`}
									onUploadingStatusChange={handleImageUploading}
								/>
								{image && (
									<Box
										width="160px"
										height="200px"
										border="1px solid #E2E8F0"
										borderRadius="8px"
										display="flex"
										flexDirection="column"
										alignItems="center"
										justifyContent="center"
										p={2}
										overflow="hidden"
									>
										<Image
											src={image}
											alt="Product image"
											maxH="160px"
											maxW="100%"
											objectFit="contain"
										/>
									</Box>
								)}
							</Flex>
						</>
					)}
				</Flex>
			)}
		/>
	);
};

export default ImageInputWidget;
