import React, { useState } from 'react';
import { Box, Text, VStack, Image, Skeleton } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ICardData } from '../utils/marketinData';
import 'src/styles/hover.css';

interface ShortcutCardProps {
	card: ICardData;
}

const ShortcutCard: React.FC<ShortcutCardProps> = ({ card }) => {
	const { navigate, icon, id, title, size } = card;
	const navigateTo = useNavigate();
	const location = useLocation();

	const [isImageLoaded, setIsImageLoaded] = useState(false);

	const handleNavigation = () => {
		const state = location.state || {};
		navigateTo(navigate, { state: { ...state, selectedChannel: id } });
	};

	return (
		<Box
			className="container"
			borderWidth="1px"
			borderRadius="lg"
			w="full"
			maxW="200px"
			mx="auto"
			textAlign="center"
			onClick={handleNavigation}
			cursor="pointer"
			transition="border-color 0.3s ease-in-out"
			_hover={{
				borderColor: 'orange.base',
			}}
		>
			<Box
				className="content"
				bg="gray.50"
				height="50%"
				width="100%"
				borderTopRadius="lg"
				transition="background-color 0.3s ease-in-out"
			>
				<VStack spacing={4} py={4} height="100%" justifyContent="center">
					<Box
						position="relative"
						w={size === 'lg' ? '4em' : '3em'}
						h={size === 'lg' ? '4em' : '3em'}
					>
						{!isImageLoaded && (
							<Skeleton height="100%" width="100%" borderRadius="full" />
						)}
						<Image
							src={icon}
							alt={id}
							w="100%"
							h="auto"
							onLoad={() => setIsImageLoaded(true)}
							onError={() => setIsImageLoaded(true)}
							position="absolute"
							top="0"
							left="0"
						/>
					</Box>
				</VStack>
			</Box>
			<Box p={2}>
				<Text color="#4B5563" fontWeight={500} fontFamily="Noto Sans">
					{title}
				</Text>
			</Box>
		</Box>
	);
};

export default ShortcutCard;
