import { Switch, FormLabel, Tooltip, Text, Box } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

interface VariationSwitchProps {
	name: 'designDirection' | 'fbStory' | 'fbFeed' | 'igFeed';
	label: string;
	isDisabled: boolean;
	activeVariations: any[];
	onSwitchChange: (name: string) => void;
}

const VariationSwitch = ({
	name,
	label,
	isDisabled,
	activeVariations,
	onSwitchChange,
}: VariationSwitchProps) => {
	const { watch } = useFormContext();

	return (
		<Box>
			<Tooltip
				label={isDisabled ? 'Should generate variations' : ''}
				isDisabled={!isDisabled}
				placement="top"
				hasArrow
				bg="red.600"
				color="white"
			>
				<div>
					<Switch
						id={`enabled-switch-${name}`}
						isChecked={Boolean(watch(name))}
						onChange={() => onSwitchChange(name)}
						isDisabled={isDisabled}
						sx={{
							'& .chakra-switch__track': {
								backgroundColor: watch(name) ? '#F7480B' : 'gray.200',
							},
							'& .chakra-switch__thumb': {
								backgroundColor: 'white',
							},
						}}
					/>
					<FormLabel
						htmlFor={`enabled-switch-${name}`}
						mb="0"
						marginLeft="10px"
					>
						<Text>{label}</Text>
					</FormLabel>
				</div>
			</Tooltip>
		</Box>
	);
};

export default VariationSwitch;
