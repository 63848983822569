import React, { forwardRef, useState } from 'react';
import {
	Box,
	FormLabel,
	FormControl,
	Input,
	Text,
	Image,
	Flex,
} from '@chakra-ui/react';
import Actions from './Actions';
import {
	Controller,
	ControllerRenderProps,
	FieldValues,
} from 'react-hook-form';

interface CustomInputProps {
	label: string;
	name: string;
	control: any;
	isLayerVisible?: boolean;
	image?: string;
	isLoading?: boolean;
	onChange?: (val: string) => void;
	onFontClick?: () => void;
	onCopilotClick?: () => void;
	onShowClick?: () => void;
	onUnlockClick?: () => void;
	onUploadClick?: (file: File) => void;
	onDownloadClick?: () => void;
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
	(
		{
			label,
			name,
			control,
			isLayerVisible,
			image,
			isLoading,
			onChange,
			onFontClick,
			onCopilotClick,
			onShowClick,
			onUnlockClick,
			onUploadClick,
			onDownloadClick,
		},
		ref,
	) => {
		const [isFocused, setIsFocused] = useState(false);
		const isMediaInput = Boolean(image);

		const renderInput = (field: ControllerRenderProps<FieldValues, string>) =>
			isMediaInput ? (
				<Flex
					flex={1}
					justify="center"
					alignItems="center"
					minW="150px"
					maxW="180px"
					position="relative"
					p={4}
				>
					<Image
						src={image}
						maxW="100%"
						maxH="100%"
						objectFit="contain"
						h="auto"
						borderRadius="md"
					/>
				</Flex>
			) : (
				<Input
					{...field}
					ref={ref}
					bg="white"
					variant="unstyled"
					height="50px"
					borderRadius="md"
					px={3}
					fontSize="14px"
					placeholder="Enter text"
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					onChange={({ target }) => {
						onChange && onChange(target.value);
					}}
				/>
			);

		return (
			<Box
				position="relative"
				border="1px solid"
				borderColor={isFocused ? 'orange.base' : 'border.primary'}
				borderRadius="md"
				w="full"
				bg="white"
				tabIndex={0}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				ref={ref as React.RefObject<HTMLDivElement>}
			>
				<FormControl>
					<FormLabel
						position="absolute"
						top="-12px"
						left="10px"
						bg="white"
						px={1}
						fontWeight="bold"
						zIndex={1}
					>
						<Text color={isFocused ? 'orange.base' : 'text.secondary'}>
							{label}
						</Text>
					</FormLabel>
					<Controller
						control={control}
						name={name}
						render={({ field }) => renderInput(field)}
					/>

					<Actions
						onFontClick={onFontClick}
						onCopilotClick={onCopilotClick}
						onShowClick={onShowClick}
						onUnlockClick={onUnlockClick}
						isLayerVisible={isLayerVisible}
						onUploadClick={onUploadClick}
						onDownloadClick={onDownloadClick}
						isLoading={isLoading}
						layerName={name}
					/>
				</FormControl>
			</Box>
		);
	},
);

CustomInput.displayName = 'CustomInput';
export default CustomInput;
