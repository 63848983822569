import React from 'react';
import ReactDOM from 'react-dom';
import { Box, Text, CloseButton, VStack, Icon } from '@chakra-ui/react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

interface Props {
	onClose: () => void;
}

const DeviceNotSupported: React.FC<Props> = ({ onClose }) => {
	React.useEffect(() => {
		const isIOS =
			/iPad|iPhone|iPod/.test(navigator.userAgent) &&
			!navigator.userAgent.includes('Windows');
		if (isIOS) {
			const meta = document.createElement('meta');
			meta.name = 'viewport';
			meta.content =
				'width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no';
			document.getElementsByTagName('head')[0].appendChild(meta);

			const handleResize = () => {
				const scale = window.innerWidth / document.documentElement.clientWidth;
				document.body.style.transform = `scale(${scale})`;
				document.body.style.transformOrigin = 'top left';
			};

			window.addEventListener('resize', handleResize);
			handleResize();

			return () => {
				document.getElementsByTagName('head')[0].removeChild(meta);
				window.removeEventListener('resize', handleResize);
			};
		}
	}, []);

	const content = (
		<Box
			position="fixed"
			top="0"
			left="0"
			right="0"
			bottom="0"
			backgroundColor="rgba(0, 0, 0, 0.7)"
			zIndex="9999999"
			display="flex"
			alignItems="center"
			justifyContent="center"
			overflow="hidden"
			maxH="100vh"
			maxW="100vw"
		>
			<VStack
				spacing={4}
				textAlign="center"
				p={4}
				borderWidth={1}
				borderRadius="20px"
				backgroundColor="white"
				width="90vw"
				maxWidth="90vw"
				maxHeight="90vh"
				height="85vh"
				alignItems="center"
				justifyContent="center"
				fontSize={['14px', '16px', '18px']}
				position="relative"
				top="45%"
				left="45%"
				transform="translate(-50%, -50%)"
				overflowY="auto"
				sx={{
					WebkitTextSizeAdjust: '100%',
					touchAction: 'manipulation',
					'& *': {
						maxHeight: '100%',
					},
				}}
			>
				<CloseButton
					position="absolute"
					top="2"
					right="2"
					onClick={onClose}
					size="lg"
				/>
				<Icon as={AiOutlineInfoCircle} boxSize={['3rem', '4rem', '5rem']} />
				<Text
					fontSize={['20px', '24px', '30px']}
					fontWeight="semibold"
					lineHeight="1.2"
				>
					 FusionAds.ai shines on Desktop!
				</Text>
				<Text
					fontSize={['14px', '16px', '18px']}
					lineHeight="1.5"
					maxWidth="90%"
				>
					For the full, immersive experience, please visit us directly at
					https://www.fusionos.ai in a desktop browser.
				</Text>
			</VStack>
		</Box>
	);

	return ReactDOM.createPortal(content, document.body);
};

export default DeviceNotSupported;
