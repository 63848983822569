import React, { createContext, useContext, useState, ReactNode } from 'react';

interface PopupContextType {
	isPopupVisible: boolean;
	setPopupVisible: (visible: boolean) => void;
}

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export const PopupProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [isPopupVisible, setPopupVisible] = useState(true);

	return (
		<PopupContext.Provider value={{ isPopupVisible, setPopupVisible }}>
			{children}
		</PopupContext.Provider>
	);
};

export const usePopup = (): PopupContextType => {
	const context = useContext(PopupContext);
	if (!context) {
		throw new Error('usePopup must be used within a PopupProvider');
	}
	return context;
};
