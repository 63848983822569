import { IconButton, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import { ShareIcon } from 'src/assets/icons';

const AppShare = () => {
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const [isCopied, setIsCopied] = useState(false);

	const handleClipboard = (value: string) => {
		setIsCopied(true);
		setIsTooltipOpen(true);
		navigator.clipboard.writeText(value);
	};

	const currentUrl = window.location.href;

	return (
		<Tooltip
			placement="right"
			isOpen={isTooltipOpen}
			color={isCopied ? 'green.800' : '#202223'}
			label={
				isCopied
					? 'Copied!'
					: `Click to copy the share link for others in your organization: ${currentUrl}`
			}
			bg={isCopied ? 'green.100' : 'gray.100'}
			closeDelay={500}
			onClose={() => {
				setIsTooltipOpen(false);
				setIsCopied(false);
			}}
			hasArrow
		>
			<IconButton
				aria-label="Share"
				bg="transparent"
				_hover={{ bg: 'transparent' }}
				onMouseEnter={() => setIsTooltipOpen(true)}
				onMouseLeave={() => setIsTooltipOpen(false)}
				onClick={() => handleClipboard(currentUrl)}
			>
				<ShareIcon />
			</IconButton>
		</Tooltip>
	);
};

export default AppShare;
