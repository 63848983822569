import { Image, Text, Flex, Box } from '@chakra-ui/react';
import SignUpForm from './SignupForm';
import { FC } from 'react';
import { IoMdRefreshCircle } from 'react-icons/io';
import Header from './Header';
import LoadingList from 'src/components/common/Loading/LoadingList';

interface IAdGeneration {
	templateUrl?: string;
	imageHasError: boolean;
	isPendingGeneration: boolean;
	onLoad: () => void;
	handleRetry: () => void;
}

const AdGeneration: FC<IAdGeneration> = ({
	templateUrl,
	imageHasError,
	isPendingGeneration,
	onLoad,
	handleRetry,
}) => {
	const imageSizes = { base: '200px', md: '300px', lg: '400px' };

	return (
		<Flex direction="column">
			<Header
				step={2}
				title="Congratulations, you just created your first FREE ad!"
			/>
			<Flex
				direction={{ base: 'column', lg: 'row' }}
				gap={8}
				justifyContent="center"
				alignItems="center"
			>
				<Flex flex={1} alignItems="center" order={{ base: 1, lg: 2 }}>
					{imageHasError && (
						<Flex
							justify="center"
							alignItems="center"
							minW={imageSizes}
							minH={imageSizes}
							borderRadius="21px"
							bg="gray.100"
						>
							<Flex
								p={5}
								alignItems="center"
								direction="column"
								textAlign="center"
								gap={2}
								w="80%"
								color="gray.text"
							>
								<Text>There was an issue generating the image.</Text>
								<Text>Click here to try again.</Text>
								<IoMdRefreshCircle
									cursor="pointer"
									size={50}
									onClick={handleRetry}
								/>
							</Flex>
						</Flex>
					)}
					{!imageHasError && (
						<Flex w="full" h="full">
							<Box
								display={isPendingGeneration ? 'block' : 'none'}
								position="relative"
								zIndex="-1"
								maxW={{ base: '200px', md: '400px' }}
								maxH={{ base: '200px', md: '400px' }}
								borderRadius="15px"
								backgroundImage="url('/images/background_preview_DD.webp')"
								backgroundSize="cover"
								backgroundPosition="center"
							/>
							{isPendingGeneration && (
								<LoadingList
									isPendingGeneration={isPendingGeneration}
									hasError={imageHasError}
									size={imageSizes}
								/>
							)}
						</Flex>
					)}
					<Image
						display={isPendingGeneration || imageHasError ? 'none' : 'block'}
						src={templateUrl}
						fallbackSrc="/fusion-loader.gif"
						maxW={imageSizes}
						maxH={imageSizes}
						borderRadius="15px"
						onLoad={onLoad}
					/>
				</Flex>
				<Flex flex={1} alignItems="center" order={{ base: 1, md: 2 }}>
					<SignUpForm />
				</Flex>
			</Flex>
		</Flex>
	);
};

export default AdGeneration;
