import { useContext } from 'react';
import {
	Drawer,
	DrawerBody,
	DrawerContent,
	List,
	useDisclosure,
} from '@chakra-ui/react';
import NavListItem from './NavListItem';
import NestedNavList from './NestedNavList';
import UserContext from 'src/contexts/UserContext';
import { getSideBarNavItems } from 'src/lib/utils/user';

function DrawerExample() {
	const { onClose } = useDisclosure();
	const { user } = useContext(UserContext);

	const navList = getSideBarNavItems(user?.role ?? 'verifiedUser');

	return (
		<Drawer
			isOpen={true}
			placement="left"
			onClose={onClose}
			variant="clickThrough"
			trapFocus={false}
			blockScrollOnMount={false}
		>
			<DrawerContent
				bg="white"
				w="250px"
				maxW="250px"
				marginTop="72px"
				pt="10px"
				boxShadow="0 0px 15px -3px rgba(0, 0, 0, 0.1)"
			>
				<DrawerBody p={1} w="100%">
					<List>
						{navList.map((item) =>
							item.list ? (
								<NestedNavList
									key={item.title}
									title={item.title}
									icon={item.icon}
									list={item.list}
									link={item.link}
								/>
							) : (
								<NavListItem
									key={item.title}
									title={item.title}
									link={item.link}
									icon={item.icon}
								/>
							),
						)}
					</List>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
}

export default DrawerExample;
