import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import {
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
	FormLabelProps,
	Textarea,
	TextareaProps,
	Text,
	Flex,
	InputGroup,
	InputRightElement,
	VStack,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import CopyToClipboard from '../../CopyToClipboard';
import AssistantIcon from '../../AssistantIcon';

export interface TextareaInputProps {
	label?: string;
	id?: string;
	placeholder?: string;
	requirementsLabel?: string;
	error?: ReactNode;
	maxLength?: number;
	valueLength?: number;
	required?: boolean;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	textareaProps?: TextareaProps;
	copyToClipboard?: string;
	withAssistant?: boolean;
	content?: string;
	direction?: 'row' | 'column';
	handleOpenAssistantChat?: (ref?: HTMLDivElement) => void;
	requirementsLabelStyle?: React.CSSProperties;
}

const TextareaInput: FC<TextareaInputProps> = ({
	error,
	label,
	placeholder,
	requirementsLabel,
	maxLength,
	valueLength,
	required,
	formControlProps,
	formLabelProps,
	textareaProps,
	copyToClipboard,
	withAssistant,
	content,
	requirementsLabelStyle,
	direction = 'column',
	handleOpenAssistantChat,
}) => {
	const inputRef = useRef<HTMLDivElement | null>(null);
	const hasError = !!error;
	const [isFocused, setIsFocused] = useState(false);

	const onOpenAssistant = () => {
		inputRef.current &&
			handleOpenAssistantChat &&
			handleOpenAssistantChat(inputRef?.current);
	};

	const renderLabelIndicator = () => {
		if (!required) return null;

		if (error) return <InfoIcon color="#e53e3e" />;

		return <Text color="#e53e3e">*</Text>;
	};

	const errorFocusProps = error
		? {
				boxShadow: '0 0 0 1px #E53E3E',
				borderColor: '#E53E3E',
		  }
		: {
				boxShadow: '0 0 0 1px #3182ce',
				borderColor: '#3182ce',
		  };

	useEffect(() => {
		const c = inputRef.current?.lastElementChild as HTMLElement;
		if (c) {
			c.style.height = 'inherit';
			c.style.height = `${c.scrollHeight}px`;
		}
	}, [inputRef, content]);

	return (
		<FormControl isInvalid={hasError} {...formControlProps}>
			<Flex direction={direction} gap={direction === 'row' ? 20 : 0}>
				{label && (
					<Flex flex={1} justify="space-between">
						<FormLabel mb="1" {...formLabelProps}>
							<Flex gap={1} alignItems="center">
								{label}
								{renderLabelIndicator()}
							</Flex>
							{requirementsLabel && (
								<Flex>
									<Text
										fontSize="12px"
										color="#959595"
										style={requirementsLabelStyle}
									>
										{requirementsLabel}
									</Text>
								</Flex>
							)}
						</FormLabel>
						{copyToClipboard && (
							<CopyToClipboard value={copyToClipboard ?? ''} size="xs" />
						)}
					</Flex>
				)}
				<VStack flex={1} alignItems="flex-start">
					<InputGroup ref={inputRef}>
						{withAssistant && handleOpenAssistantChat && (
							<InputRightElement alignItems="end" h="full" right={0}>
								<AssistantIcon onOpen={onOpenAssistant} />
							</InputRightElement>
						)}
						<Textarea
							placeholder={placeholder}
							mt={1}
							pr={withAssistant ? 10 : 0}
							{...textareaProps}
							marginTop="9px"
							maxLength={maxLength}
							_focusVisible={errorFocusProps}
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
							data-testid="text-area"
						/>
					</InputGroup>
					<Flex
						alignItems="center"
						justifyContent={
							error && !maxLength
								? 'flex-start'
								: !error && maxLength
								? 'flex-end'
								: error && maxLength
								? 'space-between'
								: 'flex-end'
						}
					>
						{error && <FormErrorMessage mt={0}>{error}</FormErrorMessage>}

						{!!maxLength && (
							<Text color="#A0AEC0" textAlign="right" pr={2} pt={0.5}>
								{valueLength}/{maxLength}
							</Text>
						)}
					</Flex>
				</VStack>
			</Flex>
		</FormControl>
	);
};

export default TextareaInput;
