import { useContext, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Heading,
	Text,
	Alert,
	AlertIcon,
	Image,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import Logo from 'src/assets/svgs/logo-sm.svg';
import UserContext from 'src/contexts/UserContext';
import authService from 'src/services/auth';

const PendingVerification = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { user } = useContext(UserContext);

	const handleSendVerificationEmail = async () => {
		setIsLoading(true);
		try {
			await authService.sendVerificationEmail();
		} catch (error: any) {}
		setIsLoading(false);
	};

	return (
		<Flex direction="column" align="center" justify="center" px={4} bg="white">
			<Image src={Logo} boxSize="100px" mb={6} alt="Fusion Logo" />
			<Heading as="h1" fontSize={24} color="#F85A23" mb={4} fontWeight={500}>
				Verify your email
			</Heading>
			<Text fontSize="14px" textAlign="center" maxW="xl" mb={6}>
			Thank you for signing up. Please check your inbox and click the link we&apos;ve sent you to verify your email address
			</Text>
			<Alert
				status="info"
				bg="blue.50"
				borderRadius="md"
				p={4}
				maxW="xl"
				mb={6}
			>
				<AlertIcon as={InfoIcon} color="blue.400" />
				<Text fontSize="14px">
					If you can’t find the email in your inbox, please check your{' '}
					<Text as="span" fontWeight="bold">
						spam folder
					</Text>
					.
				</Text>
			</Alert>
		</Flex>
	);
};

export default PendingVerification;
