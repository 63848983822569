import React, { useContext } from 'react';
import {
  Box,
  Image,
  Flex,
} from '@chakra-ui/react';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';
import UserContext from 'src/contexts/UserContext';
import CreativeCard from './CreativeCard';
import { IChannelCreativeAttributes } from 'src/lib/schemas';



interface CustomCreativeProps {
  creative: ICreative;
  withActions?: boolean;
  size?: string;
  creativesConfig?: IChannelCreativeAttributes[];
}

const CustomCreative = ({
  creative,
  withActions = true,
  size = 'md',
  creativesConfig,
}: CustomCreativeProps) => {
  const { user, account } = useContext(UserContext);
  const image = (creative as any).attributes.image?.flatFile;


  return (
    <CreativeCard
      creative={creative}
      withActions={withActions}
      size={size}
      title="A6"
      creativesConfig={creativesConfig}
    >
      <Box minH="680px">
        <Box p={4} position="absolute">
          <Flex alignItems="center" mb={4} gap={2}>
         

          </Flex>
        </Box>
        <Image
          borderRadius="lg"
          src={image}
          fallbackSrc="/fusion-loader.gif"
          alt="A6 Creative"
        />
       
      </Box>
    </CreativeCard>
  );
};

export default CustomCreative;
