import { useContext, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { AssistantChatContext, CampaignContext } from 'src/contexts';
import {
	IAssistantCreativeData,
	IField,
	IGetThreadParams,
	ThreadTypesEnum,
} from 'src/lib/schemas';

interface Props {
	fieldName: string;
	reference: string;
	type: ThreadTypesEnum;
	control: any;
	variant: string;
	channel: string;
	displayName: string;
}

export const useAssistantField = ({
	fieldName,
	control,
	reference,
	type,
	channel,
	variant,
	displayName,
}: Props) => {
	const { openAssistantChat } = useContext(AssistantChatContext);
	const { field } = useController({ name: fieldName, control });

	const openAssistant = async (ref?: HTMLDivElement) => {
		const threadParams: IGetThreadParams = {
			type,
			reference,
			sub_reference: fieldName,
			property: fieldName,
		};
		const fieldParams: IField = {
			displayName,
			name: fieldName,
			channel,
			variant,
			content: field.value,
			id: field.name,
			setValueV2: field.onChange,
		};
		openAssistantChat({ threadParams, fieldParams, ref });
	};

	return openAssistant;
};
