import {
	Box,
	Button,
	Flex,
	Popover,
	PopoverBody,
	PopoverContent,
	Image,
	PopoverTrigger,
	Text,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserLogo from 'src/components/common/UserLogo';
import UserContext from 'src/contexts/UserContext';
import authService from 'src/services/auth';
import { MdOutlineLogout } from 'react-icons/md';
import Logo from 'src/assets/svgs/logo.svg';

const TopBar = () => {
	const [isLoggingOut, setIsLoggingOut] = useState(false);
	const { user, setUser } = useContext(UserContext);
	const navigate = useNavigate();

	const handleLogout = async () => {
		setIsLoggingOut(true);
		await authService.logout();
		setUser(null);
		setIsLoggingOut(false);
		navigate('/login');
	};

	return (
		<Box
			w="full"
			h="72px"
			bg="white"
			position="fixed"
			top={0}
			zIndex={1800}
			boxShadow="0 0px 15px -3px rgba(0, 0, 0, 0.1)"
		>
			<Flex h="full" justifyContent="space-between" alignItems="center">
				<Flex ml="20px">
					<Link to="/home">
						<Image src={Logo} height="30px" />
					</Link>
				</Flex>

				{user && (
					<Flex gap={3} mr="30px">
						<Popover>
							<PopoverTrigger>
								<Button
									bg="transparent"
									_hover={{ bg: 'transparent' }}
									alignItems="center"
									gap={2}
								>
									<Text
										color="#414141"
										fontSize="14px"
										fontWeight={500}
										letterSpacing="0.2px"
										fontFamily="Noto Sans"
									>
										Hello {user.name}{' '}
									</Text>
									<UserLogo size={{ h: '33px', w: '33px' }} />
								</Button>
							</PopoverTrigger>
							<PopoverContent
								_focusVisible={{
									outline: 'none',
								}}
								boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.25)'}
								m={4}
								width="fit-content"
							>
								<PopoverBody bg="#FFF" borderRadius="6px" p="16px" minW="250px">
									<Flex
										direction="column"
										gap={3}
										p={3}
										borderRadius="8px"
										alignItems="center"
										bg="white"
									>
										<UserLogo size={{ h: '33px', w: '33px' }} />
										<Flex direction="column" alignItems="center">
											<Text color="#26272B" fontWeight={600} fontSize="12px">
												{user.name}
											</Text>
											<Text color="#757588" fontWeight={400} fontSize="12px">
												{user.email}
											</Text>
										</Flex>
									</Flex>
									<Flex mt={3}>
										<Button
											leftIcon={<MdOutlineLogout size={20} />}
											bg={'orange.primary'}
											color="white"
											onClick={handleLogout}
											isLoading={isLoggingOut}
											loadingText="Logging out..."
											_hover={{ bg: 'orange.primary' }}
											w="full"
										>
											<Text
												color="white"
												fontWeight={600}
												fontSize="12px"
												ml="-5px"
											>
												Logout
											</Text>
										</Button>
									</Flex>
								</PopoverBody>
							</PopoverContent>
						</Popover>
					</Flex>
				)}
			</Flex>
		</Box>
	);
};

export default TopBar;
