import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
	Box,
	Flex,
	HStack,
	IconProps,
	ListIcon,
	ListItem,
	Text,
} from '@chakra-ui/react';
import 'src/styles/hover.css';

interface NavListItemProps {
	title: string;
	link?: string;
	icon: FC<IconProps>;
}

const NavListItem: FC<NavListItemProps> = ({ title, icon: Icon, link }) => {
	const { pathname } = useLocation();

	const isActiveLink = pathname.includes(link ?? '');
	const activeLinkProps = isActiveLink
		? { color: 'orange.base', bg: '#EDEEEF', borderLeftRadius: 0 }
		: {};

	return (
		<Flex>
			<Box
				borderRight={
					isActiveLink ? '3px solid #F7480B' : '3px solid transparent'
				}
				borderRadius="0px 4px 4px 0px"
			/>
			<Box w="full">
				<Link to={link ?? ''}>
					<ListItem cursor="pointer" borderRadius="6px">
						<HStack
							className="container"
							_hover={{
								color: 'orange.base',
							}}
							p={3}
							pl="20px"
							borderRadius="6px"
							{...activeLinkProps}
						>
							<ListIcon
								as={() => (
									<Icon
										height="50px"
										width="50px"
										color="#414141"
										{...activeLinkProps}
									/>
								)}
							/>
							<Text
								className="text"
								fontWeight="500"
								lineHeight="175%"
								letterSpacing="0.2px"
								{...activeLinkProps}
							>
								{title}
							</Text>
						</HStack>
					</ListItem>
				</Link>
			</Box>
		</Flex>
	);
};

export default NavListItem;
