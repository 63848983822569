import {
	useState,
	useEffect,
	useMemo,
	useRef,
	useContext,
	Dispatch,
} from 'react';
import { useNavigate } from 'react-router-dom';
import * as _ from 'lodash';

import { CampaignContext } from 'src/contexts';
import {
	getCampaignDelta,
	validateInitialPayload,
} from 'src/components/campaign/utils/delta';
import { ICampaign, ICampaignForm } from 'src/lib/schemas';
import { createOrUpdateCampaign } from 'src/services/campaign';
import { toastError } from 'src/services/toast';
import { transformPartialFormToCampaign } from 'src/components/campaigns/utils/transform';
import { IDesignDirection } from 'src/lib/schemas/campaign/newFlowCampaign';

const CAMPAIGN_FIELDS_OMIT = [
	'account',
	'status',
	'brief',
	'creatives',
	'schedule',
	'lastUpdatedBy',
	'updatedAt',
	'createdAt',
];

const useAutoSaveCampaign = (
	setDirtyPlacements: Dispatch<React.SetStateAction<boolean>>,
	setDesignDirections: Dispatch<React.SetStateAction<IDesignDirection[]>>,
) => {
	const {
		campaign,
		id: campaignId,
		setCampaign,
		isLaunching,
	} = useContext(CampaignContext);
	const [changedFields, setChangedFields] = useState<Partial<ICampaign>>({});
	const navigate = useNavigate();

	const cleanedCampaign = useMemo(() => {
		return _.omit(campaign, CAMPAIGN_FIELDS_OMIT);
	}, [campaign]);

	useEffect(() => {
		//@ts-ignore
		setCampaign((prevState) => ({ ...prevState, ...changedFields }));
		handleUpdateCampaign();
	}, [changedFields]);

	const isAllFieldsUndefined = (obj: any) => {
		return Object.values(obj).every((value) => value === undefined);
	};

	const handleUpdateCampaign = async () => {
		try {
			const delta = getCampaignDelta(cleanedCampaign, changedFields);
			const payload = _.omit(delta, ['id', 'status']);
			const {
				tone,
				goal,
				audience,
				promotion,
				placements,
				channels,
				designDirections,
				destination,
			} = payload;
			// clean undefined fields
			if (goal === undefined) delete payload.goal;
			if (tone === undefined) delete payload.tone;
			if (audience === undefined) delete payload.audience;
			if (promotion === undefined) delete payload.promotion;
			// if (channels === undefined) delete payload.channels;
			if (designDirections === undefined) delete payload.designDirections;
			if (destination === undefined) delete payload.destination;
			if (placements === undefined) delete payload.placements;

			const isEmptyPayload =
				_.isEmpty(payload) || isAllFieldsUndefined(payload);
			const isInvalidPayload = validateInitialPayload({
				...changedFields,
				group: undefined,
			});
			if (isEmptyPayload || isInvalidPayload) {
				return;
			}

			const response = await createOrUpdateCampaign(
				payload,
				campaignId ?? 'new',
			);
			response.designDirections &&
				setDesignDirections(response.designDirections);
			if (campaignId === 'new') {
				navigate(`/projects/campaigns/${response.id}?status=draft`, {
					replace: true,
				});
			}
			if (payload.placements) {
				setDirtyPlacements(false);
			}
		} catch (error: any) {
			const isTarget = error.response.data.message.includes('target');
			!isTarget && toastError(error);
		}
	};

	const handleFieldsChange = (formData: Partial<ICampaignForm>) => {
		if (isLaunching) return;
		const transformedFormValues = transformPartialFormToCampaign(
			formData,
			campaign,
		);
		setChangedFields(transformedFormValues);
	};

	return { onFieldsChange: handleFieldsChange };
};

export default useAutoSaveCampaign;
