export const CAMPAIGN_STEPS = [
	{
		step: 1,
		title: 'Configuration',
	},
	{
		step: 2,
		title: 'Brief',
	},
	{
		step: 3,
		title: 'Content',
	},
	{
		step: 4,
		title: 'Schedule and Deploy',
	},
];

export const CHANNELS_NAME_MAPPING: Record<string, string> = {
	facebook: 'Facebook',
	instagram: 'Instagram',
	twitter: 'X/Twitter',
	google: 'Google',
	sms: 'SMS',
	pns: 'Push notification',
	email: 'E-mail',
	a6: 'A6',
	'design-direction': 'Design Direction',
};

export const CHANNELS_ICON_MAPPING: Record<string, string> = {
	facebook: '/images/home/facebook.png',
	instagram: '/images/home/instagram.png',
	twitter: '/images/home/twitter.png',
	google: '/images/home/google.png',
	sms: '/images/home/sms.png',
	pns: '/images/home/pns.png',
	email: '/images/home/email.png',
};
