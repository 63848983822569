import {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Box, Button, Container, Flex } from '@chakra-ui/react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	MultiSelectCreateableInputHook,
	MultiSelectValuesHook,
	PromoCodesInputHook,
	StringInputHook,
	TextareaInputHook,
} from '../common/form';
import { ProductFormModal } from '../commerce/products';
import ConfigFormModal from '../config/ConfigFormModal';
import { AppInputConfigContext, CampaignContext } from 'src/contexts';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import useFetch from 'src/hooks/useFetch';
import useAccountConfigOptions from 'src/hooks/config/useAccountConfigOptions';
import useCatalogKinds from 'src/hooks/useCatalogKinds';
import {
	createAccountConfigOptions,
	getAccountConfigOptions,
} from 'src/services/account';
import {
	ECampaignPromotedType,
	ICatalog,
	ICatalogForm,
	IChannel,
	IChannelGroup,
	IDesignDirection,
} from 'src/lib/schemas';
import { IAccountConfigOption, IOption } from 'src/lib/schemas/misc';
import { transformCampaignToForm } from 'src/lib/utils';
import { toastError } from 'src/services/toast';
import { ChannelType } from './channels/ChannelType';
import MarketingChannelsForm from './channels/MarketingChannelsForm';
import {
	ICampaignForm,
	CampaignFormSchema,
} from 'src/lib/schemas/campaign/form';
import useAutoSaveCampaign from 'src/hooks/v2/useAutoSaveCampaign';
import DesignLockModal from './design-directions/LockModal';
import { createOrUpdateCampaign } from 'src/services/campaign';
import UserContext from 'src/contexts/UserContext';
import { debounce } from 'lodash';
import MultiSelectCreatProducteableInputHook from '../common/form/MultiSelectCreateableInput/MultiSelectCreateableProductInputHook';

const CHANNELS = [
	'facebook',
	'instagram',
	'twitter',
	'google',
	'sms',
	'pns',
	'email',
];

interface IQueryState {
	option: IOption;
	type: ECampaignPromotedType;
	selectedChannel?: string;
}

interface CampaignFormProps {
	group?: string;
	availableChannels: IChannel[];
	onCampaignTitleChange: (title: string) => void;
	onCampaignSubmit: () => void;
	onMetaOptionsChange: (options: IOption[]) => void;
	availableChannelGroups: IChannelGroup[] | null;
	setOpenSections: React.Dispatch<React.SetStateAction<boolean[]>>;
	designDirectionsRef: React.RefObject<HTMLDivElement>;
	selectedPlacements: string[];
	setSelectedPlacements: React.Dispatch<React.SetStateAction<string[]>>;
	designDirections: IDesignDirection[];
	setDesignDirections: React.Dispatch<React.SetStateAction<IDesignDirection[]>>;
}

const CampaignForm: FC<CampaignFormProps> = ({
	group,
	availableChannels,
	onCampaignTitleChange,
	onCampaignSubmit,
	onMetaOptionsChange,
	designDirectionsRef,
	availableChannelGroups,
	selectedPlacements,
	setSelectedPlacements,
	designDirections,
	setDesignDirections,
}) => {
	const formProductToggle = useToggleWithPayload<Partial<ICatalog>>();
	const formToneToggle = useToggleWithPayload<IAccountConfigOption>();
	const formAudienceToggle = useToggleWithPayload<IAccountConfigOption>();
	const lockDDToggle = useToggleWithPayload<IDesignDirection[]>([]);
	const [dirtyPlacements, setDirtyPlacements] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);
	const [isChannelUpdating, setIsChannelUpdating] = useState(false);
	const { inputConfig, loadingInputs, loadedInputs } = useContext(
		AppInputConfigContext,
	);
	const { account } = useContext(UserContext);
	const { fetchConfig: fetchTones, createConfig: createTone } =
		useAccountConfigOptions('Tone');
	const { fetchConfig: fetchAudiences, createConfig: createAudience } =
		useAccountConfigOptions('Audience');

	const { catalogKinds } = useCatalogKinds();
	const [isChannelsSelected, setIsChannelsSelected] = useState(false);
	const [selectedChannels, setSelectedChannels] = useState<string[]>();
	const [isPromotedObjectLoaded, setIsPromotedObjectLoaded] = useState(false);
	const {
		campaign,
		id: campaignId,
		completeStep,
		setOpenSections,
	} = useContext(CampaignContext);
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const locationState = location.state as IQueryState;

	const formMethods = useForm<ICampaignForm>({
		resolver: zodResolver(CampaignFormSchema),
	});

	const isFormDirty = formMethods.formState.isDirty;
	const title = formMethods.watch('title');
	const watchedDescription = formMethods.watch('description');
	const promotedObjectType = formMethods.watch('promotedObject.type');
	const destination = formMethods.watch('destination');
	const channels = formMethods.watch('channels') ?? {};
	const watchedPlacements = formMethods.watch('placements') ?? [];
	const watchedPromotedObjectValue = formMethods.watch('promotedObject.value');
	const watchedDestination = formMethods.watch('destination');
	const watchedGoals = formMethods.watch('goal') ?? [];
	const watchedAudience = formMethods.watch('audience');
	const watchedTones = formMethods.watch('tone');
	const watchedPromotion = formMethods.watch('promotion');
	const watchedBudgetTotal = formMethods.watch('budget.total');
	const watchedChannels = Object.keys(channels).filter(
		(item) => channels[item],
	);
	const handleCancel = () => {
		setOpenSections([false, true, false, false]);
		if (designDirectionsRef.current) {
			const elementPosition =
				designDirectionsRef.current.getBoundingClientRect().top +
				window.scrollY;
			const offsetPosition = elementPosition - 400;
			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth',
			});
		}
	};

	useEffect(() => {
		onCampaignTitleChange(title);
	}, [title]);

	useEffect(() => {
		if (loadingInputs['Tone'] || loadedInputs['Tone']) return;
		fetchTones();
	}, [loadingInputs['Tone'], loadedInputs['Tone']]);

	useEffect(() => {
		if (loadingInputs['Audience'] || loadedInputs['Audience']) return;
		fetchAudiences();
	}, [loadingInputs['Audience'], loadedInputs['Audience']]);

	useEffect(() => {
		if (title) {
			formMethods.clearErrors('title');
		}
		if (watchedDescription) {
			formMethods.clearErrors('description');
		}
		if (watchedAudience?.length) {
			formMethods.clearErrors('audience');
		}
		if (watchedTones?.length) {
			formMethods.clearErrors('tone');
		}
		if (
			watchedPromotedObjectValue?.length ||
			promotedObjectType === ECampaignPromotedType.landingPage
		) {
			formMethods.clearErrors('promotedObject.value');
		}
		if (destination) {
			formMethods.clearErrors('destination');
		}
		if (watchedPlacements?.length > 0) {
			formMethods.clearErrors('placements');
			formMethods.clearErrors('channels');
		}
	}, [
		title,
		watchedDescription,
		watchedAudience,
		watchedTones,
		destination,
		watchedPromotedObjectValue,
		promotedObjectType,
		watchedPlacements.length,
	]);

	useEffect(() => {
		const updateCampaignChannels = async () => {
			let selectedChannels = [];
			if (locationState?.selectedChannel) {
				switch (locationState.selectedChannel) {
					case 'all':
						selectedChannels = CHANNELS;
						break;
					case 'direct':
						selectedChannels = ['sms', 'pns', 'email'];
						break;
					default:
						selectedChannels = [locationState.selectedChannel];
						break;
				}
				try {
					const newCampaign = await createOrUpdateCampaign(
						{ channels: selectedChannels },
						'new',
					);
					navigate(`/projects/campaigns/${newCampaign.id}`, {
						replace: true,
						state: { selectedChannels: [locationState.selectedChannel] },
					});
					setIsChannelsSelected(true);
				} catch (error) {
					console.error('Error updating campaign channels:', error);
				}
			}
		};

		updateCampaignChannels();
	}, [locationState]);

	const handleChannelChange = (channelId: string, channelType: ChannelType) => {
		setDirtyPlacements(true);
		// Prevent further updates if one is already in progress
		if (isChannelUpdating) return;

		setIsChannelUpdating(true);

		// Find the selected channel and its placements
		const selectedChannel = availableChannels.find(
			(channel) => channel.id === channelId,
		);

		// Check if the channel and its placements exist
		if (!selectedChannel || !selectedChannel.placements) {
			setIsChannelUpdating(false); // Ensure we reset the updating state if no valid channel found
			return;
		}

		const selectedPlacementIds = selectedChannel.placements
			.filter((placement) => placement.type === channelType)
			.map((item) => item.id);

		setSelectedPlacements((prevSelectedPlacements = []) => {
			let updatedSelectedPlacements = [...prevSelectedPlacements];

			if (
				selectedPlacementIds.every((id) => prevSelectedPlacements.includes(id))
			) {
				// Remove placements if all are already selected
				updatedSelectedPlacements = updatedSelectedPlacements.filter(
					(placementId) => !selectedPlacementIds.includes(placementId),
				);
			} else {
				// Add placements if not all are selected
				selectedPlacementIds.forEach((id) => {
					if (!updatedSelectedPlacements.includes(id)) {
						updatedSelectedPlacements.push(id);
					}
				});
			}

			formMethods.setValue('placements', updatedSelectedPlacements);

			// Update selected channels based on the updated placements
			setSelectedChannels((prevSelectedChannels = []) => {
				const isChannelSelected = prevSelectedChannels.includes(channelId);

				// Check if any selected placements belong to the current channel
				const hasPlacementsSelectedForChannel =
					selectedChannel?.placements?.some((placement) =>
						updatedSelectedPlacements.includes(placement.id),
					);

				// Remove the channel if none of its placements are selected
				if (isChannelSelected && !hasPlacementsSelectedForChannel) {
					return prevSelectedChannels.filter((id) => id !== channelId);
				}

				// Add the channel if at least one of its placements is selected
				if (!isChannelSelected && hasPlacementsSelectedForChannel) {
					return [...prevSelectedChannels, channelId];
				}

				// No change in channels
				return prevSelectedChannels;
			});

			setIsChannelUpdating(false); // Re-enable input once state is updated
			return updatedSelectedPlacements;
		});
	};

	const handlePlacementsChange = (updatedSelectedPlacements: string[]) => {
		formMethods.setValue('placements', updatedSelectedPlacements);
	};

	useEffect(() => {
		if (
			campaignId === 'new' &&
			availableChannels.length &&
			!isChannelsSelected
		) {
			setIsChannelsSelected(true);
			setSelectedChannels(CHANNELS);
			const selectedPlacementIds = availableChannels.flatMap(
				(channel) => channel.placements?.map((placement) => placement.id) || [],
			);
			formMethods.setValue('placements', selectedPlacementIds);
			formMethods.setValue(
				'promotedObject.type',
				ECampaignPromotedType.landingPage,
			);
		}
	}, [campaignId, availableChannels]);

	useEffect(() => {
		if (account && promotedObjectType === ECampaignPromotedType.landingPage) {
			formMethods.setValue(
				'destination',
				account.websiteLink ?? 'www.example.com',
			);
		}
	}, [promotedObjectType]);

	const handleMetaValueChange = (
		value: IOption['value'][] | IOption[],
		generateByAI?: boolean,
	) => {
		if (typeof value[0] === 'string') return;

		if (generateByAI) {
			formMethods.setValue(
				'promotedObject.type',
				ECampaignPromotedType.landingPage,
			);
		} else {
			formMethods.setValue(
				'promotedObject.type',
				ECampaignPromotedType.catalogs,
			);
		}

		formMethods.setValue('destination', value[0]?.url ?? '');
	};

	useEffect(() => {
		if (!dirtyPlacements && isSubmitting) {
			onCampaignSubmit();
			setIsSubmitting(false);
		}
	}, [dirtyPlacements, isSubmitting]);

	const submitForm = formMethods.handleSubmit(async () => {
		setOpenSections([false, true, false, false]);
		completeStep(0);
		if (dirtyPlacements) {
			setIsSubmitting(true);
			return;
		}
		onCampaignSubmit();
	});

	const {
		data: products,
		refetch: refetchProducts,
		isLoading: isProductsLoading,
	} = useFetch<ICatalog[]>(() => getAccountConfigOptions('catalogs'));

	const handleCreateProduct = async (payload: ICatalogForm) => {
		try {
			const createdCatalog = await createAccountConfigOptions(
				'catalogs',
				payload,
			);
			refetchProducts();
			formMethods.setValue('promotedObject.value', [
				{
					value: createdCatalog._id,
					label: payload.name,
				},
			]);
			formMethods.setValue('destination', payload.url);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleCreateNewMeta = (val: string) => {
		const defaultInitialValues = {
			kind: 'service',
			name: val,
			url: '',
			details: {},
			category: undefined,
			tags: [],
		};

		switch (promotedObjectType) {
			case ECampaignPromotedType.catalogs:
				formProductToggle.onOpen(defaultInitialValues);
				break;
			default:
				break;
		}
	};

	const getMetaOptions = () => {
		if (promotedObjectType === ECampaignPromotedType.catalogs) {
			return (products || []).map((product) => ({
				label: product.name,
				value: product.id,
				url: product.url,
			}));
		}
	};

	const handleCreateToneOption = async (name: string) => {
		if (!name.trim()) {
			formToneToggle.onOpen();
			return;
		}

		await handleCreateTone({ name });
	};

	const handleCreateTone = async (payload: IAccountConfigOption) => {
		try {
			const newTone = await createTone(payload);
			formMethods.setValue('tone', [...(watchedTones || []), newTone.id]);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleCreateAudienceOption = async (name: string) => {
		if (!name.trim()) {
			formAudienceToggle.onOpen();
			return;
		}

		await handleCreateAudience({ name });
	};

	const handleCreateAudience = async (payload: IAccountConfigOption) => {
		try {
			const newAudience = await createAudience(payload);
			formMethods.setValue('audience', [
				...(watchedAudience || []),
				newAudience.id,
			]);
		} catch (error: any) {
			toastError(error);
		}
	};

	const isMetaOptionsLoading = isProductsLoading;
	const CAMPAIGN_META_OPTIONS = useMemo(
		() => getMetaOptions(),
		[isMetaOptionsLoading, promotedObjectType],
	);

	useEffect(() => {
		onMetaOptionsChange(CAMPAIGN_META_OPTIONS ?? []);
	}, [CAMPAIGN_META_OPTIONS]);

	const queryStatus = searchParams.get('status');

	useEffect(() => {
		if (!campaignId || !campaign?.budget) return;
		if (campaign?.budget?.total) {
			if (watchedBudgetTotal !== String(campaign.budget.total)) {
				formMethods.setValue('budget.total', String(campaign?.budget?.total));
			}
			formMethods.setValue(
				'budget.breakdown',
				campaign?.budget.breakdown ?? [],
			);
		}
	}, [campaign?.budget]);

	useEffect(() => {
		formMethods.setValue('budget.total', '0');
	}, []);

	useEffect(() => {
		setSelectedChannels(campaign?.channels);
		if (
			campaign &&
			campaign.promotedObject &&
			!isMetaOptionsLoading &&
			!isPromotedObjectLoaded
		) {
			const { promotedObject, destination } = transformCampaignToForm(
				campaign,
				CAMPAIGN_META_OPTIONS ?? [],
			);
			if (promotedObject.type === promotedObjectType) {
				setIsPromotedObjectLoaded(true);
				formMethods.setValue('promotedObject.value', promotedObject.value);
				formMethods.setValue('destination', destination);
			}
		}
	}, [campaign, CAMPAIGN_META_OPTIONS]);

	useEffect(() => {
		if (
			campaign &&
			campaign.promotedObject &&
			!isMetaOptionsLoading &&
			!isCampaignLoaded
		) {
			setIsCampaignLoaded(true);
			const {
				title,
				description,
				promotedObject,
				destination,
				audience,
				tone,
				promotion,
				channels,
				budget,
				placements,
			} = transformCampaignToForm(campaign, CAMPAIGN_META_OPTIONS ?? []);

			formMethods.reset({
				title,
				description,
				promotedObject,
				destination,
				audience,
				tone,
				promotion,
				channels,
				budget,
				placements,
			});
		}
	}, [campaign, queryStatus, isMetaOptionsLoading]);

	const autosave = useAutoSaveCampaign(setDirtyPlacements, setDesignDirections);

	const debouncedHandleFieldChanges = useCallback(
		debounce(() => {
			handleFieldChanges();
		}, 300),
		[],
	);

	useEffect(() => {
		const isNewCampaign = campaignId && campaignId === 'new';
		const isOldCampaignLoaded = Boolean(campaign && isCampaignLoaded);
		const shouldSaveDelta = isNewCampaign || isOldCampaignLoaded;
		if (!shouldSaveDelta || !group) return;
		(isFormDirty || campaignId !== 'new') && debouncedHandleFieldChanges();
	}, [
		promotedObjectType,
		watchedPromotedObjectValue,
		watchedDestination,
		watchedAudience,
		watchedTones,
		watchedPromotion,
		watchedGoals.length,
		watchedChannels.length,
		watchedPlacements.length,
		group,
		debouncedHandleFieldChanges,
	]);

	const handleFieldChanges = async () => {
		autosave.onFieldsChange({ ...formMethods.getValues(), group });
	};

	const setFieldValue = (field: any, value: string) => {
		formMethods.setValue(field, value);
		debouncedHandleFieldChanges();
	};

	const handleTitleTabKeyPress = (e: any) => {
		if (e.key === 'Tab') {
			e.preventDefault();
			formMethods.setFocus('description');
		}
	};
	return (
		<Container maxW="7xl" px={0} pb={5} m={0}>
			<FormProvider {...formMethods}>
				<form>
					<Flex direction="column" gap={10}>
						<StringInputHook
							name="title"
							label="Campaign name"
							formLabelProps={{ fontSize: '16px' }}
							requirementsLabel="This is the name that you will use to identify this later.  e.g: Christmas campaign"
							placeholder="Enter campaign name"
							requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
							inputProps={{
								onChange: debouncedHandleFieldChanges,
								onKeyDown: (e: any) => handleTitleTabKeyPress(e),
							}}
							direction="row"
							required
						/>
						<TextareaInputHook
							name="description"
							label="Describe your campaign"
							formLabelProps={{ fontSize: '16px' }}
							requirementsLabel="Describe what you are promoting, to whom, when, and what you want to achieve. The more you put here, the better the results."
							placeholder="What are you promoting and why? To whom? When? What are you trying to achieve? The more detail the better."
							textareaProps={{ onChange: debouncedHandleFieldChanges }}
							requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
							withAssistant
							setFieldValue={setFieldValue}
							required
							direction="row"
						/>
						<MultiSelectCreatProducteableInputHook
							name="promotedObject.value"
							label="Do you have an image for what you are promoting?"
							requirementsLabel="Select or create a product or a service from your catalog"
							formLabelProps={{ fontSize: '16px' }}
							requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
							isLoading={isMetaOptionsLoading}
							placeholder="Select/search"
							options={CAMPAIGN_META_OPTIONS || []}
							onValueChangeCallback={handleMetaValueChange}
							onCreateOption={handleCreateNewMeta}
							isMulti={false}
							promotedObjectType={promotedObjectType}
							valueAsObject
							isClearable
							required
							direction="row"
						/>
						<Flex direction="column">
							<MultiSelectCreateableInputHook
								name="audience"
								label="Who are your customers?"
								formLabelProps={{ fontSize: '16px' }}
								requirementsLabel="Select or create the characteristics which describe your customers"
								requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
								placeholder="Select/search"
								options={inputConfig['Audience'] ?? []}
								isLoading={loadingInputs['Audience']}
								onCreateOption={handleCreateAudienceOption}
								isMulti
								required
								direction="row"
							/>
							<Flex alignSelf="flex-end">
								<MultiSelectValuesHook
									name="audience"
									title="Target Audience"
									options={inputConfig['Audience'] ?? []}
								/>
							</Flex>
						</Flex>
						<Flex direction="column">
							<MultiSelectCreateableInputHook
								name="tone"
								label="What tone of voice do you want to use?"
								formLabelProps={{ fontSize: '16px' }}
								requirementsLabel="Select or create the characteristics which describe how to communicate with your customers"
								requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
								placeholder="Select/search"
								options={inputConfig['Tone'] ?? []}
								isLoading={loadingInputs['Tone']}
								onCreateOption={handleCreateToneOption}
								isMulti
								required
								direction="row"
							/>
							<Flex alignSelf="flex-end">
								<MultiSelectValuesHook
									name="tone"
									options={inputConfig['Tone'] ?? []}
								/>
							</Flex>
						</Flex>

						<Box>
							<PromoCodesInputHook
								name="promotion"
								label="Will you offer a promotional codes? (Optional)"
								requirementsLabel="Please enter a promotional discount code your customers can use"
								requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
								formLabelProps={{ mb: 0, fontSize: '16px' }}
								valuesContainerProps={{ mt: 0 }}
								direction="row"
								withRadioOptions={true}
							/>
						</Box>
						<MarketingChannelsForm
							availableChannels={availableChannels}
							availableChannelGroups={availableChannelGroups || []}
							requirementsLabel="Select all the channels where you want to promote your business and we will generate everything for that specific channel."
							requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
							// error={channelsErrors?.root.message}
							selectedPlacements={selectedPlacements}
							onChange={handleChannelChange}
							handlePlacementsChange={handlePlacementsChange}
						/>
						<Box display="none" textAlign="center">
							<Button
								id="campaign-config-form-submit"
								colorScheme="secondary"
								fontWeight="medium"
								type="submit"
							></Button>
						</Box>

						<Box alignSelf="flex-end">
							<Button
								variant="orangeSolid"
								type="button"
								id="campaign-config-form-submit-1"
								onClick={() => {
									if (
										Object.keys(formMethods.formState.errors).length === 0 &&
										designDirections?.length &&
										designDirections?.some((dd) => dd.status === 'GENERATED')
									) {
										lockDDToggle.onOpen(
											designDirections.filter(
												(dd) => dd.status === 'GENERATED',
											),
										);
										return;
									}
									submitForm();
								}}
							>
								Generate Designs
							</Button>
						</Box>
					</Flex>
				</form>
			</FormProvider>

			<ProductFormModal
				isOpen={formProductToggle.isOpen}
				catalogKinds={catalogKinds}
				onClose={formProductToggle.onClose}
				onSubmit={handleCreateProduct}
				title="What are you promoting?"
				initialValues={formProductToggle.payload}
			/>
			<ConfigFormModal
				isOpen={formToneToggle.isOpen}
				onClose={formToneToggle.onClose}
				onSubmit={handleCreateTone}
				initialValues={formToneToggle.payload}
				title="Tone"
			/>
			<ConfigFormModal
				isOpen={formAudienceToggle.isOpen}
				onClose={formAudienceToggle.onClose}
				onSubmit={handleCreateAudience}
				initialValues={formAudienceToggle.payload}
				title="Audience"
			/>
			<DesignLockModal
				isOpen={lockDDToggle.isOpen}
				onCancel={handleCancel}
				onClose={lockDDToggle.onClose}
				onSubmit={submitForm}
				designDirections={lockDDToggle.payload}
				setDesignDirections={setDesignDirections}
			/>
		</Container>
	);
};

export default CampaignForm;
