import { FC, ReactNode } from 'react';
import {
	Box,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	VStack,
	useAccordionItemState,
} from '@chakra-ui/react';

interface CampaignSectionProps {
	title: string;
	isOpen: boolean;
	accordionContent: ReactNode;
	extraInformation?: ReactNode;
	onToggle?: () => void;
	isDisabled?: boolean;
}

const CampaignSection: FC<CampaignSectionProps> = ({
	title,
	isOpen,
	accordionContent,
	extraInformation,
	onToggle,
	isDisabled = false,
}) => {
	return (
		<VStack spacing={0} mb={5}>
		<Accordion
		  allowToggle={!isDisabled}  
		  index={isOpen && !isDisabled ? 0 : -1}
		  boxShadow="0px 0px 15px -3px rgba(0, 0, 0, 0.25)"
		  borderRadius="8px"
		  borderWidth="1px"
		  borderColor={isDisabled ? 'gray.100' : 'gray.100'}  
		  w="full"
		  onChange={onToggle}
		>
		  <AccordionItem border="none">
			{({ isExpanded }) => (
			  <>
				<AccordionButton
				  borderRadius="10px"
				  _hover={{ bg: 'inherit' }}
				  opacity={isDisabled ? 0.6 : 1} 
				  cursor={isDisabled ? 'default' : 'pointer'}  
				  data-testid="campaign-stepper"
				>
				  <Box
					py={5}
					px={2}
					flex="1"
					textAlign="left"
					fontWeight="bold"
					fontSize="lg"
					color={isExpanded ? 'black' : 'gray.500'}
				  >
					{title}
				  </Box>
				  <AccordionIcon boxSize={6} />
				</AccordionButton>
				<AccordionPanel pb={4} px={0}>
				  <Box px={4}>{accordionContent}</Box>
				</AccordionPanel>
			  </>
			)}
		  </AccordionItem>
		</Accordion>
		{extraInformation && (
		  <Box
			w="95%"
			bg="white"
			borderWidth="1px"
			borderColor="gray.100"
			borderRadius="0 0 8px 8px"
			boxShadow="0px 0px 15px -3px rgba(0, 0, 0, 0.25)"
			clipPath="inset(0 -10px -10px -10px)"
		  >
			{extraInformation}
		  </Box>
		)}
	  </VStack>
	);
  };

export default CampaignSection;
