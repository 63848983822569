import {
	useState,
	useRef,
	useEffect,
	FC,
	memo,
	RefObject,
	useContext,
	useCallback,
} from 'react';
import {
	Box,
	Button,
	Flex,
	Heading,
	Link,
	Skeleton,
	Text,
} from '@chakra-ui/react';

import Carousel from './parts/Carousel';
import EditDesignDirection from './parts/EditDesignDirection';
import { EditIcon, SparklesIcon, DownloadIcon } from 'src/assets/icons';
import {
	IChannelMediaAttributes,
	IDesignDirection,
	ImageLayer,
	TextLayer,
} from 'src/lib/schemas';
import InformationBanner from 'src/components/common/InformationBanner';
import CarouselArrowButtons from './parts/Carousel/CarouselArrowButtons';
import { updateCampaignCreative } from 'src/services/campaign';
import { CampaignContext } from 'src/contexts';
import http from 'src/services/http';
import { customToast, toastError } from 'src/services/toast';
import Overlay from 'src/components/common/Overlay';
import { IActions, IPayload, IPerview } from './parts/utils';
import { delay } from 'src/lib/utils';
import _ from 'lodash';

interface DesignDirectionsProps {
	designDirections: IDesignDirection[];
	onRefetchDD: () => void;
	onGenerateNewDD: () => void;
	onRemoveDD: (ddId: string) => void;
	onGenerateCreatives: (designDirectionId?: string) => void;
	isGeneratingCreatives: boolean;
	isGeneratingDesignDirections: boolean;
	handleLockUnlockDD: (
		designDirectionId: string,
		locked: boolean,
		isDD?: boolean,
	) => void;
	onScroll: (scrollLeft: number) => void;
	scrollPosition: number;
	creativesRef: RefObject<HTMLDivElement>;
	handleIsGeneratingCreatives: (status: boolean) => void;
}

const DesignDirections: FC<DesignDirectionsProps> = ({
	designDirections,
	onRefetchDD,
	onGenerateNewDD,
	onRemoveDD,
	onGenerateCreatives,
	isGeneratingCreatives,
	isGeneratingDesignDirections,
	handleLockUnlockDD,
	onScroll,
	scrollPosition,
	creativesRef,
	handleIsGeneratingCreatives,
}) => {
	const { id: campaignId } = useContext(CampaignContext);
	const [isPreviewLoading, setIsPreviewLoading] = useState(false);
	const [editMode, setEditMode] = useState(true);
	const [isDDLoaded, setisDDLoaded] = useState(false);
	const [isGeneratingDD, setIsGeneratingDD] = useState(false);
	const [shouldScrollToEnd, setShouldScrollToEnd] = useState(false);
	const [isRemovingDD, setIsRemovingDD] = useState(false);
	const [isChangingImageLayer, setIsChangingImageLayer] = useState(false);
	const [showLeftArrowButton, setShowLeftArrowButton] = useState(false);
	const [showRightArrowButton, setShowRightArrowButton] = useState(false);
	const [textLayersContent, setTextLayersContent] = useState<any>([]);

	const [currentDesignDirection, setCurrentDesignDirection] =
		useState<IDesignDirection | null>(null);

	const [previewPSDMeta, setPreviewPSDMeta] = useState<IPerview>({
		flatFile: '',
		layeredFile: '',
		layers: [],
	});

	const mediaContent =
		currentDesignDirection?.attributes as unknown as IChannelMediaAttributes;

	useEffect(() => {
		const image = mediaContent?.image || {};
		if (currentDesignDirection && image && image.layeredFile) {
			setPreviewPSDMeta((prevState) => ({
				...prevState,
				flatFile: image.flatFile,
				layeredFile: image.layeredFile,
				layers: image.layers,
			}));
		}
	}, [mediaContent?.image?.layeredFile]);

	const headlineTextLayers = mediaContent?.image.layers?.filter(
		(layer) => layer.type === 'text' && layer.primary,
	) as TextLayer[];

	const handleLayersTextChange = useCallback(
		(content: any, layers: any) => {
			const cleanContent = _.cloneDeep(content);
			const hasNestedObject = (obj: any): boolean => {
				return Object.values(obj).some((val) => typeof val === 'object');
			};

			hasNestedObject(cleanContent) &&
				Object.keys(cleanContent).forEach((key) => {
					if (key.includes('.')) delete cleanContent[key];
				});

			const layersCopy = [...(mediaContent?.image?.layers ?? [])];
			const newLayers: any = [];
			layers.forEach((layer: any) => {
				let currentLayer: any = layer;
				const parents = [];
				while (currentLayer.parentName) {
					const parentLayer = layersCopy.find(
						(layer) => layer.name === currentLayer.parentName,
					);
					if (parentLayer) parents.unshift(currentLayer.parentName);
					currentLayer = parentLayer;
				}

				const newLayer = {
					name: layer.name,
					content: _.get(cleanContent, layer.name),
					parents,
				};
				newLayers.push(newLayer);
			});
			setTextLayersContent(newLayers);
			handleChangeTextLayerContent();
		},
		[mediaContent],
	);

	const handleChangeTextLayerContent = useCallback(() => {
		document.getElementById('change-text-layer-button-preview')?.click();
	}, []);

	const handleScroll = useCallback((scrollLeft: number) => {
		const container = scrollRef.current;
		if (container) {
			setShowLeftArrowButton(container.scrollLeft > 0);
			setShowRightArrowButton(
				container.scrollWidth > container.clientWidth + container.scrollLeft,
			);
			container.scrollLeft = scrollLeft;
		}
	}, []);

	const scrollRef = useRef<HTMLDivElement>(null);
	const designDirectionRef = useRef<HTMLDivElement>(null);

	const handleNext = useCallback(
		() => handleScroll(scrollPosition + 370),
		[handleScroll, scrollPosition],
	);
	const handlePrev = useCallback(
		() => handleScroll(scrollPosition - 370),
		[handleScroll, scrollPosition],
	);

	useEffect(() => {
		handleScroll(scrollPosition);
		const container = scrollRef.current;
		if (container) {
			setShowLeftArrowButton(container.scrollLeft > 0);
			setShowRightArrowButton(
				container.scrollWidth > container.clientWidth + container.scrollLeft,
			);
		}
	}, [handleScroll, scrollPosition, designDirections]);

	const handleRemoveDD = async (ddId: string) => {
		setIsRemovingDD(true);
		await onRemoveDD(ddId);
		if (currentDesignDirection?.id === ddId) {
			setCurrentDesignDirection(null);
			setEditMode(false);
		}
		setIsRemovingDD(false);
	};

	const handleRetryGenerateDD = (designDirection: IDesignDirection) => {
		handleRemoveDD(designDirection.id);
		handleGenerateDD();
	};

	// useEffect(() => {
	// 	let scrollTimeoutId: NodeJS.Timeout | undefined;
	// 	if (designDirections.length > 0 && shouldScrollToEnd) {
	// 		scrollTimeoutId = setTimeout(() => {
	// 			scrollEndOfCarousel();
	// 			setShouldScrollToEnd(false);
	// 		}, 700);
	// 	}
	// 	return () => {
	// 		clearTimeout(scrollTimeoutId);
	// 	};
	// }, [designDirections, shouldScrollToEnd, isGeneratingDD]);

	const handleGenerateDD = async (editModeOpen?: boolean) => {
		setIsGeneratingDD(true);
		await onGenerateNewDD();
		await delay(1000);
		handleScrollToView(designDirectionRef);
		await delay(1000);
		scrollEndOfCarousel();
		await delay(1000);
		if (editModeOpen) setCurrentDesignDirection(null);
		setIsGeneratingDD(false);
		setShouldScrollToEnd(true);
	};

	useEffect(() => {
		const isSomeDDGenerated = designDirections.find(
			({ status }) => status === 'GENERATED',
		);
		if (isSomeDDGenerated && currentDesignDirection === null && !isDDLoaded) {
			setCurrentDesignDirection(isSomeDDGenerated);
			setisDDLoaded(true);
		}

		if (currentDesignDirection !== null) {
			const updatedDD = designDirections.find(
				(dd) => dd.id === currentDesignDirection.id,
			);
			updatedDD && setCurrentDesignDirection(updatedDD);
		}
	}, [designDirections, currentDesignDirection, isDDLoaded]);

	const handleScrollToView = (ref: RefObject<HTMLElement>) => {
		setTimeout(() => {
			ref?.current?.scrollIntoView({
				behavior: 'smooth',
			});
		}, 100);
	};

	const scrollEndOfCarousel = () => {
		const container = scrollRef.current;
		if (container) {
			const scrollWidth = container.scrollWidth;
			const clientWidth = container.clientWidth;
			const maxScrollLeft = scrollWidth - clientWidth;
			handleScroll(maxScrollLeft);
		}
	};

	const handleEditMode = (generateNewDD?: boolean) => {
		if (generateNewDD) handleGenerateDD(generateNewDD);
		else {
			handleScrollToView(creativesRef);
			setEditMode(false);
			setCurrentDesignDirection(null);
		}
	};

	const handleGeneratNewDD = (actions: IActions) => {
		if (
			currentDesignDirection &&
			editMode &&
			headlineTextLayers.find(
				(layer) =>
					layer.content !==
					textLayersContent.find((l: any) => l.name === layer.name)?.content,
			)
		) {
			try {
				handleChangingPreviewLoading({ preview: true, newDD: true });
				const payload: IPayload = {
					designDirection: currentDesignDirection,
					psdPerview: { ...previewPSDMeta },
					text: textLayersContent,
					actions,
				};
				changeTextLayer(payload);
			} catch (error) {
				console.log(error);
				handleChangingPreviewLoading({ preview: false, newDD: false });
			}
		} else handleGenerateDD();
	};

	const handleEditDD = (dd: IDesignDirection) => {
		setIsPreviewLoading(true);

		try {
			if (
				currentDesignDirection &&
				editMode &&
				headlineTextLayers.find(
					(layer) =>
						layer.content !==
						textLayersContent.find((l: any) => l.name === layer.name)?.content,
				)
			) {
				const payload: IPayload = {
					designDirection: currentDesignDirection,
					psdPerview: {
						flatFile: previewPSDMeta.flatFile,
						layeredFile: previewPSDMeta.layeredFile,
						layers: previewPSDMeta.layers,
					},
					text: textLayersContent,
					actions: {
						action: 'changeDD',
						generateCreatives: false,
						editMode: false,
						changeDD: true,
					},
				};
				changeTextLayer(payload, dd);
			} else {
				setCurrentDesignDirection((prev) => {
					if (prev?.id === dd.id) return prev;
					setEditMode(true);
					return dd;
				});
				setIsPreviewLoading(false);
			}
		} catch (error) {
			console.log(error);
			setIsPreviewLoading(false);
		}
	};

	const changeTextLayer = async (payload: IPayload, dd?: IDesignDirection) => {
		const { layeredFile } = payload.psdPerview;
		const values = payload.text.map((layer: any) => ({
			value: layer.content,
			layer: layer.name,
			property: 'text',
		}));

		const changeLayerPayload = {
			inputs: {
				layeredFile,
				values,
			},
		};

		try {
			const { data } = await http.post(
				'/v2/apps/fusion_ai.edit_psd/execute/edit_psd',
				changeLayerPayload,
			);
			if (data) await processCallback(data.callback, payload, dd);
		} catch (error) {
			toastError(error);
			resetLoadingStates(payload);
		}
	};

	const processCallback = async (
		callbackUrl: string,
		payload: IPayload,
		dd?: IDesignDirection,
	) => {
		try {
			const response = await http.get(callbackUrl);
			const { status, body } = response.data;
			if (status === 'processing' || status === 'pending') {
				setTimeout(processCallback.bind(null, callbackUrl, payload, dd), 500);
			} else if (status === 'error' || status === 'failed') {
				customToast('Error processing image', 'error');
				resetLoadingStates(payload);
			} else if (status === 'successful') {
				const updatedLayers = headlineTextLayers.map((layer: any) => {
					return {
						...layer,
						content: payload.text.find((l: any) => l.name === layer.name)
							.content,
					};
				});
				const filterdLayers = payload.psdPerview.layers.filter(
					(layer: any) =>
						!headlineTextLayers.some((l: any) => l.name === layer.name),
				);
				const layers = [...filterdLayers, ...updatedLayers];

				setPreviewPSDMeta((prevState) => ({
					...prevState,
					flatFile: body.flatFile,
					layeredFile: body.layeredFile,
					layers: layers,
				}));

				await updateDD(
					{
						flatFile: body.flatFile,
						layeredFile: body.layeredFile,
						layers,
					},
					payload.designDirection,
				);
				payload.actions.generateCreatives && (await onGenerateCreatives());
				payload.actions.editMode &&
					handleEditMode(payload.actions.editModeStatus);
				payload.actions.changeDD &&
					dd &&
					setCurrentDesignDirection((prev) => {
						if (prev?.id === dd.id) return prev;
						setEditMode(true);
						return dd;
					});

				resetLoadingStates(payload);
			}
		} catch (e) {
			console.log({ e });
			toastError(e);
			resetLoadingStates(payload);
		}
	};

	const updateDD = async (
		payload: IPerview,
		designDirection: IDesignDirection,
	) => {
		const attributes =
			designDirection?.attributes as unknown as IChannelMediaAttributes;
		if (!attributes || !campaignId) return;

		const updatePayload = {
			attributes: {
				...attributes,
				image: {
					...attributes.image,
					layeredFile: payload.layeredFile,
					flatFile: payload.flatFile,
					layers: payload.layers,
				},
				headline: textLayersContent.find(
					(layer: any) => layer.name === '$Headline',
				)?.content,
			},
		};
		const data = await updateCampaignCreative(
			campaignId,
			designDirection.id,
			updatePayload,
		);
		// onRefetchDD();
	};

	const resetLoadingStates = (payload: IPayload) => {
		setIsPreviewLoading(false);
		payload.actions.action === 'newDD' && setIsGeneratingDD(false);
		payload.actions.action === 'generateCreatives' &&
			handleIsGeneratingCreatives(false);
		payload.actions.action === 'changeImage' && setIsChangingImageLayer(false);
	};
	const handleChangingPreviewLoading = (status: Record<string, boolean>) => {
		const { preview, newDD, generateCreatives, changeImage } = status;
		preview !== undefined && setIsPreviewLoading(preview);
		newDD !== undefined && setIsGeneratingDD(newDD);
		changeImage !== undefined && setIsChangingImageLayer(changeImage);
		generateCreatives !== undefined &&
			handleIsGeneratingCreatives(generateCreatives);
	};

	const allDDGenerated = designDirections.every(
		(dd) => dd.status === 'GENERATED',
	);
	const handleGenerateCreatives = async (actions: IActions) => {
		handleChangingPreviewLoading({ preview: true, generateCreatives: true });
		try {
			if (
				currentDesignDirection &&
				editMode &&
				headlineTextLayers.find(
					(layer) =>
						layer.content !==
						textLayersContent.find((l: any) => l.name === layer.name)?.content,
				)
			) {
				const payload: IPayload = {
					designDirection: currentDesignDirection,
					psdPerview: { ...previewPSDMeta },
					text: textLayersContent,
					actions,
				};
				changeTextLayer(payload);
			} else {
				await onGenerateCreatives();
				handleEditMode();
				handleChangingPreviewLoading({
					preview: false,
					generateCreatives: false,
				});
			}
		} catch (error) {
			console.log(error);
			handleChangingPreviewLoading({
				preview: false,
				generateCreatives: false,
			});
		}
	};

	const handleChangeMediaImage = (
		flatFile: string,
		layeredFile: string,
		layers: (ImageLayer | TextLayer)[],
	) => {
		setPreviewPSDMeta((prevState) => ({
			...prevState,
			flatFile: flatFile,
			layeredFile: layeredFile,
			layers: layers,
		}));

		if (currentDesignDirection) {
			handleChangingPreviewLoading({ preview: true, changeImage: true });

			const payload: IPayload = {
				designDirection: currentDesignDirection,
				psdPerview: {
					flatFile: flatFile,
					layeredFile: layeredFile,
					layers: layers,
				},
				text: textLayersContent,
				actions: {
					action: 'changeImage',
					generateCreatives: false,
					editMode: false,
				},
			};
			changeTextLayer(payload);
		}
	};

	return (
		<Flex direction="column" gap={7} ref={designDirectionRef}>
			<InformationBanner title="Steps to improve your design" type="info">
				<Text mt="2">
					<b>1. Apply your font and logo:</b>{' '}
					<Link
						href="/account/company"
						style={{ textDecoration: 'underline', color: '#2c6ecb' }}
					>
						Click here
					</Link>{' '}
					to go to your account.
				</Text>

				<Text mt="2">
					<b>
						2. Edit your designs to give them the last touch in 3 different ways
					</b>
				</Text>
				<Box ml={6}>
					<Box mt="2">
						<ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
							<li style={{ marginBottom: '8px', fontSize: '14px' }}>
								Edit all the layers in your browser: Place the cursor over the
								image and click on <EditIcon width={4} height={4} />{' '}
								<span style={{ color: '#2c6ecb' }}>Edit layers</span> to open
								the online editor.
							</li>
							<li style={{ marginBottom: '8px', fontSize: '14px' }}>
								Edit all the layers in Photoshop: Place the cursor over the
								image and click on <DownloadIcon width={4} height={4} />{' '}
								<span style={{ color: '#2c6ecb' }}>Download</span> and make any
								change in Photoshop.
							</li>
							<li style={{ marginBottom: '8px', fontSize: '14px' }}>
								Edit all the layers in real-time: Select an ad option and scroll
								to the section Edit ad direction and you will see all the
								fields.
							</li>
						</ul>
					</Box>
				</Box>
			</InformationBanner>
			<Flex direction="column" gap={4}>
				<Heading fontWeight="bold">Ad options</Heading>

				<Flex gap={2} alignItems="center">
					<Text>Select design direction</Text>
					<Button
						colorScheme="secondary"
						height={8}
						rightIcon={<SparklesIcon color="white" />}
						onClick={() =>
							handleGeneratNewDD({
								action: 'newDD',
								generateCreatives: false,
								editMode: true,
								editModeStatus: true,
							})
						}
						isLoading={isGeneratingDD}
						isDisabled={isChangingImageLayer || isPreviewLoading}
						size="sm"
					>
						Add new design direction
					</Button>
				</Flex>
			</Flex>
			<Box position="relative">
				<Flex
					ref={scrollRef}
					onScroll={() =>
						scrollRef.current && onScroll(scrollRef.current.scrollLeft)
					}
					borderRadius="md"
					bg="#F8F8F8"
					boxShadow="0px 0.913px 5.478px 0px rgba(0, 0, 0, 0.15)"
					px={6}
					py={8}
					gap={8}
					overflowX="auto"
					direction="column"
					alignItems="flex-start"
					scrollBehavior="auto"
					sx={{
						'::-webkitScrollbar': {
							display: 'none',
						},
						MsOverflowStyle: 'none',
						scrollbarWidth: 'none',
						webkitOverflowScrolling: 'touch',
					}}
				>
					<Flex position="relative">
						{isPreviewLoading && (
							<Overlay zIndex={50} h="full" bg="rgba(255, 255, 255, 0)">
								<Flex w="full" h="full" justify="center" align="center"></Flex>
							</Overlay>
						)}

						{isGeneratingDesignDirections ? (
							<Flex gap={10}>
								<Skeleton h="438px" w="438px" borderRadius="21px" />
								<Skeleton h="438px" w="438px" borderRadius="21px" />
								<Skeleton h="438px" w="438px" borderRadius="21px" />
							</Flex>
						) : (
							<Carousel
								designDirections={designDirections}
								currentDDId={currentDesignDirection?.id}
								onEdit={handleEditDD}
								onRemove={handleRemoveDD}
								isRemovingDD={isRemovingDD}
								onRetry={handleRetryGenerateDD}
							/>
						)}
					</Flex>
					{scrollRef.current &&
						scrollRef.current.scrollWidth > scrollRef.current.clientWidth && (
							<CarouselArrowButtons
								showLeftArrowButton={showLeftArrowButton}
								showRightArrowButton={showRightArrowButton}
								onNext={handleNext}
								onPrev={handlePrev}
							/>
						)}
				</Flex>
			</Box>

			<EditDesignDirection
				key={currentDesignDirection?.id ?? 'no-key'}
				designDirection={currentDesignDirection}
				editMode={editMode}
				onLockUnlock={handleLockUnlockDD}
				onRefetchDesignDirections={onRefetchDD}
				isGeneratingDD={isGeneratingDD}
				isGeneratingCreatives={isGeneratingCreatives}
				previewPSDMeta={previewPSDMeta}
				isPreviewLoading={isPreviewLoading}
				isChangingLayer={isChangingImageLayer}
				handleChangingLayer={(status) => {
					setIsChangingImageLayer(status);
				}}
				textLayerContent={textLayersContent}
				onLayersTextChange={handleLayersTextChange}
				allDDGenerated={allDDGenerated}
				onChangeMediaImage={handleChangeMediaImage}
				onGenerateNewDD={() =>
					handleGeneratNewDD({
						action: 'newDD',
						generateCreatives: false,
						editMode: true,
						editModeStatus: true,
					})
				}
				onGenerateCreatives={() =>
					handleGenerateCreatives({
						action: 'generateCreatives',
						generateCreatives: true,
						editMode: true,
					})
				}
			/>
		</Flex>
	);
};

export default memo(DesignDirections);
