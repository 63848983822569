import { useContext, useEffect, useRef, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	HStack,
	Skeleton,
	Text,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';
import {
	IChannelCreativeAttributes,
	IChannelGroup,
	ICreative,
	IDesignDirection,
} from 'src/lib/schemas';
import DDCard from './DDCard';
import GenerateOrSelectCard from './GenerateOrSelectCard';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import { removeDuplicateLayers } from '../utils/removeDuplicateLayers';
import EditDDModal from './EditDDModal';
import { AssistantChatContext, CampaignContext } from 'src/contexts';
import {
	getCampaign,
	genrateNewDesignDirection,
	removeDesignDirection,
	genrateCampaignCreatives,
} from 'src/services/campaign';
import { toastError, toastSuccess } from 'src/services/toast';
import Creatives from '../creatives/Creatives';
interface DesignDirectionsProps {
	creativesConfig: IChannelCreativeAttributes[];
	onSubmit: () => void;
	isLoading?: boolean;
	designDirections: IDesignDirection[];
	setDesignDirections: React.Dispatch<
		React.SetStateAction<IDesignDirection[] | IDesignDirection[]>
	>;
	creatives: ICreative[];
	setCreatives: React.Dispatch<React.SetStateAction<ICreative[] | ICreative[]>>;
	availableChannelGroups: IChannelGroup[];
}
const DesignDirections = ({
	creativesConfig,
	isLoading,
	designDirections,
	setDesignDirections,
	creatives,
	setCreatives,
	availableChannelGroups,
	...props
}: DesignDirectionsProps) => {
	const { completeStep } = useContext(CampaignContext);
	const { id: campaignId, setCampaign, campaign } = useContext(CampaignContext);
	const { closeChat } = useContext(AssistantChatContext);
	const [selectedDesignDirectionId, setSelectedDesignDirectionId] = useState<
		string | null
	>(null);
	const [selectedDesignDirection, setSelectedDesignDirection] =
		useState<IDesignDirection>();
	const [loadingCreatives, setLoadingCreatives] = useState(false);
	const [isGeneratingDD, setIsGeneratingDD] = useState(false);
	const prevDesignDirectionsLength = useRef(designDirections.length);
	const tagModalToggle = useToggleWithPayload<IDesignDirection>();
	const timeoutId = useRef<any>(null);
	const creativesRef = useRef<any>(null);
	const hasAnyGenerated = designDirections?.some(
		(c) => c.status === 'GENERATED',
	);
	const isDesignDirectionPending = designDirections?.some(
		(c) => c.status === 'pending' || c.status === 'generating',
	);
	useEffect(() => {
		if (
			!selectedDesignDirectionId &&
			designDirections?.length &&
			designDirections[0].status === 'GENERATED'
		) {
			handleSelectDesignDirection(designDirections[0], false);
		}
	}, [designDirections]);
	useEffect(() => {
		if (isLoading || (isDesignDirectionPending && !timeoutId.current)) {
			handleRefetchCampaignDesignDirection();
		}
	}, [isDesignDirectionPending, timeoutId.current, isLoading]);
	useEffect(() => {
		campaign?.designDirections &&
			setDesignDirections(removeDuplicateLayers(campaign.designDirections));
		campaign?.creatives && setCreatives(campaign?.creatives);
	}, [campaign]);
	useEffect(() => {
		(isLoading || !designDirections.length) &&
			setSelectedDesignDirectionId(null);
	}, [isLoading, designDirections]);
	useEffect(() => {
		if (designDirections.length > prevDesignDirectionsLength.current) {
			setIsGeneratingDD(false);
		}
		prevDesignDirectionsLength.current = designDirections.length;
	}, [designDirections]);
	const handleRefetchCampaignDesignDirection = async () => {
		try {
			if (!campaignId || campaignId === 'new') return;
			const response = await getCampaign(campaignId);
			setDesignDirections(response.designDirections ?? []);
			const isPending = response.designDirections?.some(
				(c) => c.status === 'pending' || c.status === 'generating',
			);
			if (isPending) {
				timeoutId.current = setTimeout(
					handleRefetchCampaignDesignDirection,
					1000,
				);
			} else {
				setIsGeneratingDD(false);
				clearTimeout(timeoutId.current);
				timeoutId.current = undefined;
			}
		} catch (error: any) {
			toastError(error);
		}
	};
	const handleRetryGenerateDD = (designDirection: IDesignDirection) => {
		if (!campaignId) return;
		removeDesignDirection(campaignId, designDirection.id);
		handleGenerateNewDD();
	};
	const handleRefreshDesignDirections = async () => {
		if (!campaignId) return;
		const response = await getCampaign(campaignId);
		setDesignDirections(response.designDirections ?? []);
		setCampaign(response);
	};
	const handleGenerateNewDD = async () => {
		try {
			if (!campaignId) return;
			setIsGeneratingDD(true);
			await genrateNewDesignDirection(campaignId);
			await handleRefreshDesignDirections();
		} catch (error: any) {
			toastError(error);
			setIsGeneratingDD(false);
		}
	};
	const handleRemoveDesignDirection = async (id: string) => {
		try {
			if (!campaignId || !id) return;
			await removeDesignDirection(campaignId, id);
			toastSuccess('Design direction deleted');
			if (selectedDesignDirectionId === id) {
				setSelectedDesignDirectionId(null);
			}
			handleRefreshDesignDirections();
		} catch (error: any) {
			toastError(error);
		}
	};
	const handleGenerateCreatives = async (
		designDirectionId?: string,
		onlyUnprocessed = true,
	) => {
		if (!campaignId) return;
		try {
			await genrateCampaignCreatives(
				campaignId,
				designDirectionId,
				onlyUnprocessed,
			);
			const data = await getCampaign(campaignId);
			data.creatives && setCreatives(data.creatives);
		} catch (error: any) {
			toastError(error);
		}
	};
	const handleSelectDesignDirection = (
		designDirection: IDesignDirection,
		scroll = true,
	) => {
		if (selectedDesignDirectionId !== designDirection.id) {
			setSelectedDesignDirectionId(designDirection.id);
			setSelectedDesignDirection(designDirection);
			scroll && creativesRef.current.scrollIntoView();
		}
		const creativesFromSelectedDD = creatives?.filter(
			(creative) => creative.designDirectionId === designDirection.id,
		);
		const allChannelsCovered = campaign?.channels?.every(
			(channel) =>
				creativesFromSelectedDD?.some(
					(creative) => creative.channel === channel,
				),
		);
		if (!allChannelsCovered) {
			setLoadingCreatives(true);
			handleGenerateCreatives(designDirection.id, !allChannelsCovered);
		}
	};
	const isAnySelected = selectedDesignDirectionId !== null;
	const sortedDesignDirections = [...designDirections].sort((a, b) => {
		if (a.locked && !b.locked) return -1;
		if (!a.locked && b.locked) return 1;
		return a.variant.localeCompare(b.variant);
	});
	const designDirectionsToShow = isLoading
		? sortedDesignDirections.filter((dd) => dd.locked)
		: sortedDesignDirections;
	return (
		<Flex p={4} direction="column">
			<HStack
				justifyContent="space-between"
				w="full"
				alignItems="center"
				mb={10}
				mt="-20px"
			>
				<Text size="md">Designs to promote your business</Text>
				<GenerateOrSelectCard
					onGenerate={handleGenerateNewDD}
					campaignId={campaignId!}
					handleRefreshDesignDirections={handleRefreshDesignDirections}
					setIsGeneratingDD={setIsGeneratingDD}
				/>
			</HStack>
			<Wrap spacing={4}>
				{designDirectionsToShow.map((dd: IDesignDirection) => (
					<WrapItem key={dd.id}>
						<Box>
							<DDCard
								designDirection={dd}
								openEditModal={() => tagModalToggle.onOpen(dd)}
								onRetry={handleRetryGenerateDD}
								onRemove={handleRemoveDesignDirection}
								onSelect={() => handleSelectDesignDirection(dd)}
								isSelected={selectedDesignDirectionId === dd.id}
								isAnySelected={isAnySelected}
							/>
						</Box>
					</WrapItem>
				))}
				{isGeneratingDD && !isDesignDirectionPending && (
					<WrapItem>
						<Skeleton w="323px" h="323px" borderRadius="2xl" />
					</WrapItem>
				)}
				{isLoading && (
					<>
						{Array.from({
							length: Math.max(
								0,
								3 - designDirections.filter((dd) => dd.locked).length,
							),
						}).map((_, index) => (
							<WrapItem key={index}>
								<Skeleton w="323px" h="323px" borderRadius="2xl" />
							</WrapItem>
						))}
					</>
				)}
				<Box ref={creativesRef} />
				{selectedDesignDirection && campaign?.channels.length && (
					<Creatives
						creatives={creatives}
						setCreatives={setCreatives}
						loadingCreatives={loadingCreatives}
						setLoadingCreatives={setLoadingCreatives}
						channels={campaign.channels}
						designDirection={selectedDesignDirection}
						creativesConfig={creativesConfig}
						availableChannelGroups={availableChannelGroups}
					/>
				)}
			</Wrap>
			<Box alignSelf="flex-end" mt={5}>
				<Button
					isDisabled={!hasAnyGenerated || isDesignDirectionPending}
					variant="orangeSolid"
					_hover={{
						opacity: 0.9,
					}}
					_disabled={{
						opacity: 0.7,
						cursor: 'not-allowed',
					}}
					onClick={() => {
						props.onSubmit();
						completeStep(1);
					}}
				>
					Schedule & Publish
				</Button>
			</Box>
			{tagModalToggle.payload && (
				<EditDDModal
					isOpen={tagModalToggle.isOpen}
					onClose={() => {
						tagModalToggle.onClose();
						closeChat();
					}}
					initialValues={tagModalToggle.payload}
					handleRefreshDesignDirections={handleRefreshDesignDirections}
					designDirections={designDirections}
					setLoadingCreatives={setLoadingCreatives}
				/>
			)}
		</Flex>
	);
};
export default DesignDirections;
