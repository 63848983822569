import { Flex, Heading, Button, Box } from '@chakra-ui/react';
import { v4 as uuid } from 'uuid';

import {
	DataTable,
	DataTablePagination,
	TActions,
} from 'src/components/common/DataTable';
import GroupDeleteModal from 'src/components/groups/GroupDeleteModal';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import GroupFormModal from 'src/components/groups/GroupFormModal';
import { getGroupsListingColumns } from 'src/components/groups/GroupListingColumns';
import { IGroup, IGroupForm } from 'src/lib/schemas';
import useGroups from 'src/hooks/useGroups';
import useClientPagination from 'src/hooks/useClientPagination';
import { createGroup, updateGroup } from 'src/services/config';
import { deleteElem, upsertElem } from 'src/lib/utils';
import CustomContainer from 'src/components/common/CustomContainer';

const Groups = () => {
	const formToggle = useToggleWithPayload<IGroup>();
	const deleteToggle = useToggleWithPayload<IGroup>();
	const { isLoading, data, refetch } = useGroups();

	const { data: groups, ...pagination } = useClientPagination<IGroup>({
		data: data || [],
	});
	const handleCreateUpdateGroup = async (payload: IGroupForm) => {
		if (!payload.id) {
			payload.id = uuid();
		}
		if (!data?.length) {
			createGroup(upsertElem(data ?? [], payload as IGroup));
		} else {
			await updateGroup(upsertElem(data, payload as IGroup));
		}
		refetch();
	};

	const handleDeleteGroup = async (group: IGroup) => {
		await updateGroup(deleteElem(data ?? [], group.id));
		refetch();
	};

	const handleAction = async (action: TActions, data: IGroup) => {
		switch (action) {
			case 'Delete':
				deleteToggle.onOpen(groups.find((u) => u.id === data.id));
				break;
			case 'Edit':
				formToggle.onOpen(groups.find((u) => u.id === data.id));
				break;
			default:
				break;
		}
	};

	const columns = getGroupsListingColumns(handleAction);

	return (
		<CustomContainer>
			<Flex w="full" direction="column" gap={6}>
				<Flex alignItems="center" justifyContent="space-between">
					<Heading>Groups</Heading>
					<Button
						onClick={() => formToggle.onOpen()}
						colorScheme="secondary"
						h="32px"
					>
						Add group
					</Button>
				</Flex>
				<Box>
					<DataTable
						columns={columns}
						data={groups ?? []}
						isLoading={isLoading}
						tableProps={{
							border: '1px solid #F5F5F5',
							minH: '400px',
						}}
						trProps={{
							borderBottomWidth: '1px',
							borderColor: '#F5F5F5',
						}}
					/>
					<DataTablePagination {...pagination} />
				</Box>
				<GroupFormModal
					isOpen={formToggle.isOpen}
					onClose={formToggle.onClose}
					onSubmit={handleCreateUpdateGroup}
					initialValues={formToggle.payload}
					title={`${formToggle.payload ? 'Edit' : 'Add'} Group`}
				/>
				<GroupDeleteModal
					isOpen={deleteToggle.isOpen}
					onClose={deleteToggle.onClose}
					onConfirm={handleDeleteGroup}
					data={deleteToggle.payload}
				/>
			</Flex>
		</CustomContainer>
	);
};

export default Groups;
