import { Flex, Input, Button, Tooltip } from '@chakra-ui/react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { SparklesIcon } from 'src/assets/icons';
import { AssistantChatContext } from 'src/contexts';
import {
	ChatMessageEnum,
	IMessage,
	IThreadPayload,
	ThreadRolesEnum,
	ThreadTypesEnum,
} from 'src/lib/schemas';

const DISABLED_SUBMIT_MESSAGE =
	"Your assistant is taking a request. Please wait until it's processed.";

const Actions = () => {
	const [content, setContent] = useState('');
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const {
		field,
		thread,
		isMyAssistantWritting,
		shouldSubmitBeAble,
		isChatLoading,
		addMessage,
		sendMessage,
	} = useContext(AssistantChatContext);

	useEffect(() => {
		!isMyAssistantWritting && setIsTooltipOpen(false);
	}, [isMyAssistantWritting]);

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();
		if (!content || isMyAssistantWritting) return;
		const payload: IThreadPayload = {
			user_message: content,
			current_content: field!.content,
			thread_id: thread?.threadId,
			property: thread?.property,
			scope: {
				type: ThreadTypesEnum.Campaign,
				reference: thread!.reference!,
				property: thread?.property ?? field?.id,
				sub_reference: thread?.sub_reference,
			},
		};
		const newMessage: IMessage = {
			role: ThreadRolesEnum.User,
			splitted_content: [
				{ value: content, type: ChatMessageEnum.String, suggestion: false },
			],
		};
		addMessage(newMessage);
		sendMessage(payload);
		setContent('');
	};

	const handleTooltip = () => {
		isMyAssistantWritting && setIsTooltipOpen(true);
	};

	return (
		<Flex borderBottomRadius={4} p={3} boxShadow="0px -4px 10px 0px #f8fafe">
			<form onSubmit={handleSubmit} style={{ width: '100%' }}>
				<Tooltip
					hasArrow
					label={DISABLED_SUBMIT_MESSAGE}
					isOpen={isTooltipOpen}
					bg="gray.100"
					color="gray.800"
					borderRadius="sm"
					openDelay={500}
				>
					<Flex
						alignItems="center"
						gap={2}
						justifyContent="space-between"
						onMouseOver={handleTooltip}
						onMouseLeave={() => setIsTooltipOpen(false)}
					>
						<Input
							autoFocus
							border="1px solid #F7480B"
							_hover={{ border: '1px solid #F7480B' }}
							_focusVisible={{ border: '1px solid #F7480B' }}
							borderRadius={5}
							placeholder="Enter feedback and click the submit button"
							h="40px"
							fontSize="14px"
							value={content}
							bg="white"
							onChange={(e) => setContent(e.target.value)}
						/>
						<Button
							isDisabled={isChatLoading || !shouldSubmitBeAble}
							type="submit"
							p={5}
							size="sm"
							borderRadius={5}
							colorScheme="secondary"
							fontWeight="semibold"
							fontSize="small"
							rightIcon={<SparklesIcon color="white" />}
						>
							Submit
						</Button>
					</Flex>
				</Tooltip>
			</form>
		</Flex>
	);
};

export default Actions;
