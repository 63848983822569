import {
	Box,
	Image,
	Text,
	Button,
	Flex,
	HStack,
	Avatar,
} from '@chakra-ui/react';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';
import { useContext } from 'react';
import UserContext from 'src/contexts/UserContext';
import { IoMdLink } from 'react-icons/io';
import MiniGlobeIcon from 'src/assets/icons/MiniGlobe';

import CreativeCard from './CreativeCard';
import { IChannelCreativeAttributes } from 'src/lib/schemas';
import { formatCallToAction } from './utils/formatedCTA';
import DDPhotoEditor, {
	DDPhotoEditorProps,
} from '../design-directions/DDPhotoEditor';

interface SquareCreativeProps extends Partial<DDPhotoEditorProps> {
	creative: ICreative;
	withActions?: boolean;
	size?: string;
	editMode?: boolean;
	creativesConfig?: IChannelCreativeAttributes[];
}
const StoryCreative = ({
	creative,
	withActions = true,
	size = 'md',
	creativesConfig,
	editMode = false,
	...photoEditorProps
}: SquareCreativeProps) => {
	const { user, account } = useContext(UserContext);
	const image = (creative as any).attributes.image.flatFile;
	const callToAction = (creative as any).attributes.callToAction;

	return (
		<CreativeCard
			creative={creative}
			withActions={withActions}
			size={size}
			title="Story"
			creativesConfig={creativesConfig}
		>
			<Box minH="680px">
				<Box p={4} position="absolute" zIndex={1}>
					<Flex alignItems="center" mb={4} gap={2}>
						<Avatar
							src={account?.logo}
							sx={{
								img: {
									objectFit: 'contain',
								},
							}}
						/>
						<Box>
							<Text color="black" fontWeight="bold">
								{user?.name}
							</Text>
							<HStack spacing={1}>
								<Text color="black" fontSize="sm">
									Sponsored ·
								</Text>
								<MiniGlobeIcon />
							</HStack>
						</Box>
					</Flex>
				</Box>
				{!editMode ? (
					<Image
						src={image}
						alt="Preview"
						fallbackSrc="/fusion-loader.gif"
						borderRadius="lg"
					/>
				) : (
					photoEditorProps.designDirection &&
					photoEditorProps.isChangingLayer !== undefined &&
					photoEditorProps.onRefetchCreatives && (
						<DDPhotoEditor
							key={photoEditorProps.layeredFile}
							layeredFile={photoEditorProps.layeredFile}
							textLayerContent={photoEditorProps.textLayerContent}
							mediaLayersContent={photoEditorProps.mediaLayersContent}
							font={photoEditorProps.font}
							designDirection={photoEditorProps.designDirection}
							isChangingLayer={photoEditorProps.isChangingLayer}
							onRefetchCreatives={photoEditorProps.onRefetchCreatives}
							withActions={false}
							containerHeight="680px"
						/>
					)
				)}
				{callToAction && (
					<Flex
						position="absolute"
						bottom={5}
						width="100%"
						justifyContent="center"
					>
						<Button
							size="sm"
							cursor="default"
							bg="gray.100"
							px={3}
							_hover={{ bg: 'gray.100' }}
							fontWeight={500}
							leftIcon={<IoMdLink />}
						>
							{formatCallToAction(callToAction)}
						</Button>
					</Flex>
				)}
			</Box>
		</CreativeCard>
	);
};

export default StoryCreative;
