import { useContext, useEffect, useState } from 'react';
import PreRegisterForm from './PreRegisterForm';
import AdGeneration from './AdGeneration';
import { Flex } from '@chakra-ui/react';
import http from 'src/services/http';
import { useNavigate, useParams } from 'react-router-dom';
import { ICatalog } from 'src/lib/schemas';
import ScrollToTop from 'src/components/common/ScrollToTop';
import { registerByDomain as registerByDomainService } from 'src/services/scraping';
import UserContext from 'src/contexts/UserContext';
import FusionLoading from 'src/components/common/FusionLoading';
import useRoute from 'src/hooks/useRoute';
import DomainNotFound from './DomainNotFound';

const ScrappingPage = () => {
	const [step, setstep] = useState(1);
	const [templateUrl, setTemplateUrl] = useState();
	const [imageHasError, setImageHasError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [adGenerationLoading, setAdGenerationLoading] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState<ICatalog>();
	const [brand, setBrand] = useState<ICatalog>();
	const { setUser } = useContext(UserContext);
	const [domainRegistered, setDomainRegistered] = useState(false);
	const navigate = useNavigate();
	const { formatUrl, removeUrlPrefix } = useRoute();
	const { id } = useParams<{ id: string }>();
	const cleanUrl = (url: string) => {
		const decodedUrl = decodeURIComponent(url);
		const formatedUrl = formatUrl(decodedUrl);
		return formatedUrl;
	};
	const formatedUrl = id && cleanUrl(id);
	const urlWithoutPrefix = formatedUrl
		? removeUrlPrefix(formatedUrl)
		: 'your domain';

	const handleProductChange = (product: ICatalog) => {
		setSelectedProduct(product);
	};

	const registerByDomain = async (url = formatedUrl) => {
		if (!url) return;
		try {
			const response = await registerByDomainService(url);
			response && setUser(response.user);
			setDomainRegistered(true);
		} catch (error) {
			setDomainRegistered(false);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		localStorage.removeItem('tokens');
		localStorage.removeItem('user');
		registerByDomain();
	}, []);

	const processGenerateAdCallback = async (callbackUrl: string) => {
		try {
			const response = await http.get(callbackUrl);
			const { status, body } = response.data;
			if (status === 'processing' || status === 'pending') {
				setTimeout(processGenerateAdCallback.bind(null, callbackUrl), 1500);
			} else if (status === 'error' || status === 'failed') {
				setImageHasError(true);
			} else if (status === 'successful') {
				setTemplateUrl(body.flatFile);
				setAdGenerationLoading(false);
			}
		} catch (e) {
			setAdGenerationLoading(false);
		}
	};

	const generateAd = async (payload: any) => {
		setBrand(payload);
		setImageHasError(false);
		setAdGenerationLoading(true);
		http
			.post('/v2/apps/fusion_ai.demo_ad/execute/generate_demo_ad', {
				inputs: {
					...payload,
					promotedObject: selectedProduct,
					promotion: [
						{
							code: '20OFF',
							description: '20%OFF',
						},
					],
				},
			})
			.then(({ data }) => {
				processGenerateAdCallback(data.callback);
				setstep(2);
				window.history.pushState(null, '', '/complete-registration');
			})
			.catch((error) => {
				setAdGenerationLoading(false);
			});
	};

	const handleRetryDomain = (newDomain: string) => {
		if (!newDomain) return;
		navigate(`/welcome/${newDomain}`);
		setIsLoading(true);
		setDomainRegistered(false);
		const formatedUrl = cleanUrl(newDomain);
		registerByDomain(formatedUrl);
	};

	if (isLoading) return <FusionLoading isLoading={isLoading} />;
	if (!domainRegistered)
		return (
			<DomainNotFound
				onRetryDomain={handleRetryDomain}
				domain={urlWithoutPrefix}
			/>
		);
	return (
		formatedUrl && (
			<Flex
				width={{ base: '100%', sm: '80%', xl: '60%' }}
				direction="column"
				margin="0 auto"
			>
				{step === 1 ? (
					<PreRegisterForm
						route={formatedUrl}
						onGenerateAd={generateAd}
						onSkip={() => navigate('/signup')}
						onProductChange={handleProductChange}
						selectedProduct={selectedProduct}
						websiteUrl={formatedUrl}
					/>
				) : (
					<>
						<ScrollToTop />
						<AdGeneration
							templateUrl={templateUrl}
							isPendingGeneration={adGenerationLoading}
							imageHasError={imageHasError}
							onLoad={() => setAdGenerationLoading(false)}
							handleRetry={() => generateAd(brand)}
						/>
					</>
				)}
			</Flex>
		)
	);
};

export default ScrappingPage;
