import { FC } from 'react';
import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { IChannel } from 'src/lib/schemas';
import { ChannelType } from './ChannelType';
import MarketingChannelInputHook from 'src/components/common/form/MarketingChannelInputV2/MarketingChannelInputHook';
import { CHANNELS_ICON_MAPPING } from 'src/lib/constants/campaign';

interface FilteredChannelsSectionProps {
	title: string;
	type: ChannelType;
	channels: IChannel[];
	selectedPlacements?: string[];
	onChange: (channelId: string, channelType: ChannelType) => void;
}

const FilteredChannelsSection: FC<FilteredChannelsSectionProps> = ({
	title,
	channels,
	onChange,
	type,
	selectedPlacements,
}) => {
	const isSelected = (channel: IChannel) => {
		return channel.placements?.some(
			(placement) =>
				selectedPlacements?.includes(placement.id) && placement.type === type,
		);
	};

	return (
		<Box w="full">
			<Text size="md">{title}</Text>
			<SimpleGrid columns={{ base: 2, md: 3, lg: 4, xl: 5 }} spacing={4} mt={5}>
				{channels.map((item) => (
					<MarketingChannelInputHook
						key={item.id}
						fontColor="gray.500"
						name={item.name}
						isSelected={isSelected(item)}
						onChange={onChange}
						id={item.id}
						type={type}
						icon={item.icon}
					/>
				))}
			</SimpleGrid>
		</Box>
	);
};

export default FilteredChannelsSection;
