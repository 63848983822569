import { FC } from 'react';
import { Flex, Box, Text, BoxProps } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import OptionCard from '../../OptionCard';
import ValueChip from '../../ValueChip';
import { IOption } from 'src/lib/schemas/misc';
import { useProductFormModalContext } from 'src/contexts/ProductFormModalContext';
import { removeDuplicates } from 'src/lib/utils/array';

interface MultiSelectValuesHookProps {
	name: string;
	valuesAsCard?: boolean;
	title?: string;
	options: Array<IOption>;
	boxProps?: BoxProps;
	withLabel?: boolean;
	isProductFormModal?: boolean;
}

const MultiSelectValuesHook: FC<MultiSelectValuesHookProps> = ({
	name,
	valuesAsCard,
	title,
	options,
	boxProps,
	withLabel,
	isProductFormModal: isProductFormModalProp,
}) => {
	const { control } = useFormContext();
	const { field } = useController({ control, name });
	const { onChange, value } = field;
	const productFormModalContext = useProductFormModalContext();
	const isProductFormModal =
		isProductFormModalProp ?? productFormModalContext?.isProductFormModal;

	const handleRemove = (val: IOption) => {
		onChange(
			value?.filter((v: IOption) => (v as unknown as string) !== val.value),
		);
	};

	if (!value?.length) return null;

	const isValueObject = typeof value[0] === 'object' && value[0]._id;

	const valueIds = isValueObject
		? value.map((val: { _id: any }) => val._id)
		: value;

	const valueAsOptions = options.filter((o) => valueIds.includes(o.value));
	const cleanedOptions = removeDuplicates(
		valueAsOptions,
		(option) => option.value,
	);

	const renderContent = () => (
		<Flex mt={isProductFormModal ? -5 : 3} gap={3} flexWrap="wrap">
			{cleanedOptions.map((item: IOption) =>
				valuesAsCard ? (
					<OptionCard key={item.value} option={item} onAction={handleRemove} />
				) : (
					<ValueChip key={item.value} option={item} onRemove={handleRemove} />
				),
			)}
		</Flex>
	);

	if (isProductFormModal) {
		return (
			<Box mt={2}>
				<Flex
					direction="row"
					justify="space-between"
					alignItems="center"
					w="100%"
				>
					{withLabel && (
						<Text fontWeight="bold" mb={2}>
							{title ?? name}
						</Text>
					)}
				</Flex>
				{renderContent()}
			</Box>
		);
	}

	return (
		<Flex>
			<Box
				mt={withLabel ? 6 : 0}
				px={withLabel ? 6 : 0}
				py={withLabel ? 3 : 0}
				bg={withLabel ? '#F8F8F8' : 'white'}
				borderRadius="lg"
				{...boxProps}
			>
				{withLabel && (
					<Flex gap={1}>
						<Text>Added</Text>
						<Text textTransform="capitalize">{title ?? name}</Text>
					</Flex>
				)}
				<Flex mt={3} gap={3} flexWrap="wrap">
					{cleanedOptions.map((item: IOption) =>
						valuesAsCard ? (
							<OptionCard
								key={item.value}
								option={item}
								onAction={handleRemove}
							/>
						) : (
							<ValueChip
								key={item.value}
								option={item}
								onRemove={handleRemove}
							/>
						),
					)}
				</Flex>
			</Box>
		</Flex>
	);
};

export default MultiSelectValuesHook;
